import "../styles/employeeDetails.css";
import empPicture from "../assets/images/employee-card01.png";
import depIcon from "../assets/images/department.png";
import salIcon from "../assets/images/salary.png";
import workIcon from "../assets/images/regularwork.png";
import dateIcon from "../assets/images/joiningdate.png";
import { useNavigate } from "react-router-dom";

const EmployeeDetails = () => {
  const navigate = useNavigate();

  return (
    <div className="board__layout" style={{ height: "100vh" }}>
      <div className="section-header">
        <div className="header__titles">
          <button onClick={() => navigate("/payrollmanagement/paymenthistory")}>
            <span>
              <i className="ri-arrow-left-s-line"></i>
            </span>
            BACK
          </button>
          <h4>Employee Details</h4>
        </div>
        <button className="action__btn">Action</button>
      </div>
      <div className="section__content">
        <div className="employee__title">
          <div>
            <img src={empPicture} alt="employee image" />
          </div>
          <div>
            <h1>Antonio John</h1>
            <p>Software Developer</p>
          </div>
        </div>
        <div
          className="employee__details"
          style={{
            borderRight: "1px solid #0A9250",
            borderLeft: "1px solid #0A9250",
          }}
        >
          <div className="emp__detail">
            <div>
              <img src={depIcon} alt="icon" />
            </div>
            <div>
              <h6>Department</h6>
              <p>Antonio John</p>
            </div>
          </div>
          <div className="emp__detail">
            <div>
              <img src={workIcon} alt="icon" />
            </div>
            <div>
              <h6>Work Shift</h6>
              <p>Regular Work</p>
            </div>
          </div>
        </div>

        <div className="employee__details">
          <div className="emp__detail">
            <div>
              <img src={salIcon} alt="icon" />
            </div>
            <div>
              <h6>Salary</h6>
              <p>$4000</p>
            </div>
          </div>
          <div className="emp__detail">
            <div>
              <img src={dateIcon} alt="icon" />
            </div>
            <div>
              <h6>Joining Date</h6>
              <p>15/08/2022</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EmployeeDetails;
