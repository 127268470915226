import { useNavigate, useParams } from "react-router-dom";
import { useState, useEffect } from "react";
import { Formik, Field, Form, ErrorMessage, FieldArray } from "formik";
import TextError from "../MultiStep/TextError";
import { useMemo } from "react";
import * as Yup from "yup";
import countryList from "react-select-country-list";
import { differenceInMonths } from "date-fns";
import axios from "axios";
import Modal from "react-modal";
Modal.setAppElement("#root");

const ExperienceUpdate = () => {
  const accessToken = localStorage.getItem("accessToken");
  const { id } = useParams();
  const [employeeData, setEmployeeData] = useState([]);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const navigate = useNavigate();
  const countries = useMemo(() => countryList().getData(), []);

  const fetchEmployeeData = async (id) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/employees/employeeInformation/${id}`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      const data = response.data?.data;
      setEmployeeData(data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchEmployeeData(id);
  }, [id]);

  const validationSchema = Yup.object().shape({
    experiences: Yup.array().of(
      Yup.object().shape({
        previous_worked_firm: Yup.string().required(
          "Previous Company Name is required"
        ),
        organization_address: Yup.string().required(
          "Previous Company Address is required"
        ),
        organization_city: Yup.string().required("City is required"),
        organization_country: Yup.string().required("Country is required"),
        designation: Yup.string().required("Previous Designation is required"),
        date_of_joining: Yup.date().required("Joining Date is required"),
        relieving_date: Yup.date().required("Relieving Date is required"),
        references: Yup.array().of(
          Yup.object().shape({
            reference_person: Yup.string().required(
              "Reference Person is required"
            ),
            reference_designation: Yup.string().required(
              "Reference Person Designation is required"
            ),
            reference_mobile_no: Yup.string()
              .min(10, "Number must be at least 10 charaters")
              .required("Number is required"),
            reference_email: Yup.string()
              .email("Invalid email address")
              .required("Reference Person Email Id is required"),
          })
        ),
      })
    ),
  });

  const handleSubmit = async (values) => {
    try {
      const experiences = values.experiences.map((exp) => {
        const joiningDate = new Date(exp.date_of_joining);
        const relievingDate = new Date(exp.relieving_date);
        const experienceInMonths = differenceInMonths(
          relievingDate,
          joiningDate
        );
        return { ...exp, experience_in_months: experienceInMonths };
      });

      const requestBody = {
        id: employeeData[0].id,
        experiences: experiences,
      };

      const response = await axios.put(
        `${process.env.REACT_APP_BASE_URL}/employees/experiences`,
        requestBody,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      setShowSuccessModal(true);
      console.log("Data updated successfully:", response.data);
    } catch (error) {
      console.log("Error updating data:", error.response);
    }
  };

  const handleCloseModal = () => {
    setShowSuccessModal(false);
    navigate(`/employeedirectory/${id}`);
  };

  return (
    <div className="board__layout">
      <div className="section-header">
        <h1>Update Experiences</h1>
      </div>
      {employeeData.map((employee) => (
        <Formik
          initialValues={{
            experiences: employee.experiences.map((experience) => ({
              previous_worked_firm: experience.previous_worked_firm || "",
              organization_address: experience.organization_address || "",
              organization_city: experience.organization_city || "",
              organization_country: experience.organization_country || "",
              designation: experience.designation || "",
              date_of_joining: experience.date_of_joining || "",
              relieving_date: experience.relieving_date || "",
              references: [
                {
                  reference_person:
                    experience.references[0].reference_person || "",
                  reference_designation:
                    experience.references[0].reference_designation || "",
                  reference_mobile_no:
                    experience.references[0].reference_mobile_no || "",
                  reference_email:
                    experience.references[0].reference_email || "",
                },
              ],
            })),
          }}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({ values }) => (
            <Form style={{ padding: "30px" }}>
              <FieldArray name="experiences">
                {({ insert, remove, push }) => (
                  <div>
                    {values.experiences.length > 0 &&
                      values.experiences.map((exp, index) => (
                        <div key={index} style={{ marginBottom: "3rem" }}>
                          <div className="form-rows">
                            <div>
                              <label
                                htmlFor={`experiences.${index}.previous_worked_firm`}
                              >
                                Previous Company Name
                              </label>
                              <Field
                                type="text"
                                name={`experiences.${index}.previous_worked_firm`}
                                id={`experiences.${index}.previous_worked_firm`}
                                placeholder="Enter your Previous Company Name"
                              />
                              <ErrorMessage
                                component={TextError}
                                name={`experiences.${index}.previous_worked_firm`}
                              />
                            </div>

                            <div>
                              <label
                                htmlFor={`experiences.${index}.organization_address`}
                              >
                                Previous Company Address
                              </label>
                              <Field
                                type="text"
                                name={`experiences.${index}.organization_address`}
                                id={`experiences.${index}.organization_address`}
                                placeholder="Enter your Previous Company Address"
                              />
                              <ErrorMessage
                                component={TextError}
                                name={`experiences.${index}.organization_address`}
                              />
                            </div>
                          </div>

                          <div className="form-rows">
                            <div>
                              <label
                                htmlFor={`experiences.${index}.organization_city`}
                              >
                                City
                              </label>
                              <Field
                                type="text"
                                name={`experiences.${index}.organization_city`}
                                id={`experiences.${index}.organization_city`}
                                placeholder="Previous Company Place"
                              />
                              <ErrorMessage
                                component={TextError}
                                name={`experiences.${index}.organization_city`}
                              />
                            </div>

                            <div>
                              <label
                                htmlFor={`experiences.${index}.organization_country`}
                              >
                                Country
                              </label>
                              <Field
                                name={`experiences.${index}.organization_country`}
                                as="select"
                              >
                                <option value="">Select Country</option>
                                {countries.map((country, index) => (
                                  <option value={country.id} key={index}>
                                    {country.label}
                                  </option>
                                ))}
                              </Field>
                              <ErrorMessage
                                component={TextError}
                                name={`experiences.${index}.organization_country`}
                              />
                            </div>
                          </div>

                          <div className="form-rows">
                            <div>
                              <label
                                htmlFor={`experiences.${index}.designation`}
                              >
                                Previous Designation
                              </label>
                              <Field
                                type="text"
                                name={`experiences.${index}.designation`}
                                id={`experiences.${index}.designation`}
                                placeholder="Designation Possessed in Previous Company"
                              />
                              <ErrorMessage
                                component={TextError}
                                name={`experiences.${index}.designation`}
                              />
                            </div>

                            <div>
                              <label
                                htmlFor={`experiences.${index}.date_of_joining`}
                              >
                                Joining Date
                              </label>
                              <Field
                                type="date"
                                name={`experiences.${index}.date_of_joining`}
                                id={`experiences.${index}.date_of_joining`}
                                placeholder="Previous Company Joining Date"
                              />
                              <ErrorMessage
                                component={TextError}
                                name={`experiences.${index}.date_of_joining`}
                              />
                            </div>
                          </div>

                          <div className="form-rows">
                            <div>
                              <label
                                htmlFor={`experiences.${index}.relieving_date`}
                              >
                                Relieving Date
                              </label>
                              <Field
                                type="date"
                                name={`experiences.${index}.relieving_date`}
                                id={`experiences.${index}.relieving_date`}
                                placeholder="Last working day"
                              />
                              <ErrorMessage
                                component={TextError}
                                name={`experiences.${index}.relieving_date`}
                              />
                            </div>
                            <div></div>
                          </div>

                          <FieldArray name={`experiences.${index}.references`}>
                            {({
                              push: pushReference,
                              remove: removeReference,
                            }) => (
                              <div>
                                {exp.references.length > 0 &&
                                  exp.references.map(
                                    (reference, referenceIndex) => (
                                      <div key={referenceIndex}>
                                        <div className="form-rows">
                                          <div>
                                            <label
                                              htmlFor={`experiences.${index}.references.${referenceIndex}.reference_person`}
                                            >
                                              Reference Person
                                            </label>
                                            <Field
                                              type="text"
                                              name={`experiences.${index}.references.${referenceIndex}.reference_person`}
                                              id={`experiences.${index}.references.${referenceIndex}.reference_person`}
                                              placeholder="Reference Person Name"
                                            />
                                            <ErrorMessage
                                              component={TextError}
                                              name={`experiences.${index}.references.${referenceIndex}.reference_person`}
                                            />
                                          </div>
                                          <div>
                                            <label
                                              htmlFor={`experiences.${index}.references.${referenceIndex}.reference_designation`}
                                            >
                                              Reference Person Designation
                                            </label>
                                            <Field
                                              type="text"
                                              name={`experiences.${index}.references.${referenceIndex}.reference_designation`}
                                              id={`experiences.${index}.references.${referenceIndex}.reference_designation`}
                                              placeholder="Contacting Person Designation"
                                            />
                                            <ErrorMessage
                                              component={TextError}
                                              name={`experiences.${index}.references.${referenceIndex}.reference_designation`}
                                            />
                                          </div>
                                        </div>
                                        <div className="form-rows">
                                          <div>
                                            <label
                                              htmlFor={`experiences.${index}.references.${referenceIndex}.reference_mobile_no`}
                                            >
                                              Reference Phone Number
                                            </label>
                                            <Field
                                              type="text"
                                              name={`experiences.${index}.references.${referenceIndex}.reference_mobile_no`}
                                              id={`experiences.${index}.references.${referenceIndex}.reference_mobile_no`}
                                              placeholder="Contacting Person Phone No."
                                            />
                                            <ErrorMessage
                                              component={TextError}
                                              name={`experiences.${index}.references.${referenceIndex}.reference_mobile_no`}
                                            />
                                          </div>
                                          <div>
                                            <label
                                              htmlFor={`experiences.${index}.references.${referenceIndex}.reference_email`}
                                            >
                                              Reference Person Email Id
                                            </label>
                                            <Field
                                              type="text"
                                              name={`experiences.${index}.references.${referenceIndex}.reference_email`}
                                              id={`experiences.${index}.references.${referenceIndex}.reference_email`}
                                              placeholder="Prev Co Contacting Person Email Id"
                                            />
                                            <ErrorMessage
                                              component={TextError}
                                              name={`experiences.${index}.references.${referenceIndex}.reference_email`}
                                            />
                                          </div>
                                          {referenceIndex > 0 && (
                                            <div>
                                              <button
                                                type="button"
                                                className="formik-delete-btn"
                                                onClick={() =>
                                                  removeReference(
                                                    referenceIndex
                                                  )
                                                }
                                              >
                                                Delete Reference
                                              </button>
                                            </div>
                                          )}
                                        </div>
                                      </div>
                                    )
                                  )}
                                <button
                                  type="button"
                                  className="formik-addMore-btn"
                                  onClick={() =>
                                    pushReference({
                                      reference_person: "",
                                      reference_designation: "",
                                      reference_mobile_no: "",
                                      reference_email: "",
                                    })
                                  }
                                >
                                  Add More References
                                </button>
                              </div>
                            )}
                          </FieldArray>

                          <div>
                            {index > 0 && (
                              <button
                                type="button"
                                className="formik-delete-btn"
                                onClick={() => remove(index)}
                              >
                                Delete
                              </button>
                            )}
                          </div>
                        </div>
                      ))}
                    <button
                      type="button"
                      className="formik-addMore-btn"
                      onClick={() =>
                        push({
                          previous_worked_firm: "",
                          organization_address: "",
                          organization_city: "",
                          organization_country: "",
                          designation: "",
                          date_of_joining: "",
                          relieving_date: "",
                          experience_in_months: "",
                          references: [
                            {
                              reference_person: "",
                              reference_designation: "",
                              reference_mobile_no: "",
                              reference_email: "",
                            },
                          ],
                        })
                      }
                    >
                      Add More Experiences +
                    </button>
                  </div>
                )}
              </FieldArray>
              <div className="form-btn-div">
                <button
                  type="button"
                  className="btn-skip"
                  onClick={() => navigate(`/employeedirectory/${id}`)}
                >
                  Close
                </button>
                <button type="submit" className="form-btn-submit">
                  Update
                </button>
              </div>
            </Form>
          )}
        </Formik>
      ))}
      <Modal
        isOpen={showSuccessModal}
        onRequestClose={() => setShowSuccessModal(false)}
        contentLabel="Data Updated Successfully"
        className="react-modal-content"
        overlayClassName="react-modal-overlay"
      >
        <h5>Details Updated Successfully</h5>
        <button onClick={handleCloseModal}>OK</button>
      </Modal>
    </div>
  );
};

export default ExperienceUpdate;
