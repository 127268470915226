import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import axios from "axios";
import TextError from "./TextError";
import { useState, useEffect } from "react";
import { useCallback } from "react";
import { BsArrowRight } from "react-icons/bs";

const JoiningDetails = (props) => {
  const accessToken = localStorage.getItem("accessToken");
  const [selectedCompany, setSelectedCompany] = useState("");
  const [selectedDepartment, setSelectedDepartment] = useState("");
  const [companyList, setCompanyList] = useState([]);
  const [departmentList, setDepartmentList] = useState([]);
  const [designationList, setDesignationList] = useState([]);
  const [reportingManagerList, setReportingManagerList] = useState([]);
  const [selectedDepartmentId, setSelectedDepartmentId] = useState("");

  const getCompany = () => {
    axios
      .get(`${process.env.REACT_APP_BASE_URL}/companies`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      })
      .then((response) => {
        const data = response.data?.data || [];
        setCompanyList(data);
      })
      .catch((error) => console.log(error));
  };

  const getDepartments = useCallback(
    (company = selectedCompany) => {
      return axios
        .get(
          `${process.env.REACT_APP_BASE_URL}/companies/${company}/departments`,
          {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          }
        )
        .then((response) => {
          const data = response.data?.data || [];
          setDepartmentList(data);
          return data;
        })
        .catch((error) => console.log(error));
    },
    [selectedCompany]
  );

  const getDesignation = useCallback(
    (department = selectedDepartment) => {
      axios
        .get(
          `${process.env.REACT_APP_BASE_URL}/departments/${selectedDepartmentId}/roles`,
          {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          }
        )
        .then((response) => {
          const data = response.data?.data || [];
          setDesignationList(data);
        })
        .catch((error) => console.log(error));
    },
    [selectedDepartment]
  );

  const getReportingManagerList = useCallback(
    (department = selectedDepartment) => {
      axios
        .get(`${process.env.REACT_APP_BASE_URL}/employees/managers`, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        })
        .then((response) => {
          const data = response.data.data || [];
          setReportingManagerList(data);
        })
        .catch((error) => console.log(error));
    },
    [selectedDepartment]
  );

  useEffect(() => {
    getCompany();
  }, []);

  useEffect(() => {
    getDepartments();
  }, [getDepartments]);

  useEffect(() => {
    getDesignation();
    getReportingManagerList();
  }, [getDesignation, getReportingManagerList]);

  const JoiningDetailsValidation = Yup.object({
    companyName: Yup.string().required("Company Name is required"),
    department: Yup.string().required("Department is required"),
    joining_date: Yup.date().required("Joining Date is required"),
    access_card_expiry_date: Yup.date().required(
      "Access Card Expiry Date is required"
    ),
    reportingManager: Yup.string().required("Reporting Manager is required"),
    designation: Yup.string().required("Designation is required"),
    work_hours: Yup.string().required("Work Hours is required"),
  });

  const handleSubmit = (values) => {
    props.next(values);
  };

  const handlePrevious = (values) => {
    props.onBack(values);
  };


  const handleInputChange = (event, type, formik) => {
    const { name, value } = event.target;
    if (type === "company") {
      console.log("value", value);
      setSelectedCompany(value);
      formik.setFieldValue(name, value);
      getDepartments(value);
    } else if (type === "department") {
      setSelectedDepartment(value);
      setSelectedDepartmentId(value);
      formik.setFieldValue(name, value);
    }
  };

  return (
    <Formik
      initialValues={props.empData}
      validationSchema={JoiningDetailsValidation}
      onSubmit={handleSubmit}
    >
      {(formik) => (
        <Form>
          <div className="form-rows">
            <div>
              <label htmlFor="companyName">Company Name</label>
              <Field
                name="companyName"
                as="select"
                value={selectedCompany}
                onChange={(e) => handleInputChange(e, "company", formik)}
              >
                <option value="">Select the Company</option>
                {companyList.map((company) => (
                  <option key={company.id} value={company.id}>
                    {company.company_name}
                  </option>
                ))}
              </Field>
              <ErrorMessage name="companyName" component={TextError} />
            </div>
            <div>
              <label htmlFor="department">Department</label>
              <Field
                name="department"
                as="select"
                value={selectedDepartment}
                onChange={(e) => handleInputChange(e, "department", formik)}
              >
                <option value="">Select the Department</option>
                {departmentList.map((department) => (
                  <option key={department.id} value={department.id}>
                    {department.department_name}
                  </option>
                ))}
              </Field>
              <ErrorMessage name="department" component={TextError} />
            </div>
          </div>

          <div className="form-rows">
            <div>
              <label htmlFor="joining_date">Joining Date</label>
              <Field type="date" name="joining_date" id="joining_date" />
              <ErrorMessage name="joining_date" component={TextError} />
            </div>
            <div>
              <label htmlFor="reportingManager">Reporting Manager</label>
              <Field name="reportingManager" as="select">
                <option value="">Select the Reporting Manager</option>
                {reportingManagerList.map((reportingManager) => (
                  <option
                    key={reportingManager.id}
                    value={reportingManager.employee_information_id}
                  >
                    {`${reportingManager.employee_full_name} - ${reportingManager.employee_id}`}
                  </option>
                ))}
              </Field>
              <ErrorMessage name="reportingManager" component={TextError} />
            </div>
          </div>

          <div className="form-rows">
            <div>
              <label htmlFor="designation">Designation</label>
              <Field name="designation" as="select">
                <option value="">Select the Designation </option>
                {designationList.map((designation) => (
                  <option key={designation.id} value={designation.id}>
                    {designation.role_name}
                  </option>
                ))}
              </Field>
              <ErrorMessage name="designation" component={TextError} />
            </div>
            <div>
              <label htmlFor="access_card_expiry_date">
                Access Card Expiry Date
              </label>
              <Field
                type="date"
                name="access_card_expiry_date"
                id="access_card_expiry_date"
              />
              <ErrorMessage
                name="access_card_expiry_date"
                component={TextError}
              />
            </div>
          </div>

          <div className="form-rows">
            <div>
              <label htmlFor="work_hours">Work Hours</label>
              <Field
                type="text"
                name="work_hours"
                id="work_hours"
                placeholder="Enter Employee Work Hours"
              />
              <ErrorMessage name="work_hours" component={TextError} />
            </div>
            <div></div>
          </div>

          <div className="form-btn-div" style={{ marginTop: "1rem" }}>
          <button onClick={handlePrevious} type="button" className="form-btn-previous">Previous</button>
            <button type="submit" className="form-btn-submit">
              Save & Continue <BsArrowRight />
            </button>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default JoiningDetails;
