import "../styles/roleManagement.css";
import axios from "axios";
import { useEffect, useState } from "react";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import Modal from "react-modal";
Modal.setAppElement("#root");

const RoleManagementEdit = () => {
  const accessToken = localStorage.getItem("accessToken");
  const location = useLocation();
  const depId = useParams();
  const navigate = useNavigate();
  const selectedRoleData = location.state?.roleData;
  const [allPermissionsData, setAllPermissionsData] = useState([]);
  const [rolePermissionsData, setRolePermissionsData] = useState([]);
  const [showSuccessModal, setShowSuccessModal] = useState(false);

  const getAllPermissionsData = () => {
    axios
      .get(`${process.env.REACT_APP_BASE_URL}/permissions`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      })
      .then((response) => {
        const data = response.data?.data || [];
        setAllPermissionsData(data);
      })
      .catch((error) => console.log(error));
  };

  const getRolePermissionsData = () => {
    axios
      .get(`${process.env.REACT_APP_BASE_URL}/roles/${roleId}/permissions`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      })
      .then((response) => {
        const data = response.data?.data || [];
        setRolePermissionsData(data);
      })
      .catch((error) => console.log(error));
  };

  useEffect(() => {
    getAllPermissionsData();
    getRolePermissionsData();
  }, []);

  const roleId = selectedRoleData.id;
  const roleName = selectedRoleData.role_name;

  const updateAllPermissionsData = (permissionId, checked) => {
    setAllPermissionsData((prevData) =>
      prevData.map((permission) =>
        permission.permission_id === permissionId
          ? { ...permission, selected: checked }
          : permission
      )
    );
  };

  const submitUpdateRole = async () => {
    try {
      const headers = {
        Accept: "application/json",
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      };

      const updatedPermissionIds = allPermissionsData
        .filter((permission) => {
          return permission.selected;
        })
        .map((permission) => permission.permission_id);

      if (updatedPermissionIds.length === 0) {
        alert("No permissions selected.");
        return;
      }

      const data = {
        role_name: roleName,
        permission_ids: updatedPermissionIds,
      };

      const url = `${process.env.REACT_APP_BASE_URL}/roles/${roleId}`;

      const response = await axios.put(url, data, { headers });

      setShowSuccessModal(true);
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleCloseModal = () => {
    setShowSuccessModal(false);
  };

  return (
    <div className="board__layout">
      <div className="section-header">
        <div className="header__titles">
          <button onClick={() => navigate("/rolemanagement")}>
            <span>
              <i className="ri-arrow-left-s-line"></i>
            </span>
            BACK
          </button>
          <h4>Edit Role Management</h4>
        </div>
      </div>
      <div className="role_mgmt_view-container">
        <div className="role_mgmt_view-firstRow">
          <div className="role_mgmt-titles">
            <h6>Company:</h6>
            <p>{selectedRoleData?.company[0].company_name}</p>
          </div>
          <div className="role_mgmt-titles">
            <h6>Department:</h6>
            <p>{selectedRoleData?.department_name}</p>
          </div>
          <div className="role_mgmt-titles">
            <h6>Role:</h6>
            <p>{selectedRoleData?.role_name}</p>
          </div>
        </div>

        <div className="role_management-permissions">
          <h5>Permissions</h5>
          <div className="role_management-container">
            {allPermissionsData.map((permission, index) => (
              <div
                key={permission.permission_id}
                className="role_management-lists"
              >
                <div className="role_management-list">
                  <input
                    type="checkbox"
                    checked={permission.selected}
                    onChange={(e) =>
                      updateAllPermissionsData(
                        permission.permission_id,
                        e.target.checked
                      )
                    }
                  />
                  <p>{permission.name}</p>
                </div>
                <hr />
              </div>
            ))}
          </div>
        </div>

        <div className="role_form-btn_section" style={{ paddingRight: "30px" }}>
          <button
            type="button"
            className="role_form-btn_cancel"
            onClick={() => navigate("/rolemanagement")}
          >
            Close
          </button>
          <button
            type="button"
            className="role_form-btn_submit"
            onClick={submitUpdateRole}
          >
            Update
          </button>
        </div>
      </div>
      <Modal
        isOpen={showSuccessModal}
        onRequestClose={() => setShowSuccessModal(false)}
        contentLabel="Role and Permission Update"
        className="react-modal-content"
        overlayClassName="react-modal-overlay"
      >
        <h5>Updated Successfully</h5>
        <button onClick={handleCloseModal}>OK</button>
      </Modal>
    </div>
  );
};

export default RoleManagementEdit;
