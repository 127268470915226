import "../../styles/empManagement.css";
import employeeData from "../../assets/dummy-data/employeeData";
import {useNavigate} from "react-router-dom";

const OnBoardingTable = () => {
  const navigate = useNavigate();

  return <div className="emp-table"></div>;
};

export default OnBoardingTable;
