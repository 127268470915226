import "../styles/roleManagement.css";
import { useState, useEffect, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import TextError from "../components/MultiStep/TextError";
import Modal from "react-modal";
Modal.setAppElement("#root");

const RoleManagementForm = () => {
  const accessToken = localStorage.getItem("accessToken");
  const [selectedCompany, setSelectedCompany] = useState("");
  const [selectedDepartment, setSelectedDepartment] = useState("");
  const [companyList, setCompanyList] = useState([]);
  const [departmentList, setDepartmentList] = useState([]);
  const [roleList, setRoleList] = useState([]);
  const [permissionsList, setPermissionsList] = useState([]);
  const [showNewRole, setShowNewRole] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const navigate = useNavigate();

  const getCompany = () => {
    axios
      .get(process.env.REACT_APP_GET_COMPANY_URL, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      })
      .then((response) => {
        const data = response.data?.data || [];
        setCompanyList(data);
      })
      .catch((error) => console.log(error));
  };

  const getDepartments = useCallback(
    (company = selectedCompany) => {
      return axios
        .get(`${process.env.REACT_APP_BASE_URL}/companies/${company}/departments`, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        })
        .then((response) => {
          const data = response.data?.data || [];
          setDepartmentList(data);
          return data;
        })
        .catch((error) => console.log(error));
    },
    [selectedCompany]
  );

  const getRole = useCallback(
    (department = selectedDepartment) => {
      axios
        .get(`${process.env.REACT_APP_BASE_URL}/departments/${department}/roles`, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        })
        .then((response) => {
          const data = response.data?.data || [];
          setRoleList(data);
        })
        .catch((error) => console.log(error));
    },
    [selectedDepartment]
  );

  const getPermissionsList = async () => {
    const apiUrl = process.env.REACT_APP_GET_PERMISSIONS_URL;
    try {
      const response = await axios.get(apiUrl, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
      const data = response.data.data;
      setPermissionsList(data);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getCompany();
    getPermissionsList();
  }, []);

  useEffect(() => {
    getDepartments();
  }, [getDepartments]);

  useEffect(() => {
    getRole();
  }, [getRole]);

  const RoleDetailsValidation = Yup.object({
    companyName: Yup.string().required("Company Name is required"),
    department: Yup.string().required("Department is required"),
    role_name: Yup.string().required("Role is required"),
  });

  const handleInputChange = (event, type, formik) => {
    const { name, value } = event.target;
    if (type === "company") {
      setSelectedCompany(value);
      formik.setFieldValue(name, value);
      getDepartments(value);
    } else if (type === "department") {
      setSelectedDepartment(value);
      formik.setFieldValue(name, value);
    }
  };

  const handleCloseModal = () => {
    setShowSuccessModal(false);
  };

  const handlePermissionChange = (permissionId) => {
    setPermissionsList((prevPermissions) =>
      prevPermissions.map((permission) =>
        permission.permission_id === permissionId
          ? { ...permission, selected: !permission.selected }
          : permission
      )
    );
  };

  const handleSubmit = (values) => {
    const { department, role_name } = values;
    const permission_ids = permissionsList
      .filter((permission) => permission.selected)
      .map((permission) => permission.permission_id);

    if (permission_ids.length === 0) {
      console.error("Select at least one permission.");
      return;
    }

    const config = {
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      },
    };

    const data = {
      dept_id: department,
      role_name: role_name,
      permission_ids: permission_ids,
    };

    axios
      .post(`${process.env.REACT_APP_BASE_URL}/roles`, data, config)
      .then((response) => setShowSuccessModal(true))
      .catch((error) => console.log("error", error));
  };

  return (
    <div className="board__layout">
      <div className="section-header">
        <div className="header__titles">
          <button onClick={() => navigate("/rolemanagement")}>
            <span>
              <i className="ri-arrow-left-s-line"></i>
            </span>
            BACK
          </button>
          <h4>Create Role and Permissions</h4>
        </div>
      </div>

      <Formik
        initialValues={{
          companyName: "",
          department: "",
          role_name: "",
        }}
        validationSchema={RoleDetailsValidation}
        onSubmit={handleSubmit}
      >
        {(formik) => (
          <Form style={{ padding: "30px" }}>
            <div className="form-rows">
              <div>
                <label htmlFor="companyName">Company Name</label>
                <Field
                  name="companyName"
                  as="select"
                  value={selectedCompany}
                  onChange={(e) => handleInputChange(e, "company", formik)}
                >
                  <option value="">Select the Company</option>
                  {companyList.map((company) => (
                    <option key={company.id} value={company.id}>
                      {company.company_name}
                    </option>
                  ))}
                </Field>
                <ErrorMessage name="companyName" component={TextError} />
              </div>
              <div>
                <label htmlFor="department">Department</label>
                <Field
                  name="department"
                  as="select"
                  value={selectedDepartment}
                  onChange={(e) => handleInputChange(e, "department", formik)}
                >
                  <option value="">Select the Department</option>
                  {departmentList.map((department) => (
                    <option key={department.id} value={department.id}>
                      {department.department_name}
                    </option>
                  ))}
                </Field>
                <ErrorMessage name="department" component={TextError} />
              </div>
            </div>

            <div className="form-rows">
              <div>
                <label htmlFor="role_name">New Role</label>
                <Field type="text" name="role_name" />
                <ErrorMessage name="role_name" component={TextError} />
              </div>
              <div></div>
            </div>

            <div className="role_management-permissions">
              <h5>Select Permissions</h5>
              <div className="role_management-container">
                {permissionsList.map((permission) => (
                  <div
                    key={permission.permission_id}
                    className="role_management-lists"
                  >
                    <div className="role_management-list">
                      <input
                        type="checkbox"
                        value={permission.permission_id}
                        checked={permission.selected}
                        onChange={() =>
                          handlePermissionChange(permission.permission_id)
                        }
                      />
                      <p>{permission.name}</p>
                    </div>
                    <hr />
                  </div>
                ))}
              </div>
            </div>

            <div className="role_management-btn">
              <button
                className="btn-skip"
                onClick={() => navigate("/rolemanagement")}
              >
                Close
              </button>
              <button className="btn-submit" type="submit">
                Save
              </button>
            </div>
          </Form>
        )}
      </Formik>
      <Modal
        isOpen={showSuccessModal}
        onRequestClose={() => setShowSuccessModal(false)}
        contentLabel="Role and Permission Update"
        className="react-modal-content"
        overlayClassName="react-modal-overlay"
      >
        <h5>Updated Successfully</h5>
        <button onClick={handleCloseModal}>OK</button>
      </Modal>
    </div>
  );
};

export default RoleManagementForm;
