import React from 'react'

const Recruitment = () => {
  return (
    <div>
      <h1>Recruitment</h1>
    </div>
  )
}

export default Recruitment
