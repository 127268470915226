import "../styles/attendance.css";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import SingleCard from "../components/Reusable/SingleCard";
import TimeNAttndanceTable from "../components/Reusable/TimeNAttndanceTable";
import AccessDenied from "../components/Reusable/AccessDenied";
import UtilityBarDates from "../components/Reusable/UtilityBarDates";
import {
  totalEmployees,
  totalPresent,
  lateLogins,
  monthlyLeave,
} from "../components/Reusable/CardData";

const TimeAttendance = () => {
  const accessToken = localStorage.getItem("accessToken");
  const permissions = JSON.parse(localStorage.getItem("userPermissions"));
  const [attendanceData, setAttendanceData] = useState([]);
  const [companyList, setCompanyList] = useState([]);
  const [selectedCompany, setSelectedCompany] = useState(
    localStorage.getItem("selectedCompany") || ""
  );
  const [showAccessDeniedPopup, setShowAccessDeniedPopup] = useState(false);
  const navigate = useNavigate();
  let punctingTime = "10:15:00";

  const getCompany = () => {
    axios
      .get(`${process.env.REACT_APP_BASE_URL}/companies`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      })
      .then((response) => {
        const data = response.data?.data || [];
        setCompanyList(data);
      })
      .catch((error) => console.log(error));
  };

  useEffect(() => {
    getCompany();
  }, []);

  const onChange = (e) => {
    setSelectedCompany(e.target.value);
  };

  const getEmployeeAttendance = async () => {
    try {
      let apiUrl = "";
      const hasViewAllManagedPermission = permissions.some(
        (permission) => permission.name === "view_attendances_managed_employee"
      );
      const hasViewPerGroupPermission = permissions.some(
        (permission) => permission.name === "view_attendances_per_group"
      );

      if (hasViewAllManagedPermission) {
        apiUrl = `${process.env.REACT_APP_BASE_URL}/attendances/managed`;
      } else if (hasViewPerGroupPermission) {
        apiUrl = `${process.env.REACT_APP_BASE_URL}/attendances?company=${selectedCompany}`;
      } else {
        console.log("User does not have required permissions.");
        return;
      }

      const response = await axios.get(apiUrl, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
      const dataObject = response.data?.data;
      const dataArray = Object.keys(dataObject).map((key) => dataObject[key]);

      setAttendanceData(dataArray);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getEmployeeAttendance();
  }, [selectedCompany]);

  const currentDate =
    attendanceData.length > 0 ? new Date(attendanceData[0].date) : null;
  let formattedDate = "";

  if (currentDate) {
    const day = currentDate.getDate().toString().padStart(2, "0");
    const month = (currentDate.getMonth() + 1).toString().padStart(2, "0");
    const year = currentDate.getFullYear();
    formattedDate = `${day}/${month}/${year}`;
  }

  const totalEmpEntries = attendanceData.length;
  let totalEmpLeave = attendanceData.filter(
    (item) => item.status !== "Present"
  ).length;
  let totalEmpPresent = totalEmpEntries - totalEmpLeave;

  const lateEmpLogins = attendanceData.map((item, index) => {
    const startTime = item.login_time;
    const isLate = startTime > punctingTime;
    return isLate;
  });

  const numberOfLateLogins = lateEmpLogins.filter((isLate) => isLate).length;

  // const setWorkingTime = () => {
  //   const hasSetWorkTimePermission = permissions.some(
  //     (permission) => permission.name === "create_emp_schedule"
  //   );

  //   if (hasSetWorkTimePermission) {
  //     navigate("/timesettings");
  //   } else {
  //     setShowAccessDeniedPopup(true);
  //   }
  // };

  const setWorkingTime = () => {
    navigate("/timesettings");
  };

  return (
    <div className="board__layout">
      <div className="section-header">
        <h1>Time & Attendance</h1>
        <div className="attendance_right-section">
          <div className="filter__icon">
            <i className="ri-home-4-fill"></i>
            <select
              className="filter-select"
              onChange={onChange}
              value={selectedCompany}
            >
              <option value="">Select the Company</option>
              {companyList.map((company) => (
                <option key={company.id} value={company.company_name}>
                  {company.company_name}
                </option>
              ))}
            </select>
          </div>
          <div className="attendance_header-btn">
            <button onClick={setWorkingTime}>+ Set Working Time</button>
          </div>
        </div>
      </div>

      <div className="attendance-cards">
        <SingleCard
          item={{
            ...totalEmployees,
            days: formattedDate,
            count: totalEmpEntries,
          }}
        />
        <SingleCard
          item={{
            ...totalPresent,
            days: formattedDate,
            count: totalEmpPresent,
          }}
        />
        <SingleCard
          item={{
            ...lateLogins,
            days: formattedDate,
            count: numberOfLateLogins,
          }}
        />
        <SingleCard
          item={{ ...monthlyLeave, days: formattedDate, count: totalEmpLeave }}
        />
      </div>

      <div style={{ marginTop: "2rem" }}>
        <TimeNAttndanceTable
          attendanceData={attendanceData}
          setAttendanceData={setAttendanceData}
        />
      </div>
      {showAccessDeniedPopup && (
        <div className="em_popup-overlay">
          <div className="em_popup-content">
            <AccessDenied setShowAccessDeniedPopup={setShowAccessDeniedPopup} />
          </div>
        </div>
      )}
    </div>
  );
};

export default TimeAttendance;
