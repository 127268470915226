import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import { useEffect, useState } from "react";
import axios from "axios";
import { MonthView } from "react-calendar";

const localizer = momentLocalizer(moment);

const LeaveCalendar = ({ selectedCompany, setViewLeaveCalendar }) => {
  const accessToken = localStorage.getItem("accessToken");
  const permissions = JSON.parse(localStorage.getItem("userPermissions"));
  const [attendanceData, setAttendanceData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [isMenuVisible, setIsMenuVisible] = useState(false);
  const [menuPosition, setMenuPosition] = useState({ top: 0, left: 20 });
  const [clickedDate, setClickedDate] = useState("");
  const [leaveColumnDate, setLeaveColumnDate] = useState("");

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const month = date.toLocaleString("default", { month: "short" });
    const day = date.getDate();
    return `${month} ${day}`;
  };

  const getEmployeeMonthWiseLeave = async () => {
    try {
      let apiUrl = "";

      const hasViewAllManagedLeaveHistory = permissions.some(
        (permission) => permission.name === "view_managed_leave_history"
      );
      const hasViewAllLeaveHistory = permissions.some(
        (permission) => permission.name === "view_all_leave_history"
      );

      if (hasViewAllManagedLeaveHistory) {
        apiUrl = `${process.env.REACT_APP_BASE_URL}/employees/leaveHistoryForManagers`;
      } else if (hasViewAllLeaveHistory) {
        apiUrl = `${process.env.REACT_APP_BASE_URL}/employees/companyWiseLeaveHistory?company=${selectedCompany}`;
      } else {
        alert("User does not have required permissions.");
        return;
      }

      const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/employees/companyWiseLeaveHistory?company=${selectedCompany}`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
      const dataObject = response.data?.data;
      const dataArray = Object.keys(dataObject).map((key) => dataObject[key]);

      setAttendanceData(dataArray);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  console.log("Leave Calendar Data", attendanceData)

  useEffect(() => {
    getEmployeeMonthWiseLeave();
  }, [selectedCompany]);

  const handleEventClick = (event, e) => {
    toggleMenu(e, event.start);
  };

  const handleSlotSelect = (slotInfo) => {
    toggleMenu(slotInfo, slotInfo.start);
  };

  const generateDates = (startDate, endDate) => {
    const dates = [];
    const current = new Date(startDate);
    const end = new Date(endDate);
    while (current <= end) {
      dates.push(new Date(current));
      current.setDate(current.getDate() + 1);
    }
    return dates;
  };

  const dayPropGetter = (date) => {
    const eventDates = myEventsList.map((event) => event.start);
    const matchingEvent = myEventsList.find((event) =>
      moment(event.start).isSame(date, "day")
    );

    if (matchingEvent) {
      return {
        style: {
          backgroundColor: matchingEvent.color,
        },
      };
    }
    return {};
  };

  const generateEventsList = (data) => {
    const filteredData = data.filter((item) => item.is_approved);
    const events = [];
    const colors = [
      "honeydew",
      "lightgreen",
      "lightcoral",
      "lightpink",
      "lightgoldenrodyellow",
    ];

    filteredData.forEach((item, index) => {
      const dates = generateDates(item.leave_from, item.leave_to);

      dates.forEach((date) => {
        const images = Array.isArray(item.user?.profile_picture)
          ? item.user?.profile_picture
          : [item.user?.profile_picture];
        const empNames = Array.isArray(item.user?.employee_full_name)
          ? item.user?.employee_full_name
          : [item.user?.employee_full_name];
        events.push({
          start: new Date(date),
          end: new Date(date),
          images: images,
          empNames: empNames,
          color: colors[index % colors.length],
        });
      });
    });
    return events;
  };

  const myEventsList = generateEventsList(attendanceData);

  const EventComponent = ({ event }) => {
    if (!event || !event.images || event.images.length === 0) {
      return null;
    }
    if (!event || !event.empNames || event.empNames.length === 0) {
      return null;
    }

    return (
      <div style={{ display: "flex", flexWrap: "wrap" }}>
        {event.images.map((image, index) => (
          <div
            key={index}
            onClick={(e) => toggleMenu(e, event.start)}
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              marginRight: "10px",
              marginBottom: "10px",
            }}
          >
            <div
              style={{
                background: `url(${image})`,
                backgroundSize: "cover",
                width: "40px",
                height: "40px",
                borderRadius: "50%",
              }}
            />
          </div>
        ))}
        {event.empNames.map((empName, index) => (
          <div
            key={index}
            onClick={(e) => toggleMenu(e, event.start)}
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              marginRight: "10px",
              marginBottom: "10px",
              marginTop: "15px",
              color: "black",
              fontSize: "10px",
            }}
          >
            <p>{empName}</p>
          </div>
        ))}
      </div>
    );
  };

  const toggleMenu = (event, date) => {
    const boundingClientRect = event.currentTarget.getBoundingClientRect();
    setMenuPosition({
      top: boundingClientRect.top + boundingClientRect.height,
      left: boundingClientRect.left + 20,
    });
    const formattedDate = new Date(date).toLocaleString("en-US", {
      year: "numeric",
      month: "long",
      day: "numeric",
      weekday: "long",
    });

    const leaveFormatdDate = moment(date).format("YYYY-MM-DD");
    setIsMenuVisible(!isMenuVisible);
    setClickedDate(formattedDate);
    setLeaveColumnDate(leaveFormatdDate);
  };

  const MenuComponent = () => (
    <div
      className="calendar_menu"
      style={{
        position: "absolute",
        top: menuPosition.top,
        left: menuPosition.left,
      }}
    >
      <h1 className="title">{clickedDate}</h1>
      <hr />
      <h4 className="sub_title">Approved Leaves</h4>
      {attendanceData
        .filter(
          (data) =>
            data.is_approved === 1 &&
            (moment(leaveColumnDate).isBetween(
              data.leave_from,
              data.leave_to,
              undefined,
              "[]"
            ) ||
              moment(data.leave_from).isSame(leaveColumnDate, "day") ||
              moment(data.leave_to).isSame(leaveColumnDate, "day"))
        )
        .map((data, index) => (
          <div className="user_data-container" key={index}>
            <div className="profile">
              <div>
                <img src={data?.user?.profile_picture} />
              </div>
              <div className="employee_detail">
                <p className="full_name">{data?.user?.employee_full_name}</p>
                <p className="profession">
                  {data?.user?.roles[0]?.role_name} | {data?.user?.employee_id}
                </p>
              </div>
            </div>
            <div className="days_count-section">
              <p className="day_details">
                {`${formatDate(data?.leave_from)} `}
                <span className="to">to</span>
                {` ${formatDate(data?.leave_to)}`}
              </p>
              <div className="days_count">
                <p className="count">{data?.days_count}</p>
                <p className="days">{data?.days_count > 1 ? "Days" : "Day"}</p>
              </div>
            </div>
          </div>
        ))}
    </div>
  );
  return (
    <div>
      <div style={{ height: 800 }}>
        <Calendar
          localizer={localizer}
          events={myEventsList}
          style={{ height: 700 }}
          components={{
            event: EventComponent,
          }}
          dayPropGetter={dayPropGetter}
          onSelectEvent={handleEventClick}
          onSelectSlot={handleSlotSelect}
        />
      </div>
      <button
        onClick={() => setViewLeaveCalendar(false)}
        className="calendar_close-btn"
      >
        X
      </button>
      {isMenuVisible && <MenuComponent />}
    </div>
  );
};

export default LeaveCalendar;
