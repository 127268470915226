import "../styles/employeeSalary.css";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { useFormik } from "formik";
import * as Yup from "yup";
import DocumentPopup from "../components/Reusable/DocumentPopup";
import ConfirmationPopup from "../components/Reusable/ConfirmationPopup";

const AddEmployeeSalary = () => {
  const accessToken = localStorage.getItem("accessToken");
  const currentYear = new Date().getFullYear();
  const [employeeData, setEmployeeData] = useState([]);
  const [payslipUrl, setPayslipUrl] = useState([]);
  const [showAddConfirmation, setShowAddConfirmation] = useState(false);
  const [showDocumentPopup, setShowDocumentPopup] = useState(false);
  const navigate = useNavigate();

  const initialValues = {
    employeeId: "",
    full_name: "",
    companyName: "",
    department: "",
    wpsSalary: "",
    commission: "",
    salary_month: "",
    paySlip: null,
  };

  const validationSchema = Yup.object({
    employeeId: Yup.string()
      .min(3, "Must be 3 characters or more")
      .max(15, "Must be 15 characters or less")
      .required("Required"),
    full_name: Yup.string()
      .min(3, "Must be 3 characters or more")
      .max(50, "Must be 50 characters or less")
      .required("Required"),
    companyName: Yup.string().required("Required"),
    department: Yup.string().required("Required!"),

    salary_month: Yup.date()
      .required("Enter a valid date")
      .typeError("Invalid date format")
      .test("year", "Year must be between 2000 and 2100", function (value) {
        if (!value) return true;
        const year = new Date(value).getFullYear();
        return year >= 2000 && year <= 2100 && year <= currentYear;
      }),
    paySlip: Yup.mixed()
      .required("Payslip is not uploaded")
      .test("fileType", "Invalid file format", (value) => {
        if (value && value.name) {
          return (
            value.type === "application/pdf" ||
            value.type === "image/jpeg" ||
            value.type === "image/jpg" ||
            value.type === "image/png"
          );
        }
        return true;
      }),
  });

  const getEmployeeData = async (companyId, formik) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/employees/informationBycompanyId?companyId=${companyId}`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            "Content-Type": "multipart/form-data",
          },
        }
      );

      const data = response.data?.data;
      setEmployeeData(data);

      if (data && data.length > 0) {
        formik.setFieldValue(
          "full_name",
          data[0]?.users?.employee_full_name || ""
        );
        formik.setFieldValue(
          "companyName",
          data[0]?.users?.roles[0]?.company[0]?.company_name || ""
        );
        formik.setFieldValue(
          "department",
          data[0]?.users?.roles[0]?.department_name || ""
        );
      }
    } catch (error) {
      console.log(error);
    }
  };

  const empId = employeeData[0]?.users?.id;

  const getPaySlip = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/payroll/${empId}/payslip`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            "Content-Type": "multipart/form-data",
          },
        }
      );

      const data = response.data?.data;
      setPayslipUrl(data);
    } catch (error) {
      console.log(error);
    }
  };

  const handleSubmit = async (values) => {
    try {
      const formData = new FormData();
      formData.append("payslip", values.paySlip, values.paySlip.name);
      formData.append("date", values.salary_month);

      const config = {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          Accept: "application/json",
        },
      };

      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/payroll/${empId}/payslip`,
        formData,
        config
      );

      
      getPaySlip();
      setShowAddConfirmation(false);
      setShowDocumentPopup(true);
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleSaveButton = () => {
    setShowAddConfirmation(true);
  };

  const cancelExit = () => {
    setShowAddConfirmation(null);
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: handleSubmit,
  });

  

  return (
    <div className="board__layout">
      <div className="section-header">
        <div className="header__titles">
          <button onClick={() => navigate("/employeesalary")}>
            <span>
              <i className="ri-arrow-left-s-line"></i>
            </span>
            BACK
          </button>
          <h4>Add Pay Slip</h4>
        </div>
      </div>

      <form onSubmit={formik.handleSubmit} style={{ padding: "30px" }}>
        <div className="form-rows">
          <div>
            <label htmlFor="employeeId">Employee Id</label>
            <input
              type="text"
              id="employeeId"
              name="employeeId"
              placeholder="Enter Employee Id"
              onChange={(e) => {
                const inputValue = e.target.value.toUpperCase();
                e.target.value = inputValue;
                formik.handleChange(e);
                clearTimeout(formik.values.timeoutId);
                const timeoutId = setTimeout(() => {
                  getEmployeeData(inputValue, formik);
                }, 500);
                formik.setFieldValue("timeoutId", timeoutId);
              }}
              onBlur={formik.handleBlur}
              value={formik.values.employeeId}
            />
            {formik.errors.employeeId && formik.touched.employeeId && (
              <div className="error-message">{formik.errors.employeeId}</div>
            )}
          </div>

          <div>
            <label htmlFor="full_name">Employee First Name</label>
            <input
              type="text"
              id="full_name"
              name="full_name"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.full_name}
            />
            {formik.errors.full_name && formik.touched.full_name && (
              <div className="error-message">{formik.errors.full_name}</div>
            )}
          </div>
        </div>

        <div className="form-rows">
          <div>
            <label htmlFor="companyName">Company Name</label>
            <input
              name="companyName"
              type="text"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.companyName}
            />
            {formik.errors.companyName && formik.touched.companyName && (
              <div className="error-message">{formik.errors.companyName}</div>
            )}
          </div>

          <div>
            <label htmlFor="department">Department</label>
            <input
              name="department"
              type="text"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.department}
            />
            {formik.errors.department && formik.touched.department && (
              <div className="error-message">{formik.errors.department}</div>
            )}
          </div>
        </div>

        <div className="form-rows">
          <div>
            <label htmlFor="paySlip">Salary Month</label>
            <input
              name="salary_month"
              type="date"
              id="salary_month"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.salary_month}
            />
            {formik.errors.salary_month && formik.touched.salary_month && (
              <div className="error-message">{formik.errors.salary_month}</div>
            )}
          </div>

          <div>
            <label htmlFor="paySlip">Upload Payslip</label>
            <input
              name="paySlip"
              className="custom-file-upload"
              type="file"
              id="paySlip"
              onChange={(e) => {
                formik.setFieldValue("paySlip", e.currentTarget.files[0]);
              }}
              onBlur={formik.handleBlur}
            />
            {formik.errors.paySlip && formik.touched.paySlip && (
              <div className="error-message">{formik.errors.paySlip}</div>
            )}
          </div>
        </div>

        <div className="user-btn-section">
          <div style={{ marginTop: "1rem" }}>
            <button
              type="button"
              onClick={handleSaveButton}
              className="form-btn-submit"
            >
              Save & Update
            </button>
          </div>
        </div>
        {showAddConfirmation && (
          <ConfirmationPopup
            confirm={handleSubmit}
            cancel={cancelExit}
            title="Add New Pay Slip"
            question="Are you sure that you want to Save the data?"
          />
        )}
      </form>

      {showDocumentPopup && payslipUrl.length > 0 && (
        <DocumentPopup
          documentUrl={payslipUrl[payslipUrl.length - 1].payslip_url}
          onClose={() => setShowDocumentPopup(false)}
        />
      )}
    </div>
  );
};

export default AddEmployeeSalary;
