import "../styles/dashboard.css";
import "../styles/attendance.css";
import SingleCard from "../components/Reusable/SingleCard";
import NewlyBoardedCard from "../components/Reusable/NewlyBoardedCard";
import userPurple from "../assets/images/user-group-purple.png";
import userBlue from "../assets/images/user-group-blue.png";
import userYellow from "../assets/images/user-group-yellow.png";
import userRed from "../assets/images/user-group-red.png";
import OnBoardingTable from "../components/Reusable/OnBoardingTable";
import { useNavigate } from "react-router-dom";

const newEmployee = {
  iconImg: userPurple,
  iconBgColor: "color__purple-light",
  monthColor: "color__purple__dark",
  monthBgColor: "color__bg-purple__light",
  title: "New Employee",
  count: "47",
  days: "Last 30 Days",
  month: "+11 this month",
};

const offerLetterGenerated = {
  iconImg: userRed,
  iconColor: "color__red-dark",
  iconBgColor: "color__red-light",
  monthColor: "color__red__dark",
  monthBgColor: "color__bg-red__light",
  title: "Offer Letter Generated",
  count: "40",
  days: "Last 30 Days",
  month: "+11 this month",
};

const offerLetterAccepted = {
  iconImg: userBlue,
  iconColor: "color__blue-dark",
  iconBgColor: "color__blue-light",
  monthColor: "color__blue__dark",
  monthBgColor: "color__bg-blue__light",
  title: "Offer Letter Accepted",
  count: "20",
  days: "Last 30 Days",
  month: "+11 this month",
};

const offerLetterRejected = {
  iconImg: userYellow,
  iconColor: "color__yellow-dark",
  iconBgColor: "color__yellow-light",
  monthColor: "color__yellow__dark",
  monthBgColor: "color__bg-yellow__light",
  title: "Offer Letter Rejected",
  count: "11",
  days: "Last 30 Days",
  month: "+11 this month",
};

const OnBoard = () => {
  const navigate = useNavigate();

  const handleAddEmployeeClick = () => {
    navigate("/addemployee");
  };

  return (
    <div className="board__layout">
      <div className="section-header">
        <h1>Onboarding</h1>
        <div style={{ display: "flex", columnGap: "1rem" }}>
          <div className="filter__icon">
            <i className="ri-home-4-fill"></i>
            <select className="filter-select">
              <option value="name">Select Company</option>
              <option value="ttf">The Thought Factory</option>
              <option value="scalex">scalex</option>
              <option value="rms">Rhapsody</option>
            </select>
          </div>
          <div className="emp__second-header__category04">
            <button onClick={handleAddEmployeeClick}>
              <span>
                <i className="ri-add-line"></i>
              </span>
              Add Employee
            </button>
          </div>
        </div>
      </div>
      <div className="section-wrapper">
        <div className="onboard-cards">
          <SingleCard item={newEmployee} />
          <SingleCard item={offerLetterGenerated} />
          <SingleCard item={offerLetterAccepted} />
          <SingleCard item={offerLetterRejected} />
        </div>

        <div className="leave__request-list">
          <div className="leave__main-section">
            <div className="leave__section-one ">
              <div className="color__red-light card__icon-img">
                <img src={userRed} alt="icon" />
              </div>
              <h4>112</h4>
            </div>
            <div className="leave__section-two">
              <p>Newly Boarded</p>
              <p>Last 30 Days</p>
            </div>
          </div>
          <NewlyBoardedCard />
        </div>
      </div>
      <div style={{ marginBottom: "5px" }}></div>
      <div>
        <OnBoardingTable />
      </div>
    </div>
  );
};

export default OnBoard;
