import "../../styles/empManagement.css";
import axios from "axios";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import AccessDenied from "./AccessDenied";
import ConfirmationPopup from "./ConfirmationPopup";
import { capitalizeWords } from "./capitalizeWords";
import { saveAs } from "file-saver";
import loadingGif from "../../assets/images/loading-loading-forever.gif";
import { format } from "date-fns";

const EmployeeManagementTable = (props) => {
  const permissions = JSON.parse(localStorage.getItem("userPermissions"));
  const accessToken = localStorage.getItem("accessToken");
  const userData = localStorage.getItem("userData");
  const { selectedCompany, selectedCompanyId, selectedFilter, searchQuery } =
    props;
  const [employeeData, setEmployeeData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedEmployeeIds, setSelectedEmployeeIds] = useState([]);
  const [selectedCheckboxId, setSelectedCheckboxId] = useState(null);
  const [showAccessDeniedPopup, setShowAccessDeniedPopup] = useState(false);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [showAccessPage403, setShowAccessPage403] = useState(false);
  const [employeeInformationId, setEmployeeInformationId] = useState(null);
  const [sortOrder, setSortOrder] = useState({});
  const [itemsPerPage] = useState(10);
  const navigate = useNavigate();

  useEffect(() => {
    const userDataFilter = JSON.parse(userData);
    const empInfoId = userDataFilter.user[0].employee_information_id;
    setEmployeeInformationId(empInfoId);
  }, []);

  const hasViewPerGroupPermission = permissions.some(
    (permission) => permission.name === "view_per_group_all_employees_info"
  );
  const hasViewAllManagedPermission = permissions.some(
    (permission) => permission.name === "view_all_managed_employees_info"
  );

  const getEmployeeData = async () => {
    try {
      let apiUrl = "";
      const timestamp = new Date().getTime();

      if (hasViewPerGroupPermission) {
        apiUrl = `${process.env.REACT_APP_EMP_DATA_COMPANY_URL}=${selectedCompany}&timestamp=${timestamp}`;
      } else if (hasViewAllManagedPermission) {
        apiUrl = `${process.env.REACT_APP_EMP_DATA_MANAGERS_URL}timestamp=${timestamp}`;
      } else {
        setShowAccessPage403(true);
        return;
      }

      const response = await axios.get(apiUrl, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });

      const data = response.data?.data;
      const employees = data ? data.flatMap((item) => item.users) : [];

      let filteredAndSortedEmployees = [...employees];

      if (selectedFilter === "Active") {
        filteredAndSortedEmployees = employees.filter(
          (employee) => employee.is_active === 1
        );
      } else if (selectedFilter === "In Active") {
        filteredAndSortedEmployees = employees.filter(
          (employee) => employee.is_active === 0
        );
      }

      if (selectedFilter === "Sort By Name") {
        filteredAndSortedEmployees.sort((a, b) =>
          a.employee_full_name.localeCompare(b.employee_full_name)
        );
      } else if (selectedFilter === "Sort By Employee ID") {
        filteredAndSortedEmployees.sort((a, b) =>
          compareAlphanumeric(a.employee_id, b.employee_id)
        );
      }

      setEmployeeData(filteredAndSortedEmployees);
    } catch (error) {
      console.log(error);
    }
  };

  const compareAlphanumeric = (a, b) => {
    const numA = parseInt(a.match(/\d+/)[0]);
    const numB = parseInt(b.match(/\d+/)[0]);

    if (numA === numB) {
      return a.localeCompare(b);
    }

    return numA - numB;
  };

  useEffect(() => {
    getEmployeeData();
    setCurrentPage(1);
  }, [selectedCompany, selectedFilter, searchQuery]);

  useEffect(() => {
    if (searchQuery) {
      const foundIndex = employeeData.findIndex(
        (user) =>
          user.employee_full_name
            .toLowerCase()
            .includes(searchQuery.toLowerCase()) ||
          user.employee_id.toLowerCase().includes(searchQuery.toLowerCase())
      );
      if (foundIndex !== -1) {
        const page = Math.ceil((foundIndex + 1) / itemsPerPage);
        setCurrentPage(page);
      }
    }
  }, [searchQuery, employeeData, itemsPerPage]);

  const handleCheckboxChange = (employeeId) => {
    if (selectedCheckboxId === employeeId) {
      setSelectedCheckboxId(null);
      setSelectedEmployeeIds([]);
    } else {
      setSelectedCheckboxId(employeeId);
      setSelectedEmployeeIds([employeeId]);
    }
  };

  const handleEdit = () => {
    if (selectedEmployeeIds.length === 0) {
      alert("Choose any employee");
    } else {
      const hasEditPermission = permissions.some(
        (permission) => permission.name === "update_employee_info"
      );

      if (!hasEditPermission) {
        setShowAccessDeniedPopup(true);
        return;
      }

      const idToNavigate = selectedEmployeeIds[0];
      const newPath = `/employeedirectory/${idToNavigate}`;
      navigate(newPath, { replace: true });
    }
  };

  const handleView = () => {
    if (selectedEmployeeIds.length === 0) {
      alert("Choose any employee");
    }

    const idToNavigate = selectedEmployeeIds[0];
    const newPath = `/viewdirectory/${idToNavigate}`;
    navigate(
      newPath,

      { replace: true }
    );
  };

  const moveToEmployeeDetails = (id) => {
    const hasEditPermission = permissions.some(
      (permission) => permission.name === "update_employee_info"
    );

    if (!hasEditPermission) {
      setShowAccessDeniedPopup(true);
      return;
    }

    const newPath = `/viewdirectory/${id}`;
    navigate(newPath, { replace: true });
  };

  const handleDelete = async () => {
    if (selectedEmployeeIds.length === 0) {
      alert("Choose any employee");
      return;
    }

    const hasDeletePermission = permissions.some(
      (permission) => permission.name === "delete_employee_info"
    );

    if (!hasDeletePermission) {
      alert("You don't have permission to delete employees");
      return;
    }

    setShowDeleteConfirmation(true);
  };

  const confirmDelete = async () => {
    try {
      const accessToken = localStorage.getItem("accessToken");
      let deactivatedEmployeeEncountered = false;

      for (const id of selectedEmployeeIds) {
        const employeeToBeDeleted = employeeData.find(
          (employee) => employee.id === id
        );

        if (employeeToBeDeleted && employeeToBeDeleted.is_active === 0) {
          alert(
            `Employee ${employeeToBeDeleted.employee_full_name} is already deactivated`
          );
          deactivatedEmployeeEncountered = true;
          continue;
        }

        await axios.patch(
          `${process.env.REACT_APP_BASE_URL}/employees/employeeInformation/${id}`,
          { is_active: 0 },
          {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          }
        );
      }
      getEmployeeData();
      setSelectedEmployeeIds([]);
      if (!deactivatedEmployeeEncountered) {
        alert("Selected employees deactivated successfully!");
      }
      setShowDeleteConfirmation(false);
    } catch (error) {
      console.log(error);
    }
  };

  const cancelDelete = () => {
    setShowDeleteConfirmation(false);
  };

  // Download the data in excel format

  const handleDownload = async () => {
    try {
      let apiUrl = "";

      if (hasViewPerGroupPermission) {
        apiUrl = `${process.env.REACT_APP_BASE_URL}/BulkDataDownload?company_id=${selectedCompanyId}`;
        console.log(apiUrl);
      } else if (hasViewAllManagedPermission) {
        apiUrl = `${process.env.REACT_APP_BASE_URL}/BulkDataDownload?employee_id=${employeeInformationId}`;
        console.log(apiUrl);
      } else {
        setShowAccessPage403(true);
        return;
      }

      const response = await axios.get(apiUrl, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
        responseType: "blob",
      });
      console.log("response data", response);
      const blob = new Blob([response.data], { type: "application/xml" });
      saveAs(blob, "employee data.xml");
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  // Get paginated data

  const getPaginatedData = () => {
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const startingSerialNumber = (currentPage - 1) * itemsPerPage + 1;

    let paginatedData = employeeData
      .slice(indexOfFirstItem, indexOfLastItem)
      .map((user, index) => ({
        ...user,
        serialNumber: startingSerialNumber + index,
      }));

    if (searchQuery) {
      paginatedData = paginatedData.filter(
        (user) =>
          user.employee_full_name
            .toLowerCase()
            .includes(searchQuery.toLowerCase()) ||
          user.employee_id.toLowerCase().includes(searchQuery.toLowerCase())
      );
    }

    return paginatedData;
  };

  const goToPreviousPage = () => {
    setCurrentPage((prevPage) => prevPage - 1);
  };

  const goToNextPage = () => {
    setCurrentPage((prevPage) => prevPage + 1);
  };

  const truncateEmail = (email) => {
    if (email && email.length > 25) {
      return email.substring(0, 25) + "...";
    }
    return email;
  };
  const truncateemployeeName = (userName) => {
    if (userName && userName.length > 15) {
      return userName.substring(0, 15) + "...";
    }
    return userName;
  };

  const handleSort = (columnName) => {
    const currentOrder = sortOrder[columnName];

    const newOrder = currentOrder === "asc" ? "desc" : "asc";
    setSortOrder({ [columnName]: newOrder });
    const sortedData = [...employeeData].sort((a, b) => {
      if (columnName === "is_active") {
        if (newOrder === "asc") {
          return a[columnName] - b[columnName];
        } else {
          return b[columnName] - a[columnName];
        }
      } else {
        if (newOrder === "asc") {
          return a[columnName].localeCompare(b[columnName]);
        } else {
          return b[columnName].localeCompare(a[columnName]);
        }
      }
    });
    setEmployeeData(sortedData);
  };

  console.log("EMTable", employeeData);

  return (
    <div>
      <div className="utility_bar-management">
        <button onClick={handleDownload}>
          <span>
            <i className="ri-download-line"></i> Download
          </span>
        </button>
        <button onClick={handleView}>
          <span>
            <i className="ri-eye-line"></i>
            View
          </span>
        </button>
        <button onClick={handleEdit}>
          <span>
            <i className="ri-edit-line"></i>
            Edit
          </span>
        </button>
        <button onClick={handleDelete}>
          <span>
            <i className="ri-delete-bin-line"></i> Delete
          </span>
        </button>
      </div>
      {showDeleteConfirmation && (
        <ConfirmationPopup
          confirm={confirmDelete}
          cancel={cancelDelete}
          title="Delete the Employee"
          question="Are you sure that you want to delete the employee?"
        />
      )}
      <table>
        <thead>
          <tr>
            <th></th>
            <th>SL No.</th>
            <th onClick={() => handleSort("employee_id")}>
              Employee ID {sortOrder["employee_id"] === "asc" ? "↑" : "↓"}
            </th>

            <th colSpan="2" onClick={() => handleSort("employee_full_name")}>
              Full Name {sortOrder["employee_full_name"] === "asc" ? "↑" : "↓"}
            </th>
            <th onClick={() => handleSort("personal_phone_number")}>
              Phone Number{" "}
              {sortOrder["personal_phone_number"] === "asc" ? "↑" : "↓"}
            </th>
            <th onClick={() => handleSort("official_email")}>
              Email {sortOrder["official_email"] === "asc" ? "↑" : "↓"}
            </th>
            <th onClick={() => handleSort("updated_at")}>
              Last Updated{" "}
              {sortOrder["personal_phone_number"] === "asc" ? "↑" : "↓"}
            </th>
            <th onClick={() => handleSort("is_active")}>
              Status {sortOrder["is_active"] === "asc" ? "↑" : "↓"}
            </th>
          </tr>
        </thead>
        <tbody>
          {employeeData.length > 0 ? (
            getPaginatedData().map((user) => (
              <tr key={user.id}>
                <td>
                  <input
                    type="checkbox"
                    name="em_checkbox"
                    id={`em_checkbox_${user.id}`}
                    onChange={() =>
                      handleCheckboxChange(user.employee_information_id)
                    }
                    checked={selectedEmployeeIds.includes(
                      user.employee_information_id
                    )}
                    disabled={
                      selectedCheckboxId &&
                      selectedCheckboxId !== user.employee_information_id
                    }
                  />
                </td>
                <td>{user?.serialNumber}</td>
                <td
                  style={{ cursor: "pointer" }}
                  onClick={() =>
                    moveToEmployeeDetails(user?.employee_information_id)
                  }
                >
                  {user?.employee_id}
                </td>
                <td>
                  <img
                    src={`${user?.profile_picture}?timestamp=${Date.now()}`}
                    alt="Profile"
                  />{" "}
                </td>
                <td className="management_empDetails tooltip-container">
                  <div
                    className="management_empName"
                    style={{ cursor: "pointer" }}
                    onClick={() =>
                      moveToEmployeeDetails(user?.employee_information_id)
                    }
                  >
                    <span className="tooltip-text">
                      {capitalizeWords(user?.employee_full_name)}
                    </span>
                    {capitalizeWords(
                      truncateemployeeName(user?.employee_full_name)
                    )}
                  </div>
                  <div className="management_designation">
                    {capitalizeWords(user?.roles[0]?.role_name)}
                  </div>
                </td>
                <td>{user?.personal_phone_number}</td>
                <td>
                  <div className="tooltip-container">
                    <span className="tooltip-text">{user?.official_email}</span>
                    {truncateEmail(user?.official_email)}
                  </div>
                </td>
                <td>{format(new Date(user.updated_at), "dd-MM-yyyy")}</td>
                <td className={user?.is_active ? "active" : "inactive"}>
                  {user?.is_active ? "Active" : "In Active"}
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="9">
                <img
                  src={loadingGif}
                  alt="Loading"
                  style={{ width: "50px", height: "50px" }}
                />
              </td>
            </tr>
          )}
        </tbody>
      </table>
      {showAccessDeniedPopup && (
        <div className="em_popup-overlay">
          <div className="em_popup-content">
            <AccessDenied setShowAccessDeniedPopup={setShowAccessDeniedPopup} />
          </div>
        </div>
      )}

      <div className="pagination">
        <div>
          <button onClick={goToPreviousPage} disabled={currentPage === 1}>
            <i className="fa-solid fa-arrow-left-long"></i> Previous
          </button>
        </div>
        <div>
          {Array.from(
            { length: Math.ceil(employeeData.length / itemsPerPage) },
            (_, index) => {
              const startPage = currentPage - 5 > 0 ? currentPage - 5 : 1;
              const endPage =
                startPage + 9 < Math.ceil(employeeData.length / itemsPerPage)
                  ? startPage + 9
                  : Math.ceil(employeeData.length / itemsPerPage);
              return (
                index + 1 >= startPage &&
                index + 1 <= endPage && (
                  <button
                    key={index}
                    onClick={() => setCurrentPage(index + 1)}
                    className={currentPage === index + 1 ? "active" : ""}
                  >
                    {index + 1}
                  </button>
                )
              );
            }
          )}
        </div>
        <div>
          <button
            onClick={goToNextPage}
            disabled={employeeData.length <= currentPage * itemsPerPage}
          >
            Next <i className="fa-solid fa-arrow-right-long"></i>
          </button>
        </div>
      </div>
    </div>
  );
};

export default EmployeeManagementTable;
