import React, { useState, useMemo, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Formik, Field, Form, ErrorMessage, FieldArray } from "formik";
import * as Yup from "yup";
import TextError from "../MultiStep/TextError";
import axios from "axios";
import countryList from "react-select-country-list";
import Modal from "react-modal";
Modal.setAppElement("#root");

const QualificationUpdate = () => {
  const accessToken = localStorage.getItem("accessToken");
  const { id } = useParams();
  const navigate = useNavigate();
  const [employeeData, setEmployeeData] = useState([]);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [educationList, setEducationList] = useState([]);
  const [fieldOfStudyList, setFieldOfStudyList] = useState([]);
  const [majorOfStudyList, setMajorOfStudyList] = useState([]);
  const countries = useMemo(() => countryList().getData(), []);

  const fetchEmployeeData = async (id) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/employees/employeeInformation/${id}`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      const data = response.data?.data;
      setEmployeeData(data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchEmployeeData(id);
  }, [id]);

  const getEducation = () => {
    axios
      .get(`${process.env.REACT_APP_BASE_URL}/employees/educationLevel`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      })
      .then((response) => {
        const data = response.data || [];
        setEducationList(data);
      })
      .catch((error) => console.log(error));
  };

  const getFieldOfStudy = () => {
    axios
      .get(`${process.env.REACT_APP_BASE_URL}/employees/field-of-studies`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      })
      .then((response) => {
        const data = response.data || [];
        setFieldOfStudyList(data);
      })
      .catch((error) => console.log(error));
  };

  const getMajorOfStudy = () => {
    axios
      .get(`${process.env.REACT_APP_BASE_URL}/employees/major-of-studies`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      })
      .then((response) => {
        const data = response.data || [];
        setMajorOfStudyList(data);
      })
      .catch((error) => console.log(error));
  };

  useEffect(() => {
    getEducation();
    getFieldOfStudy();
    getMajorOfStudy();
  }, []);

  const QualificationValidation = Yup.object().shape({
    qualification: Yup.array().of(
      Yup.object().shape({
        qualification_name: Yup.string().required("Education is required"),
        field_of_study: Yup.string().required("Field of Study is required"),
        university_name: Yup.string().required("University is required"),
        country: Yup.string().required("Country is required"),
        course_name: Yup.string().required("Major of Study is required"),
        passout_year: Yup.string().required("Year of Passout is required"),
      })
    ),
  });

  const qualificationToId = {};
  educationList.forEach((qualification) => {
    qualificationToId[qualification.qualification_name] = qualification.id;
  });

  const majorStudyNameToId = {};
  majorOfStudyList.forEach((majorStudy) => {
    majorStudyNameToId[majorStudy.main_course] = majorStudy.id;
  });

  const studyFieldNameToId = {};
  fieldOfStudyList.forEach((studyField) => {
    studyFieldNameToId[studyField.field_name] = studyField.id;
  });

  const handleSubmit = async (values) => {
    try {
      const requestBody = {
        id: employeeData[0].id,
        qualifications: values.qualification.map((q) => ({
          education_level_id: qualificationToId[q.qualification_name],
          field_of_studies_id: studyFieldNameToId[q.field_of_study],
          university_name: q.university_name,
          country: q.country,
          major_of_studies_id: majorStudyNameToId[q.course_name],
          passout_year: q.passout_year,
          is_approved: 1,
        })),
      };

      const response = await axios.put(
        `${process.env.REACT_APP_BASE_URL}/employees/qualifications`,
        requestBody,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      setShowSuccessModal(true);
      
    } catch (error) {
      console.log("Error updating data:", error.response);
      console.log("handle submit", values);
    }
  };

  const handleCloseModal = () => {
    setShowSuccessModal(false);
    navigate(`/employeedirectory/${id}`);
  };

  return (
    <div className="board__layout">
      <div className="section-header">
        <h1>Update Qualifications</h1>
      </div>
      {employeeData.map((employee) => (
        <Formik
          key={employeeData[0].id}
          initialValues={{
            qualification: employeeData[0].qualifications.map((skills) => ({
              qualification_name: skills.education_level_name || "",
              field_of_study: skills.field_of_study_name || "",
              university_name: skills.university_name || "",
              country: skills.country || "",
              course_name: skills.major_of_study_name || "",
              passout_year: skills.passout_year || "",
            })),
          }}
          validationSchema={QualificationValidation}
          onSubmit={handleSubmit}
          enableReinitialize
        >
          {({ values }) => (
            <Form style={{ padding: "30px" }}>
              <FieldArray name="qualification">
                {({ insert, remove, push }) => (
                  <div>
                    {values.qualification.length > 0 &&
                      values.qualification.map((qual, index) => (
                        <div key={index} style={{ marginBottom: "3rem" }}>
                          <div className="form-rows">
                            <div>
                              <label
                                htmlFor={`qualification.${index}.qualification_name`}
                              >
                                Education
                              </label>
                              <Field
                                name={`qualification.${index}.qualification_name`}
                                as="select"
                              >
                                <option value="">Select the Education </option>

                                {educationList.map((education) => (
                                  <option
                                    key={education.id}
                                    value={education.qualification_name}
                                  >
                                    {education.qualification_name}
                                  </option>
                                ))}
                              </Field>
                              <ErrorMessage
                                name={`qualification.${index}.qualification_name`}
                                component="div"
                                className="error-message"
                              />
                            </div>

                            <div>
                              <label
                                htmlFor={`qualification.${index}.field_of_study`}
                              >
                                Field of Study
                              </label>
                              <Field
                                name={`qualification.${index}.field_of_study`}
                                as="select"
                              >
                                <option value="">
                                  Select the Field of Study
                                </option>
                                {fieldOfStudyList.map((studyField) => (
                                  <option
                                    key={studyField.id}
                                    value={studyField.field_name}
                                  >
                                    {studyField.field_name}
                                  </option>
                                ))}
                              </Field>
                              <ErrorMessage
                                name={`qualification.${index}.field_of_study`}
                                component="div"
                                className="error-message"
                              />
                            </div>
                          </div>
                          <div className="form-rows">
                            <div>
                              <label
                                htmlFor={`qualification.${index}.university_name`}
                              >
                                Name of University
                              </label>
                              <Field
                                name={`qualification.${index}.university_name`}
                                type="text"
                                id={`qualification.${index}.university_name`}
                                placeholder="Name of University"
                              ></Field>
                              <ErrorMessage
                                name={`qualification.${index}.university_name`}
                                component="div"
                                className="error-message"
                              />
                            </div>

                            <div>
                              <label htmlFor={`qualification.${index}.country`}>
                                Country
                              </label>
                              <Field
                                name={`qualification.${index}.country`}
                                as="select"
                              >
                                <option value="">Select Country</option>

                                {countries.map((country, index) => (
                                  <option value={country.id} key={index}>
                                    {" "}
                                    {country.label}{" "}
                                  </option>
                                ))}
                              </Field>
                              <ErrorMessage
                                name={`qualification.${index}.country`}
                                component="div"
                                className="error-message"
                              />
                            </div>
                          </div>
                          <div className="form-rows">
                            <div>
                              <label
                                htmlFor={`qualification.${index}.course_name`}
                              >
                                Major of Study
                              </label>
                              <Field
                                name={`qualification.${index}.course_name`}
                                as="select"
                              >
                                <option value="">
                                  Select the Major of Study{" "}
                                </option>
                                {majorOfStudyList.map((majorStudy) => (
                                  <option
                                    key={majorStudy.id}
                                    value={majorStudy.main_course}
                                  >
                                    {majorStudy.main_course}
                                  </option>
                                ))}
                              </Field>
                              <ErrorMessage
                                name={`qualification.${index}.course_name`}
                                component="div"
                                className="error-message"
                              />
                            </div>

                            <div>
                              <label
                                htmlFor={`qualification.${index}.other_skills`}
                              >
                                Other Qualifiation / Skills
                              </label>
                              <Field
                                name={`qualification.${index}.other_skills`}
                                type="text"
                                placeholder="Additional Skills/ Qualifications"
                              ></Field>
                              <ErrorMessage
                                name={`qualification.${index}.other_skills`}
                                component="div"
                                className="error-message"
                              />
                            </div>
                          </div>

                          <div className="form-rows">
                            <div>
                              <label
                                htmlFor={`qualification.${index}.passout_year`}
                              >
                                Year of Passout
                              </label>
                              <Field
                                name={`qualification.${index}.passout_year`}
                                type="date"
                              ></Field>
                              <ErrorMessage
                                name={`qualification.${index}.passout_year`}
                                component="div"
                                className="error-message"
                              />
                            </div>
                            <div></div>
                          </div>

                          <div className="col">
                            {index > 0 && (
                              <button
                                type="button"
                                className="formik-delete-btn"
                                onClick={() => remove(index)}
                              >
                                Delete
                              </button>
                            )}
                          </div>
                        </div>
                      ))}

                    <button
                      type="button"
                      className="formik-addMore-btn"
                      onClick={() =>
                        push({
                          qualification_name: "",
                          field_of_study: "",
                          university_name: "",
                          country: "",
                          course_name: "",
                          passout_year: "",
                          is_approved: 1,
                        })
                      }
                    >
                      Add More Qualification +
                    </button>
                  </div>
                )}
              </FieldArray>
              <div className="form-btn-div">
                <button
                  type="button"
                  className="btn-skip"
                  onClick={() => navigate(`/employeedirectory/${id}`)}
                >
                  Close
                </button>
                <button type="submit" className="form-btn-submit">
                  Update
                </button>
              </div>
            </Form>
          )}
        </Formik>
      ))}
      <Modal
        isOpen={showSuccessModal}
        onRequestClose={() => setShowSuccessModal(false)}
        contentLabel="Data Updated Successfully"
        className="react-modal-content"
        overlayClassName="react-modal-overlay"
      >
        <h5>Details Updated Successfully</h5>
        <button onClick={handleCloseModal}>OK</button>
      </Modal>
    </div>
  );
};

export default QualificationUpdate;
