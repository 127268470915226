import "../styles/employeeSalary.css";
import PaymentHistoryTableNew from "../components/Reusable/PaymentHistoryTable";


const PaymentHistory = () => {
  return (
    <div className="board__layout">
      <div className="section-header">
        <h1>Payment History</h1>
      </div>
      
      <div>
        <PaymentHistoryTableNew />
      </div>
    </div>
  );
};

export default PaymentHistory;
