import { useNavigate } from "react-router-dom";
import "../styles/employeeSalary.css";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import TextError from "../components/Reusable/TextError";

const initialValues = {
  companyName: "",
  empName: "",
  empEmailId: "",
  empMobile: "",
  empPosition: "",
  joiningDate: "",
  offerLetter: "",
};

const validationSchema = Yup.object().shape({
  companyName: Yup.string().required("*This field is required !"),
  empName: Yup.string().required("*This field is required !"),
  empEmailId: Yup.string().email("Invalid email format").required("Required!"),
  empMobile: Yup.string().required("*This field is required !"),
  empPosition: Yup.string().required("*This field is required !"),
  joiningDate: Yup.string().required("*This field is required !"),
  offerLetter: Yup.string().required("*This field is required !"),
});

const onSubmit = (values, actions) => {
  setTimeout(() => {
    alert(JSON.stringify(values, null, 2));
    actions.setSubmitting(false);
  }, 1000);
};

const OnBoardAddEmployee = () => {
  const navigate = useNavigate();

  return (
    <div className="employee__layout">
      <div className="container">
        <div className="section-header">
          <h4>Initiate Offer Letter</h4>
        </div>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
        >
          <Form>
            <div className="main-user-info">
              <div className="user-input-box">
                <label htmlFor="companyName">Company Name</label>
                <Field as="select" name="companyName">
                  <option value="The Thought Factory">
                    The Thought Factory
                  </option>
                  <option value="Raphsody">Raphsody</option>
                  <option value="scalex">scalex</option>
                </Field>
                <ErrorMessage component={TextError} name="companyName" />
              </div>

              <div className="user-input-box">
                <label htmlFor="empName">Employee Name</label>
                <Field
                  type="text"
                  id="empName"
                  name="empName"
                  placeholder="Enter Employee Name"
                />
                <ErrorMessage component={TextError} name="empName" />
              </div>

              <div className="user-input-box">
                <label htmlFor="empEmailId">Employee Email Id</label>
                <Field
                  type="email"
                  id="empEmailId"
                  name="empEmailId"
                  placeholder="Enter Employee Email Id"
                />
                <ErrorMessage component={TextError} name="empEmailId" />
              </div>

              <div className="user-input-box">
                <label htmlFor="empMobile">Mobile Number</label>
                <Field
                  type="text"
                  id="empMobile"
                  name="empMobile"
                  placeholder="Enter Employee Mobile Number"
                />
                <ErrorMessage component={TextError} name="empMobile" />
              </div>

              <div className="user-input-box">
                <label htmlFor="empPosition">Position</label>
                <Field
                  type="text"
                  id="empPosition"
                  name="empPosition"
                  placeholder="Enter Position"
                />
                <ErrorMessage component={TextError} name="empPosition" />
              </div>

              <div className="user-input-box">
                <label htmlFor="joiningDate">Joining Date</label>
                <Field
                  type="date"
                  id="joiningDate"
                  name="joiningDate"
                  placeholder="Enter Joining Date"
                />
                <ErrorMessage component={TextError} name="joiningDate" />
              </div>

              <div className="user-input-box">
                <label htmlFor="offerLetter">Attach Offer Letter</label>
                <Field
                  name="offerLetter"
                  placeholder="documentImage"
                  type="file"
                  style={{ padding: "2px" }}
                />
                <ErrorMessage name="offerLetter" />
              </div>
            </div>

            <div className="user-btn-section">
              <div className="board-submit-btn">
                <button type="submit" className="submit-btn">
                  Send
                </button>
                <button type="reset" className="cancel-btn">
                  Cancel
                </button>
              </div>
            </div>
          </Form>
        </Formik>
      </div>
    </div>
  );
};

export default OnBoardAddEmployee;
