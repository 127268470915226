import React from "react";
import employeeData from "../../assets/dummy-data/employeeData";

const maxLength = 4;

const NewlyBoardedCard = () => {
  const data = employeeData.slice(0, maxLength);
  return (
    <div>
      {data.map((item, index) => (
        <div className="display__section" key={index}>
          <div className="personal__section">
            <div className="employee__img">
              <img src={item.empPicture} alt="employee" />
            </div>
            <div className="emp__details">
              <h6>{item.empName}</h6>
              <p>{item.leaveDay}</p>
            </div>
          </div>
          <div
            className={`${
              item.status === "Active" ? "leave__status green" : ""
            } ${item.status === "InActive" ? "leave__status red" : ""} `}
          >
            <span>
              <i className={item.statusIcon}></i>
            </span>
            <p>{item.status}</p>
          </div>
        </div>
      ))}
    </div>
  );
};

export default NewlyBoardedCard;
