import "../styles/leaveMore.css";
import { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import purpleIcon from "../assets/images/user-group-purple.png";
import redIcon from "../assets/images/user-group-red.png";
import blueIcon from "../assets/images/user-group-blue.png";
import noDeclineBtn from "../assets/images/NoBW.png";
import DocumentPopup from "../components/Reusable/DocumentPopup";
import ConfirmationPopup from "../components/Reusable/ConfirmationPopup";

const EditLeaveDetails = ({
  setShowEditLeaveDetails,
  employeeData,
  setSelectedEmployeeData,
  selectedCompany,
}) => {
  const navigate = useNavigate();
  const accessToken = localStorage.getItem("accessToken");
  const permissions = JSON.parse(localStorage.getItem("userPermissions"));
  const [showPopup, setShowPopup] = useState(false);
  const [showDocumentPopup, setShowDocumentPopup] = useState(false);
  const [isApproved, setIsApproved] = useState(false);
  const [leaveCount, setLeaveCount] = useState([]);
  const [leaveHistory, setLeaveHistory] = useState([]);
  const [leavesTakenData, setLeavesTakenData] = useState([]);
  const [leaveTypes, setLeaveTypes] = useState([]);
  const [selectedDates, setSelectedDates] = useState({
    start: employeeData?.leave_from,
    end: employeeData?.leave_to,
  });

  const fetchLeaveCount = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/employees/${employeeData.user?.employee_information_id}/leaveCount`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      const data = response.data?.data;
      setLeaveCount(data);
      
    } catch (error) {
      console.log(error);
    }
  };

  const fetchLeaveHistory = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/employees/${employeeData.user?.employee_information_id}/leaves`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      const data = response.data?.data;
      setLeaveHistory(data);
      
    } catch (error) {
      console.log(error);
    }
  };

  const fetchLeaveTypes = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/employees/leaveTypes?counrty=India`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      const data = response.data?.data;
      setLeaveTypes(data);
      
    } catch (error) {
      console.log(error);
    }
  };

  const getLeavesTaken = async () => {
    try {
      let apiUrl = "";

      const hasViewAllManagedLeaveHistory = permissions.some(
        (permission) => permission.name === "view_managed_leave_history"
      );
      const hasViewAllLeaveHistory = permissions.some(
        (permission) => permission.name === "view_all_leave_history"
      );

      if (hasViewAllManagedLeaveHistory) {
        apiUrl = `${process.env.REACT_APP_BASE_URL}/employees/leaveHistoryForManagers`;
      } else if (hasViewAllLeaveHistory) {
        apiUrl = `${process.env.REACT_APP_BASE_URL}/employees/companyWiseLeaveHistory?company=${selectedCompany}`;
      } else {
        console.log("User does not have required permissions.");
        return;
      }

      const response = await axios.get(apiUrl, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
      const dataObject = response.data?.data;
      const dataArray = Object.keys(dataObject).map((key) => dataObject[key]);

      setLeavesTakenData(dataArray);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchLeaveCount();
    fetchLeaveHistory();
    getLeavesTaken();
    fetchLeaveTypes();
  }, []);

  const filteredLeaveHistory = leaveHistory.filter(
    (leave) =>
      (leave.is_manager_approved === 1 || leave.is_hr_approved === 1) &&
      leave.days_count
  );

  const leaveTypeCounts = {};

  filteredLeaveHistory.forEach((leave) => {
    const leaveType = leave.leaveType;
    if (leaveTypeCounts[leaveType]) {
      leaveTypeCounts[leaveType] += 1;
    } else {
      leaveTypeCounts[leaveType] = 1;
    }
  });

  const totalLeaveCount = leaveCount.reduce(
    (total, leave) => total + leave.count,
    0
  );

  let totalLeavesTaken = 0;

  let availableLeaves = totalLeaveCount - totalLeavesTaken;

  const handleApprove = () => {
    setShowPopup(true);
  };

  const handleDecline = () => {
    setShowPopup(true);
  };

  const handleYes = () => {
    setIsApproved(true);
    setShowPopup(false);
    setSelectedEmployeeData(null);
  };

  const handleNo = () => {
    setShowPopup(false);
    setSelectedEmployeeData(null);
  };

  const handleOpenDocumentPopup = () => {
    setShowDocumentPopup(true);
  };

  const handleDateChange = (event) => {
    const { name, value } = event.target;
    const newDates = {
      ...selectedDates,
      [name]: value,
    };

    if (name === "start" && new Date(value) > new Date(selectedDates.end)) {
      alert("Leave Start Date cannot be after Leave End Date.");
      return;
    } else if (
      name === "end" &&
      new Date(value) < new Date(selectedDates.start)
    ) {
      alert("Leave End Date cannot be before Leave Start Date.");
      return;
    }

    setSelectedDates(newDates);
  };

  const calculateDateDifference = (startDate, endDate) => {
    const startDateObj = new Date(startDate);
    const endDateObj = new Date(endDate);
    const timeDifference = endDateObj - startDateObj;
    const daysDifference = timeDifference / (1000 * 3600 * 24);
    return daysDifference;
  };

  return (
    <div className="emp_more-container">
      <div className="emp_more-header">
        <h3>Edit Leave</h3>
        <button
          type="button"
          className="close"
          onClick={() => setShowEditLeaveDetails(null)}
        >
          X
        </button>
      </div>

      <div className="emp_more-details">
        <div className="emp_more-info">
          <div className="title">
            <div className="emp_image">
              <img src={employeeData.user?.profile_picture} alt="Employee" />
            </div>
            <div className="info_emp-details">
              <h4>{employeeData.user?.employee_full_name}</h4>
              <p>{employeeData.user?.roles[0]?.role_name}</p>
            </div>
          </div>
          <div className="emp_edit-leaves">
            <div className="description total_leaves">
              <div className="icon purple">
                <img src={purpleIcon} alt="icon" />
              </div>
              <div>
                <p>Total Leaves</p>
                <h3>
                  {totalLeaveCount} <span>Yearly</span>
                </h3>
              </div>
              <ul>
                {leaveCount.map((leave, index) => (
                  <li key={index}>
                    {leave.LeaveType} {leave.count}
                  </li>
                ))}
              </ul>
            </div>

            <div className="description leaves_taken">
              <div className="icon red">
                <img src={redIcon} alt="icon" />
              </div>
              <div>
                <p>Leaves Taken</p>
                <h3>
                  {totalLeavesTaken} <span>Yearly</span>
                </h3>
              </div>
              <ul>
                {Object.keys(leaveTypeCounts).map((leaveType, index) =>
                  leaveTypeCounts[leaveType] > 0 ? (
                    <li key={index}>
                      {leaveType} {leaveTypeCounts[leaveType]}
                    </li>
                  ) : (
                    <li key={index}>{leaveType} - 0</li>
                  )
                )}
              </ul>
            </div>
            <div className="description available_leaves">
              <div className="icon blue">
                <img src={blueIcon} alt="icon" />
              </div>
              <div>
                <p>Available Leaves</p>
                <h3>
                  {availableLeaves} <span>Yearly</span>
                </h3>
              </div>
              <ul>
                {leaveCount.map((leave, index) => (
                  <li key={index}>
                    {leave.LeaveType} {leave.count}
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>

        <div className="info_section">
          <div className="employee_id-date">
            <p>Employee ID:</p>
            <h6>{employeeData?.user?.employee_id}</h6>
            <p>Applied Date:</p>
            <h6>{employeeData?.apply_date}</h6>
          </div>
          <div>
            {employeeData.user?.employee_information_id && (
              <Link
                to={`/employeeleavehistory?employeeInformationId=${employeeData.user?.employee_information_id}`}
              >
                <p className="leave_history" style={{ cursor: "pointer" }}>
                  View Leave History
                </p>
              </Link>
            )}
          </div>
        </div>
        <div className="leave_status">
          <div className="leave_desc">
            <p>Leave Start Date</p>
            <input
              type="date"
              name="start"
              value={selectedDates.start}
              onChange={handleDateChange}
            />
          </div>
          <div className="leave_desc">
            <p>Leave End Date</p>
            <input
              type="date"
              name="end"
              value={selectedDates.end}
              onChange={handleDateChange}
            />
          </div>

          <div className="leave_desc">
            <p>Leave Type</p>
            <select name="leaveType" id="leaveType">
              {employeeData?.leaveType && (
                <option value={employeeData.leaveType}>
                  {employeeData.leaveType}
                </option>
              )}
              {leaveTypes.map(
                (leave) =>
                  leave.LeaveType !== employeeData?.leaveType && (
                    <option key={leave.LeaveType} value={leave.LeaveType}>
                      {leave.LeaveType}
                    </option>
                  )
              )}
            </select>
          </div>
          <div className="leave_desc">
            <p>No. of Days</p>
            <input
              type="text"
              name="days_count"
              value={calculateDateDifference(
                selectedDates.start,
                selectedDates.end
              )}
              disabled
            />
          </div>
        </div>

        <div className="leave_reason">
          <label>Reason for leave</label>
          <p style={{ margin: "0px" }}>{employeeData?.purpose}</p>
        </div>
        <div className="view_document" onClick={handleOpenDocumentPopup}>
          View Document &gt;
        </div>
        <div className="leave_remarks">
          <label>Remarks/Notes</label>
          <textarea name="remarks" rows="1"></textarea>
        </div>
        <div className="edit_leave-btn_section">
          <button type="button" className="edit_leave-btn_cancel">
            Cancel
          </button>
          <button type="button" className="edit_leave-btn_submit">
            Save
          </button>
        </div>
      </div>

      {showPopup && (
        <ConfirmationPopup
          confirm={handleYes}
          cancel={handleNo}
          title="Change Leave Request"
          question="Are you sure to apply the changes?"
        />
      )}
      {showDocumentPopup && (
        <DocumentPopup
          documentUrl={employeeData.document_url}
          onClose={() => setShowDocumentPopup(false)}
        />
      )}
    </div>
  );
};

export default EditLeaveDetails;
