import "../../styles/employeeDetails.css";
import "../../styles/Calendar.css";

// import liabaries
import axios from "axios";
import { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { startOfMonth, endOfMonth } from "date-fns";

// import other internal components
import Calendar from "react-calendar";

// Icons from image gallery
import empIcon from "../../assets/images/emp-icon.png";
import dateIcon from "../../assets/images/joiningdate.png";
import calendarIcon from "../../assets/images/calendar.png";
import queryString from "query-string";
import { formatDate } from "./dateUtils";

const EmployeeLeaveHistoryTable = () => {
  const accessToken = localStorage.getItem("accessToken");
  const permissions = JSON.parse(localStorage.getItem("userPermissions"));
  const location = useLocation();
  const queryParams = queryString.parse(location.search);
  const employeeInformationId = queryParams.employeeInformationId;

  const navigate = useNavigate();
  const [employeeData, setEmployeeData] = useState([]);
  const [leaveHistoryDateData, setLeaveHistoryDateData] = useState([]);
  const [selectedStartDate, setSelectedStartDate] = useState();
  const [selectedEndDate, setSelectedEndDate] = useState();
  const [view, setView] = useState(false);
  const [minDate, setMinDate] = useState(null);
  const [sortOrder, setSortOrder] = useState({
    apply_date: "asc",
    leave_from: "asc",
    leave_to: "asc",
    days_count: "asc",
    leaveType: "asc",
    is_approved: "asc",
  });

  const startDateOnChange = (startDate) => {
    setSelectedStartDate(startDate);
    setMinDate(startDate);
  };

  const endDateOnChange = (endDate) => {
    setSelectedEndDate(endDate);
  };

  const formatDate = (date) => {
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const day = date.getDate().toString().padStart(2, "0");
    return `${month}/${day}/${year}`;
  };

  const getEmployeeData = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/employees/employeeInformation/${employeeInformationId}`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      const data = response.data?.data;
      setEmployeeData(data);
    } catch (error) {
      console.log(error);
    }
  };

  const getLeaveHistory = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/employees/${employeeInformationId}/leaves`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      const dataObject = response.data?.data;
      const dataArray = Object.keys(dataObject).map((key) => dataObject[key]);

      setLeaveHistoryDateData(dataArray);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getEmployeeData();
    getLeaveHistory();
  }, [employeeInformationId]);

  const getLeaveHistoryCalendar = async () => {
    try {
      const formattedStartDate = formatDate(selectedStartDate);
      const formattedEndDate = formatDate(selectedEndDate);

      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/employees/${employeeInformationId}/leaves?from=${formattedStartDate}&to=${formattedEndDate}`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      const dataObject = response.data?.data;
      const dataArray = Object.keys(dataObject).map((key) => dataObject[key]);

      setLeaveHistoryDateData(dataArray);
    } catch (error) {
      console.log(error);
    }
  };

  const clearAllDates = () => {
    setSelectedStartDate(null);
    setSelectedEndDate(null);
  };

  const searchButtonClick = () => {
    getLeaveHistoryCalendar();
    setView(false);
  };

  const handleSort = (columnName) => {
    const currentOrder = sortOrder[columnName];
    const newOrder = currentOrder === "asc" ? "desc" : "asc";

    const sortedData = [...leaveHistoryDateData].sort((a, b) => {
      if (columnName === "days_count" || columnName === "is_approved") {
        if (newOrder === "asc") {
          return a[columnName] - b[columnName];
        } else {
          return b[columnName] - a[columnName];
        }
      } else {
        if (newOrder === "asc") {
          return a[columnName].localeCompare(b[columnName]);
        } else {
          return b[columnName].localeCompare(a[columnName]);
        }
      }
    });

    setSortOrder({ ...sortOrder, [columnName]: newOrder });
    setLeaveHistoryDateData(sortedData);
  };


  return (
    <div className="board__layout">
      <div className="section-header">
        <div className="header__titles">
          <button onClick={() => navigate("/leavemanagement")}>
            <span>
              <i className="ri-arrow-left-s-line"></i>
            </span>
            BACK
          </button>
          <h4>Employee Leave History</h4>
        </div>
      </div>
      {employeeData.map((employee) => (
        <div className="section__content">
          <div className="employee__time-title">
            <div>
              <img
                src={employee?.users?.profile_picture}
                alt="employee"
                style={{ borderRadius: "100px" }}
              />
            </div>
            <div>
              <h1>{employee?.employee_full_name}</h1>
              <p>{employee?.users?.roles[0]?.role_name}</p>
            </div>
          </div>

          <div
            className="employee__time-details"
            style={{
              borderRight: "1px solid #0A9250",
              borderLeft: "1px solid #0A9250",
            }}
          >
            <div className="emp__detail">
              <div>
                <img src={empIcon} alt="icon" />
              </div>
              <div>
                <h6>Employee ID</h6>
                <p>{employee?.users?.employee_id}</p>
              </div>
            </div>
          </div>
          <div className="employee__time-details">
            <div className="emp__detail">
              <div>
                <img src={dateIcon} alt="icon" />
              </div>
              <div>
                <h6>Joining Date</h6>
                <p>{employee.joining_date}</p>
              </div>
            </div>
          </div>
        </div>
      ))}
      <div className="utility-bar">
        <div className="utility_bar-left">
          <div className="calendar">
            <div>
              {!selectedStartDate && !selectedEndDate && (
                <p style={{ margin: 0 }}>Select Dates</p>
              )}
            </div>
            <div>
              <img
                src={calendarIcon}
                alt="icon"
                onClick={() => setView(!view)}
              />
            </div>
            <div>
              {selectedStartDate && selectedEndDate && (
                <p style={{ margin: 0 }}>Selected Dates</p>
              )}
            </div>
          </div>
          {view && (
            <div className="lh_calendar-container">
              <div className="lh_calendar">
                <div>
                  <Calendar
                    onChange={startDateOnChange}
                    value={selectedStartDate}
                  />
                </div>
                <div>
                  <Calendar
                    onChange={endDateOnChange}
                    value={selectedEndDate}
                    minDate={selectedStartDate}
                  />
                </div>
              </div>
              <div className="lh_calendar-btn">
                <button className="clear-button" onClick={clearAllDates}>
                  Clear All
                </button>
                <button className="search-button" onClick={searchButtonClick}>
                  Search
                </button>
              </div>
            </div>
          )}

          {selectedStartDate && selectedEndDate && (
            <div className="selected_dates" style={{ fontSize: "14px" }}>
              {selectedStartDate.toLocaleDateString()} -{" "}
              {selectedEndDate.toLocaleDateString()}
            </div>
          )}
        </div>
      </div>

      <div className="attendance__table">
        <table className="table">
          <thead>
            <tr>
              <th>SL No.</th>

              <th onClick={() => handleSort("apply_date")}>
                Applied Date {sortOrder["apply_date"] === "asc" ? "↑" : "↓"}
              </th>
              <th onClick={() => handleSort("leave_from")}>
                Leave Start Date {sortOrder["leave_from"] === "asc" ? "↑" : "↓"}
              </th>
              <th onClick={() => handleSort("leave_to")}>
                Leave End Date {sortOrder["leave_to"] === "asc" ? "↑" : "↓"}
              </th>
              <th onClick={() => handleSort("days_count")}>
                No Of Days {sortOrder["days_count"] === "asc" ? "↑" : "↓"}
              </th>
              <th onClick={() => handleSort("leaveType")}>
                Leave Type {sortOrder["leaveType"] === "asc" ? "↑" : "↓"}
              </th>
              <th onClick={() => handleSort("is_approved")}>
                Status {sortOrder["is_approved"] === "asc" ? "↑" : "↓"}
              </th>
            </tr>
          </thead>
          <tbody>
            {leaveHistoryDateData.map((leave, index) => (
              <tr>
                <td>{index + 1}</td>

                <td>{leave.apply_date}</td>
                <td>{leave.leave_from}</td>
                <td>{leave.leave_to}</td>
                <td>{leave.days_count}</td>
                <td>{leave.leaveType}</td>
                <td>
                  {leave.is_approved === 1 && (
                    <span className="approved">Approved</span>
                  )}
                  {leave.is_approved === 0 && (
                    <span className="rejected">Declined</span>
                  )}
                  {leave.is_approved === null && (
                    <span className="pending">Pending</span>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default EmployeeLeaveHistoryTable;
