import Employee01 from "../images/employee01.png";
import Employee02 from "../images/employee02.png";
import Employee03 from "../images/employee03.png";
import Employee04 from "../images/employee04.png";
import Employee05 from "../images/employee05.png";

const absentEmpData = [
  {
    id: "SPL0001",
    empPicture: Employee02,
    empName: "Jilcy TJ",
    empCompany: "SCALEX IT SOLUTIONS",
    dateOfAbsence: "2023-05-05",
    leaveRequestStatus: "Rejected",
    statusIcon: "fa-solid fa-circle",
    empPhone: "9946936935",
    empEmail: "jilcy@scalex.com",
    reportingManager: "Mr. Arun Sivaraman",
    visa: [
      {
        visaNumber: "T45673",
        visaExpiryDate: "2023-06-01",
      },
    ],
  },
  {
    id: "SPL0002",
    empPicture: Employee01,
    empName: "Ajoy Ashok",
    empCompany: "SCALEX IT SOLUTIONS",
    leaveRequestStatus: "Approved",
    statusIcon: "fa-solid fa-circle",
    dateOfAbsence: "2023-05-06",
    empPhone: "9847653423",
    empEmail: "ajoyashok@scalex.com",
    reportingManager: "Mr. Arun Sivaraman",
    visa: [
      {
        visaNumber: "T45673",
        visaExpiryDate: "2023-06-01",
      },
    ],
  },
  {
    id: "SPL0003",
    empPicture: Employee03,
    empName: "Arun Sivaraman",
    empCompany: "SCALEX IT SOLUTIONS",
    statusIcon: "fa-solid fa-circle",
    dateOfAbsence: "2023-05-07",
    leaveRequestStatus: "Pending",
    empPhone: "8976345609",
    empEmail: "aruns@scalex.com",
    reportingManager: "Mr. Rasin",
    visa: [
      {
        visaNumber: "T45673",
        visaExpiryDate: "2023-08-01",
      },
    ],
  },
  {
    id: "SPL0004",
    empPicture: Employee04,
    empName: "Varun V",
    empCompany: "SCALEX IT SOLUTIONS",
    dateOfAbsence: "2023-05-05",
    statusIcon: "fa-solid fa-circle",
    leaveRequestStatus: "Rejected",
    empPhone: "9946936935",
    empEmail: "varunv@scalex.com",
    reportingManager: "Mr. Arun Sivaraman",
    visa: [
      {
        visaNumber: "T45673",
        visaExpiryDate: "2023-06-01",
      },
    ],
  },
  {
    id: "SPL0005",
    empPicture: Employee05,
    empName: "Anees Buhari",
    empCompany: "SCALEX IT SOLUTIONS",
    dateOfAbsence: "2023-05-10",
    statusIcon: "fa-solid fa-circle",
    leaveRequestStatus: "Rejected",
    empPhone: "7865204876",
    empEmail: "anees@scalex.com",
    reportingManager: "Mr. Arun Sivaraman",
    visa: [
      {
        visaNumber: "T45673",
        visaExpiryDate: "2023-06-01",
      },
    ],
  },
  {
    id: "SPL0006",
    empPicture: Employee01,
    empName: "Kunjumon TV",
    empCompany: "SCALEX IT SOLUTIONS",
    dateOfAbsence: "2023-04-05",
    statusIcon: "fa-solid fa-circle",
    leaveRequestStatus: "Approved",
    empPhone: "9946936935",
    empEmail: "kunjumon@scalex.com",
    reportingManager: "Mr. Arun Sivaraman",
    visa: [
      {
        visaNumber: "T45673",
        visaExpiryDate: "2023-06-01",
      },
    ],
  },
  {
    id: "SPL0007",
    empPicture: Employee02,
    empName: "Lakshmy Vineeth",
    empCompany: "SCALEX IT SOLUTIONS",
    dateOfAbsence: "2023-05-11",
    statusIcon: "fa-solid fa-circle",
    leaveRequestStatus: "Pending",
    empPhone: "9710293857",
    empEmail: "lakshmyv@scalex.com",
    reportingManager: "Mr. Benito",
    visa: [
      {
        visaNumber: "T45673",
        visaExpiryDate: "2023-06-10",
      },
    ],
  },
  
  {
    id: "TTF0001",
    empPicture: Employee01,
    empName: "Adib Awad",
    empCompany: "The Thought Factory",
    dateOfAbsence: "2023-05-06",
    empPhone: "+971 590345129",
    leaveRequestStatus: "Pending",
    statusIcon: "fa-solid fa-circle",
    empEmail: "adib@ttf.com",
    reportingManager: "Mr. Rasin",
    visa: [
      {
        visaNumber: "T45673",
        visaExpiryDate: "2023-06-10",
      },
    ],
  },
  {
    id: "TTF0002",
    empPicture: Employee03,
    empName: "Ali Darwish",
    empCompany: "The Thought Factory",
    leaveRequestStatus: "Rejected",
    statusIcon: "fa-solid fa-circle",
    dateOfAbsence: "2023-04-08",
    empPhone: "+971 590345653",
    empEmail: "alidarwish@ttf.com",
    reportingManager: "Mr. Rasin",
    visa: [
      {
        visaNumber: "T45673",
        visaExpiryDate: "2023-06-10",
      },
    ],
  },
  {
    id: "TTF0003",
    empPicture: Employee02,
    empName: "Aiswarya",
    empCompany: "The Thought Factory",
    leaveRequestStatus: "Approved",
    statusIcon: "fa-solid fa-circle",
    dateOfAbsence: "2023-04-05",
    empPhone: "+971 598789129",
    empEmail: "aiswarya@ttf.com",
    reportingManager: "Mr. Benitto",
    visa: [
      {
        visaNumber: "T45673",
        visaExpiryDate: "2023-08-10",
      },
    ],
  },
  {
    id: "TTF0004",
    empPicture: Employee04,
    empName: "Benitto",
    empCompany: "The Thought Factory",
    dateOfAbsence: "2023-04-09",
    leaveRequestStatus: "Rejected",
    statusIcon: "fa-solid fa-circle",
    empPhone: "+971 590344439",
    empEmail: "benito@ttf.com",
    reportingManager: "Mr. Ranjeev",
    visa: [
      {
        visaNumber: "T45673",
        visaExpiryDate: "2023-08-10",
      },
    ],
  },
  {
    id: "TTF0005",
    empPicture: Employee01,
    empName: "Rasin",
    empCompany: "The Thought Factory",
    dateOfAbsence: "2023-04-09",
    leaveRequestStatus: "Rejected",
    statusIcon: "fa-solid fa-circle",
    empPhone: "+971 590344439",
    empEmail: "rasin@ttf.com",
    reportingManager: "Mr. Ranjeev",
    visa: [
      {
        visaNumber: "T45673",
        visaExpiryDate: "2023-08-10",
      },
    ],
  },

  {
    id: "RMS0001",
    empPicture: Employee02,
    empName: "Lekha Sanal",
    empCompany: "Rhapsody",
    leaveRequestStatus: "Rejected",
    statusIcon: "fa-solid fa-circle",
    dateOfAbsence: "2023-05-10",
    empPhone: "+971 546720940",
    empEmail: "lekha@ttf.com",
    reportingManager: "Mr. Arun Nair",
    visa: [
      {
        visaNumber: "T45673",
        visaExpiryDate: "2023-07-10",
      },
    ],
  },
  {
    id: "RMS0002",
    empPicture: Employee01,
    empName: "Joby George",
    empCompany: "Rhapsody",
    leaveRequestStatus: "Pending",
    statusIcon: "fa-solid fa-circle",
    dateOfAbsence: "2023-05-06",
    empPhone: "+971 543309401",
    empEmail: "jobyg@ttf.com",
    reportingManager: "Mr. Arun Nair",
    visa: [
      {
        visaNumber: "T45673",
        visaExpiryDate: "2023-09-10",
      },
    ],
  },
  {
    id: "RMS0003",
    empPicture: Employee03,
    empName: "Anvar Sadath",
    empCompany: "Rhapsody",
    dateOfAbsence: "2023-05-07",
    leaveRequestStatus: "Approved",
    statusIcon: "fa-solid fa-circle",
    empPhone: "+971 546669423",
    empEmail: "anvar@ttf.com",
    reportingManager: "Mr. Arun Nair",
    visa: [
      {
        visaNumber: "T45673",
        visaExpiryDate: "2023-06-04",
      },
    ],
  },
  {
    id: "RMS0004",
    empPicture: Employee05,
    empName: "Rajesh Kumar",
    empCompany: "Rhapsody",
    leaveRequestStatus: "Pending",
    statusIcon: "fa-solid fa-circle",
    dateOfAbsence: "2023-05-08",
    empPhone: "+971 548976423",
    empEmail: "rajesh@ttf.com",
    reportingManager: "Mr. Arun Nair",
    visa: [
      {
        visaNumber: "T45673",
        visaExpiryDate: "2023-08-03",
      },
    ],
  },
  {
    id: "RMS0005",
    empPicture: Employee04,
    empName: "Arun Nair",
    empCompany: "Rhapsody",
    dateOfAbsence: "2023-05-08",
    leaveRequestStatus: "Pending",
    statusIcon: "fa-solid fa-circle",
    empPhone: "+971 548976423",
    empEmail: "arun_nair@ttf.com",
    reportingManager: "Mr. Ranjeev",
    visa: [
      {
        visaNumber: "T45673",
        visaExpiryDate: "2023-07-08",
      },
    ],
  },
  {
    id: "RMS0006",
    empPicture: Employee02,
    empName: "Nisha Sajeev",
    empCompany: "Rhapsody",
    dateOfAbsence: "2023-05-09",
    leaveRequestStatus: "Approved",
    statusIcon: "fa-solid fa-circle",
    empPhone: "+971 548976423",
    empEmail: "nisha@ttf.com",
    reportingManager: "Mr. Arun Nair",
    visa: [
      {
        visaNumber: "T45673",
        visaExpiryDate: "2023-06-01",
      },
    ],
  },
];

export default absentEmpData;
