import "../../styles/exitManagement.css";
import { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import formatDate from "./dateUtils";
import work from "../../assets/images/regularwork.png";
import leave from "../../assets/images/leave.png";
import ResigDocsPopup from "./ResigDocsPopup";

const ExitManagementView = () => {
  const location = useLocation();
  const selectedExitData = location.state?.employeeExitData;
  const navigate = useNavigate();
  const [showResigDocPopup, setShowResigDocPopup] = useState(false);

  const handleOpenDocumentPopup = () => {
    setShowResigDocPopup(true);
  };

  return (
    <div className="board__layout">
      <div className="section-header">
        <div className="header__titles">
          <button onClick={() => navigate("/exitmanagement")}>
            <span>
              <i className="ri-arrow-left-s-line"></i>
            </span>
            BACK
          </button>
          <h4>Exit Management Details</h4>
        </div>
      </div>
      <div className="exit-mgmt_view-container">
        <div className="exit-mgmt_view-firstRow">
          <div className="firstRow_firstSection">
            <div>
              <img src={`${selectedExitData?.users?.profile_picture}?timestamp=${Date.now()}`} alt="" />
            </div>
            <div>
              <h3>{selectedExitData?.users?.employee_full_name}</h3>
              <h5>{selectedExitData?.users?.roles[0]?.role_name}</h5>
            </div>
          </div>
          <div>
            <h6>Employee ID</h6>
            <p>{selectedExitData?.users?.employee_id}</p>
          </div>
          <div className="company_section">
            <h6>Company Name</h6>
            <p>{selectedExitData?.users?.roles[0]?.company[0]?.company_name}</p>
          </div>
          <div>
            <h6>Department</h6>
            <p>{selectedExitData?.users?.roles[0]?.department_name}</p>
          </div>
          <div>
            <h6>Reporting Manager</h6>
            <p>
              {`${selectedExitData?.reportingManager?.first_name} ${selectedExitData?.reportingManager?.last_name}`}{" "}
            </p>
          </div>
        </div>
        <div className="exit-mgmt_view-secondRow">
          <div className="emv_flex">
            <img src={work} alt="icon" />
            <div>
              <h6>Joining Date</h6>
              <h4>{formatDate(selectedExitData?.joining_date)}</h4>
            </div>
          </div>
          <div className="emv_flex">
            <img src={leave} alt="icon" />
            <div>
              <h6>Resignation Applied Date</h6>
              <h4>{formatDate(selectedExitData?.resignation_apply_date)}</h4>
            </div>
          </div>
          <div className="emv_flex">
            <img src={leave} alt="icon" />
            <div>
              <h6>Resignation Date</h6>
              <h4>{formatDate(selectedExitData?.resignation_date)}</h4>
            </div>
          </div>
        </div>
        <div className="exit-mgmt_view-secondRow">
          <div className="emv_flex">
            <img src={leave} alt="icon" />
            <div>
              <h6>Last Working Date</h6>
              <h4>{formatDate(selectedExitData?.last_working_date)}</h4>
            </div>
          </div>
          <div className="emv_flex">
            <img src={leave} alt="icon" />
            <div>
              <h6>Country Exit Date</h6>
              <h4>{formatDate(selectedExitData?.country_exit_date)}</h4>
            </div>
          </div>
          <div className="emv_flex">
            <img src={leave} alt="icon" />
            <div>
              <h6>Visa Cancellation Date</h6>
              <h4>{formatDate(selectedExitData?.visa_cancel_date)}</h4>
            </div>
          </div>
        </div>
        <div className="exmv_view-download">
          <p className="exmv_view" onClick={handleOpenDocumentPopup}>
            View / Download
          </p>
        </div>

        <div className="exit-mgmt_view-thirdRow">
          <label htmlFor="remark">Remarks</label>
          <p>{selectedExitData?.resign_remarks}</p>
        </div>
        <div className="exit_form-btn_section" style={{paddingRight:'30px'}}>
        <button
          type="button"
          className="exit_form-btn_cancel"
            onClick={() => navigate("/exitmanagement")}
            
        >
          Close
        </button>
      </div>
      </div>
      
      {showResigDocPopup && (
        <ResigDocsPopup
          documentUrl={selectedExitData?.resig_doc}
          onClose={() => setShowResigDocPopup(false)}
        />
      )}
      
    </div>
  );
};

export default ExitManagementView;
