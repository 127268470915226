import "../styles/exitManagement.css";
import { useState } from "react";
import axios from "axios";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import TextError from "../components/MultiStep/TextError";
import { useNavigate } from "react-router-dom";
import Modal from "react-modal";
Modal.setAppElement("#root");

const ExitManagementForm = () => {
  const accessToken = localStorage.getItem("accessToken");
  const permissions = JSON.parse(localStorage.getItem("userPermissions"));
  const [showExitConfirmation, setShowExitConfirmation] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [exitData, setExitData] = useState([]);
  const navigate = useNavigate();
  const [initialValues, setInitialValues] = useState({
    employee_info_id: "",
    employee_company_id: "",
    companyName: "",
    full_name: "",
    reportingManager: "",
    date_of_joining: "",
    resg_apply_date: "",
    date_of_resignation: "",
    visa_cancel_date: "",
    date_of_exit: "",
    last_working_date: "",
    resignation_document: "",
    message: "",
  });

  const validationSchema = Yup.object({
    employee_company_id: Yup.string().required("Employee ID is required"),
    full_name: Yup.string().required("Full Name is required"),
    reportingManager: Yup.string().required("Reporting Manager is required"),
    date_of_joining: Yup.date().required("Joining Date is required"),
    resg_apply_date: Yup.date().required("Required"),
    date_of_resignation: Yup.date().required("Required"),
    visa_cancel_date: Yup.date().required("Required"),
    date_of_exit: Yup.date().required("Required"),
    last_working_date: Yup.date().required("Required"),
    resignation_document: Yup.mixed(),
    message: Yup.string().required("Required"),
  });

  const getEmployeeData = async (companyId, formik) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/employees/informationBycompanyId?companyId=${companyId}`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            "Content-Type": "multipart/form-data",
          },
        }
      );

      const employeeData = response.data?.data;
      setExitData(employeeData);

      formik.setValues({
        ...formik.values,
        employee_info_id: employeeData[0].users?.employee_information_id,
        employee_company_id: employeeData[0].users?.employee_id,
        companyName: employeeData[0]?.users?.roles[0]?.company[0]?.company_name,
        full_name: employeeData[0]?.users?.employee_full_name,
        reportingManager:
          employeeData[0]?.reportingManager?.first_name +
          " " +
          employeeData[0]?.reportingManager?.last_name,
        date_of_joining: employeeData[0].joining_date,
        resg_apply_date: employeeData[0].resignation_apply_date || "",
        date_of_resignation: employeeData[0].resignation_date || "",
        visa_cancel_date: employeeData[0].visa_cancel_date || "",
        date_of_exit: employeeData[0].country_exit_date || "",
        last_working_date: employeeData[0].last_working_date || "",
        resignation_document: employeeData[0].resig_doc || "",
        message: employeeData[0].resign_remarks || "",
      });
    } catch (error) {
      console.log(error);
    }
  };

  const handleSaveButton = () => {
    setShowExitConfirmation(true);
  };

  const handleCloseModal = () => {
    setShowSuccessModal(false);
    navigate(`/exitmanagement`);
  };

  const handleChangeFileInput = (event, formik) => {
    const selectedFile = event.target.files[0];
    formik.setFieldValue("resignation_document", selectedFile);
  };

  const confirmExit = async (values, { setSubmitting }) => {
    const empId = values.employee_info_id;
    try {
      const formdata = new FormData();
      formdata.append("resg_apply_date", values.resg_apply_date);
      formdata.append("resignation_date", values.date_of_resignation);
      formdata.append("last_working_date", values.last_working_date);
      formdata.append("document", values.resignation_document);
      formdata.append("employee_id", values.employee_info_id);
      formdata.append("visa_cancel_date", values.visa_cancel_date);
      formdata.append("country_exit_date", values.date_of_exit);
      formdata.append("resign_remarks", values.message);

      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/employees/resignation`,
        formdata,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            "Content-Type": "multipart/form-data",
          },
        }
      );

      await axios.patch(
        `${process.env.REACT_APP_BASE_URL}/employees/employeeInformation/${empId}`,
        { is_active: 0 },
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      );

      setShowExitConfirmation(false);

      if (response.status === 200) {
        alert("Data submitted successfully");
      } else {
        setShowErrorModal(true);
      }
    } catch (error) {
      setShowErrorModal(true);
    } finally {
      setSubmitting(false);
    }
  };

  const cancelExit = () => {
    setShowExitConfirmation(null);
  };

  return (
    <div className="board__layout">
      <div className="section-header">
        <div className="header__titles">
          <button onClick={() => navigate("/exitmanagement")}>
            <span>
              <i className="ri-arrow-left-s-line"></i>
            </span>
            BACK
          </button>
          <h4>Exit Management Form</h4>
        </div>
      </div>
      <Formik
        initialValues={initialValues}
        onSubmit={confirmExit}
        // validationSchema={validationSchema}
      >
        {(formik) => (
          <Form style={{ padding: "20px 30px" }}>
            <div className="form-rows">
              <div>
                <label htmlFor="employee_id">Employee Id</label>
                <Field
                  type="text"
                  name="employee_company_id"
                  style={{ fontWeight: "bold" }}
                  id="employee_company_id"
                  placeholder="Enter Employee ID"
                  onChange={(e) => {
                    const inputValue = e.target.value.toUpperCase();
                    e.target.value = inputValue;
                    formik.handleChange(e);
                    clearTimeout(formik.values.timeoutId);
                    const timeoutId = setTimeout(() => {
                      getEmployeeData(inputValue, formik);
                    }, 500);
                    formik.setFieldValue("timeoutId", timeoutId);
                  }}
                />
                <ErrorMessage
                  component={TextError}
                  name="employee_company_id"
                />
              </div>
              <div>
                <label htmlFor="companyName">Company Name</label>
                <Field
                  type="text"
                  name="companyName"
                  id="companyName"
                  disabled
                  placeholder="Enter Company Name"
                  value={formik.values.companyName}
                />
                <ErrorMessage component={TextError} name="companyName" />
              </div>
            </div>
            <div className="form-rows">
              <div>
                <label htmlFor="full_name">Full Name</label>
                <Field
                  type="text"
                  name="full_name"
                  id="full_name"
                  placeholder="Enter your Employee Full Name"
                  value={formik.values.full_name}
                />
                <ErrorMessage name="full_name" component={TextError} />
              </div>
              <div>
                <label htmlFor="reportingManager">Reporting Manager</label>
                <Field
                  type="text"
                  name="reportingManager"
                  id="reportingManager"
                  placeholder="Enter Reporting Person Name"
                  value={formik.values.reportingManager}
                />
                <ErrorMessage name="reportingManager" component={TextError} />
              </div>
            </div>

            <div className="form-rows">
              <div>
                <label htmlFor="date_of_joining">Joining Date</label>
                <Field
                  type="date"
                  name="date_of_joining"
                  id="date_of_joining"
                  value={formik.values.date_of_joining}
                />
                <ErrorMessage name="date_of_joining" component={TextError} />
              </div>
              <div>
                <label htmlFor="resg_apply_date">
                  Resignation Applied Date
                </label>
                <Field
                  type="date"
                  name="resg_apply_date"
                  id="resg_apply_date"
                  value={formik.values.resg_apply_date}
                />
                <ErrorMessage name="resg_apply_date" component={TextError} />
              </div>
            </div>

            <div className="form-rows">
              <div>
                <label htmlFor="date_of_resignation">Resignation Date</label>
                <Field
                  type="date"
                  name="date_of_resignation"
                  id="date_of_resignation"
                />
                <ErrorMessage
                  name="date_of_resignation"
                  component={TextError}
                />
              </div>
              <div>
                <label htmlFor="visa_cancel_date">Visa Cancellation Date</label>
                <Field
                  type="date"
                  name="visa_cancel_date"
                  id="visa_cancel_date"
                />
                <ErrorMessage name="visa_cancel_date" component={TextError} />
              </div>
            </div>
            <div className="form-rows">
              <div>
                <label htmlFor="date_of_exit">Country Exit Date</label>
                <Field type="date" name="date_of_exit" id="date_of_exit" />
                <ErrorMessage name="date_of_exit" component={TextError} />
              </div>
              <div>
                <label htmlFor="last_working_date">Last Working Date</label>
                <Field
                  type="date"
                  name="last_working_date"
                  id="last_working_date"
                />
                <ErrorMessage name="last_working_date" component={TextError} />
              </div>
            </div>
            <div className="form-rows">
              <div>
                <label htmlFor="">Attach Resignation Document </label>

                <input
                  name="resignation_document"
                  className="custom-file-upload"
                  type="file"
                  id="resignation_document"
                  onChange={(e) => handleChangeFileInput(e, formik)}
                />

                <ErrorMessage
                  component={TextError}
                  name="resignation_document"
                />
              </div>
              <div style={{ opacity: "0" }}>
                <label htmlFor="employee_info_id">
                  Employee Information ID
                </label>
                <Field
                  type="number"
                  name="employee_info_id"
                  id="employee_info_id"
                  placeholder="Employee Information ID"
                />
                <ErrorMessage component={TextError} name="employee_info_id" />
              </div>
            </div>
            <div className="form-rows">
              <div>
                <label htmlFor="message">Message</label>
                <Field
                  as="textarea"
                  name="message"
                  id="message"
                  style={{ rows: "6" }}
                  placeholder="Enter your message here..."
                />
                <ErrorMessage name="message" component={TextError} />
              </div>
            </div>
            <div className="exit_form-btn_section">
              <button
                type="button"
                className="exit_form-btn_submit"
                onClick={handleSaveButton}
              >
                {formik.values.resg_apply_date ? "Update" : "Save"}
              </button>
              <button
                type="button"
                className="exit_form-btn_cancel"
                onClick={() => navigate("/exitmanagement")}
              >
                Cancel
              </button>
            </div>
            {showExitConfirmation && (
              <div className="em_delete-popup_overlay">
                <div className="em_delete-popup">
                  <p>{`Are you sure that you want to ${
                    formik.values.resg_apply_date ? "Update" : "Save"
                  } the data?`}</p>
                  <div className="em_delete-popup_buttons">
                    <button
                      className="em_delete-confirm_btn"
                      type="submit"
                      onClick={confirmExit}
                    >
                      Yes
                    </button>
                    <button
                      className="em_delete-cancel_btn"
                      type="button"
                      onClick={cancelExit}
                    >
                      No
                    </button>
                  </div>
                </div>
              </div>
            )}
          </Form>
        )}
      </Formik>
      <Modal
        isOpen={showSuccessModal}
        onRequestClose={() => setShowSuccessModal(false)}
        contentLabel="Data Updated Successfully"
        className="react-modal-content"
        overlayClassName="react-modal-overlay"
      >
        <h5>Details Updated Successfully</h5>
        <button onClick={handleCloseModal}>OK</button>
      </Modal>
      <Modal
        isOpen={showErrorModal}
        onRequestClose={() => setShowErrorModal(false)}
        contentLabel="Error"
        className="react-modal-content"
        overlayClassName="react-modal-overlay"
      >
        <h5>Fialed to upload data</h5>
        <button onClick={handleCloseModal}>OK</button>
      </Modal>
    </div>
  );
};

export default ExitManagementForm;
