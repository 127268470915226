import { useEffect, useState, useMemo } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { Formik, Field, Form, ErrorMessage, FieldArray } from "formik";
import * as Yup from "yup";
import countryList from "react-select-country-list";
import Modal from "react-modal";
Modal.setAppElement("#root");

const AddressUpdate = () => {
  const accessToken = localStorage.getItem("accessToken");
  const countries = useMemo(() => countryList().getData(), []);
  const { id } = useParams();
  const [employeeData, setEmployeeData] = useState([]);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [isCurrentAddress, setIsCurrentAddress] = useState([]);
  const [addressButtonDisabled, setAddressButtonDisabled] = useState(false);
  const navigate = useNavigate();

  const fetchEmployeeData = async (id) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/employees/employeeInformation/${id}`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      const data = response.data?.data;
      setEmployeeData(data);
      setIsCurrentAddress(new Array(data.length).fill(false));
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchEmployeeData(id);
  }, [id]);

  const handleCheckboxChange = (index, values, setFieldValue) => {
    const updatedAddresses = values.address.map((address, i) => {
      if (i === index) {
        return {
          ...address,
          is_permanent: !values.address[index].is_permanent ? 1 : 0,
        };
      } else {
        return address;
      }
    });
    setFieldValue("address", updatedAddresses);
    setIsCurrentAddress((prevState) => {
      const updatedCurrentAddress = [...prevState];
      updatedCurrentAddress[index] = !prevState[index];
      return updatedCurrentAddress;
    });
  };

  const validationSchema = Yup.object().shape({
    address: Yup.array().of(
      Yup.object().shape({
        street_address: Yup.string().required("Street address is required"),
        state: Yup.string().required("State is required"),
        country: Yup.string().required("Country is required"),
        is_permanent: Yup.boolean().required("Required"),
      })
    ),
  });

  const updateAddressData = async (values) => {
    try {
      const requestBody = {
        id: employeeData[0].id,
        addresses: values.address.map((addr) => ({
          street_address: addr.street_address,
          state: addr.state,
          country: addr.country,
          is_permanent: addr.is_permanent,
          is_approved: 0,
          latitude: addr.street_address,
          longitude: addr.street_address,
        })),
      };

      await axios.put(
        `${process.env.REACT_APP_BASE_URL}/employees/addresses`,
        requestBody,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      setShowSuccessModal(true);
    } catch (error) {
      console.log("Error updating data:", error);
    }
  };

  const handleCloseModal = () => {
    setShowSuccessModal(false);
    navigate(`/employeedirectory/${id}`);
  };

 
 

  return (
    <div className="board__layout">
      <div className="section-header">
        <h1>Update Address</h1>
      </div>
      {employeeData.map((employee) => (
        <Formik
          key={employee.id}
          initialValues={{
            address: employee.address.map((addressData) => ({
              street_address: addressData.street_address,
              state: addressData.state,
              country: addressData.country,
              is_permanent: addressData.is_permanent,
              is_approved: addressData.is_approved,
              latitude: addressData.latitude,
              longitude: addressData.longitude,
            })),
          }}
          validationSchema={validationSchema}
          onSubmit={(values) => {
            updateAddressData(values);
          }}
        >
          {({ values, setFieldValue }) => (
            <Form style={{ padding: "30px" }}>
              <FieldArray name="address">
                {({ insert, remove, push }) => (
                  <div>
                    {values.address.length > 0 &&
                      values.address.map((address, index) => (
                        <div key={index} style={{ marginBottom: "3rem" }}>
                          <div className="form-rows">
                            
                            <div>
                              <label htmlFor={`address.${index}.street_address`}>
                               Address
                              </label>
                              <Field
                                name={`address.${index}.street_address`}
                                placeholder="State"
                                type="text"
                              />
                              <ErrorMessage
                                name={`address.${index}.street_address`}
                                component="div"
                                className="error-message"
                              />
                            </div>
                            <div>
                              <label htmlFor={`address.${index}.state`}>
                                State
                              </label>
                              <Field
                                name={`address.${index}.state`}
                                placeholder="State"
                                type="text"
                              />
                              <ErrorMessage
                                name={`address.${index}.state`}
                                component="div"
                                className="error-message"
                              />
                            </div>
                          </div>

                          <div className="form-rows">
                          
                            <div>
                              <label htmlFor={`address.${index}.country`}>
                                Country
                              </label>
                              <Field
                                name={`address.${index}.country`}
                                as="select"
                              >
                                <option value="">Select Country</option>
                                {countries.map((country, index) => (
                                  <option value={country.id} key={index}>
                                    {" "}
                                    {country.label}{" "}
                                  </option>
                                ))}
                              </Field>

                              <ErrorMessage
                                name={`address.${index}.country`}
                                component="div"
                                className="error-message"
                              />
                            </div>
                           <div></div>
                          </div>

                          <div className="form-rows">
                            <div>
                              <div style={{ marginTop: "2rem" }}>
                                <div
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    columnGap: "10px",
                                    flexWrap: "wrap",
                                  }}
                                >
                                  <Field
                                    type="checkbox"
                                    name={`address.${index}.is_permanent`}
                                    value="is_permanent"
                                    checked={values.address[index].is_permanent}
                                    onChange={() =>
                                      handleCheckboxChange(
                                        index,
                                        values,
                                        setFieldValue
                                      )
                                    }
                                  />
                                  <label style={{ marginTop: "8px", marginRight:'10rem' }}>
                                    Permanent Address
                                  </label>

                                  <Field
                                    type="checkbox"
                                    name={`address.${index}.is_permanent`}
                                    value="is_permanent"
                                    checked={
                                      !values.address[index].is_permanent
                                    }
                                    onChange={() =>
                                      handleCheckboxChange(
                                        index,
                                        values,
                                        setFieldValue
                                      )
                                    }
                                  />
                                  <label style={{ marginTop: "8px" }}>
                                    Current Address
                                  </label>
                                </div>
                              </div>
                            </div>
                            <div className="col">
                              {index > 0 && (
                                <button
                                  type="button"
                                  className="formik-delete-btn"
                                  onClick={() => {
                                    remove(index);
                                    setAddressButtonDisabled(false);
                                  }}
                                >
                                  Delete
                                </button>
                              )}
                            </div>
                          </div>
                        </div>
                      ))}

                    <button
                      type="button"
                      className={
                        addressButtonDisabled
                          ? "disable-addMore-btn"
                          : "formik-addMore-btn"
                      }
                      onClick={() => {
                        if (values.address.length < 2) {
                          const DEFAULT_ADDRESS = {
                            street_address: "",
                            state: "",
                            country: "",
                            is_permanent: 1,
                            is_approved: 0,
                            latitude: "",
                            longitude: "",
                          };
                          push(DEFAULT_ADDRESS);
                          setAddressButtonDisabled(true);
                        } else {
                          alert("A maximum of two addresses is allowable.");
                        }
                      }}
                    >
                      Add More Address +
                    </button>
                  </div>
                )}
              </FieldArray>
              <div className="form-btn-div">
                <button
                  type="button"
                  className="btn-skip"
                  onClick={() => navigate(`/employeedirectory/${id}`)}
                >
                  Close
                </button>
                <button type="submit" className="form-btn-submit">
                  Update
                </button>
              </div>
            </Form>
          )}
        </Formik>
      ))}

      <Modal
        isOpen={showSuccessModal}
        onRequestClose={() => setShowSuccessModal(false)}
        contentLabel="Data Updated Successfully"
        className="react-modal-content"
        overlayClassName="react-modal-overlay"
      >
        <h5>Details Updated Successfully</h5>
        <button onClick={handleCloseModal}>OK</button>
      </Modal>
    </div>
  );
};

export default AddressUpdate;
