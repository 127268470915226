import Page403 from "./Page403";

const Support = () => {
  return (
    <div>
      <Page403 />
    </div>
  );
};

export default Support;
