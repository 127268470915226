import userBlue from "../../assets/images/user-group-blue.png";
import userGreen from "../../assets/images/user-group-green.png";
import userPurple from "../../assets/images/user-group-purple.png";
import userRed from "../../assets/images/user-group-red.png";
import userYellow from "../../assets/images/user-group-yellow.png";
import instanceIcons from "../../assets/images/instancesIcon.png";
import shIcons from "../../assets/images/shortIcon.png";

// Data from EmployeeAttendanceDetails page
export const totalAttendances = {
  iconImg: userPurple,
  iconBgColor: "color__purple-light",
  monthColor: "color__purple__dark",
  monthBgColor: "color__bg-purple__light",
  title: "Total Working Days",
  count: 22,
  days: "Last 30 Days",
  month: "Working Days",
};

export const totalPresents = {
  iconImg: userRed,
  iconColor: "color__red-dark",
  iconBgColor: "color__red-light",
  monthColor: "color__red__dark",
  monthBgColor: "color__bg-red__light",
  title: "Total Present",
  count: 18,
  days: "Last 30 Days",
  month: "+11 this month",
};

export const dailyLeave = {
  iconImg: userYellow,
  iconColor: "color__yellow-dark",
  iconBgColor: "color__yellow-light",
  monthColor: "color__yellow__dark",
  monthBgColor: "color__bg-yellow__light",
  title: "Leave",
  count: 4,
  days: "Last 30 Days",
  month: "+11 this month",
};

export const shortHours = {
  iconImg: shIcons,
  iconColor: "color__red-dark",
  iconBgColor: "color__red-light",
  title: "Short Hours",
  count: "2:40",
  days: "This Month",
};

export const noOfInstances = {
  iconImg: instanceIcons,
  iconColor: "color__blue-dark",
  iconBgColor: "color__blue-light",
  title: "No of Instances",
  count: "2",
  days: "This Month",
};

// Data from TimeAttendance Page

export const totalEmployees = {
  iconImg: userPurple,
  iconBgColor: "color__purple-light",
  monthColor: "color__purple__dark",
  monthBgColor: "color__bg-purple__light",
  title: "Total Employees",
  count: 14,
  days: "Today",
  month: "",
};

export const totalPresent = {
  iconImg: userRed,
  iconColor: "color__red-dark",
  iconBgColor: "color__red-light",
  monthColor: "color__red__dark",
  monthBgColor: "color__bg-red__light",
  title: "Total Present",
  count: 14,
  days: "Today",
  month: "",
};

export const lateLogins = {
  iconImg: userBlue,
  iconColor: "color__blue-dark",
  iconBgColor: "color__blue-light",
  monthColor: "color__blue__dark",
  monthBgColor: "color__bg-blue__light",
  title: "Late Logins",
  count: 0,
  days: "Today",
  month: "",
};

export const monthlyLeave = {
  iconImg: userYellow,
  iconColor: "color__yellow-dark",
  iconBgColor: "color__yellow-light",
  monthColor: "color__yellow__dark",
  monthBgColor: "color__bg-yellow__light",
  title: "Leave",
  count: 0,
  days: "Today",
  month: "",
};

// Data from Dashboard Cards

export const totalEmployee = {
  iconImg: userPurple,
  iconBgColor: "color__purple-light",
  monthColor: "color__purple__dark",
  monthBgColor: "color__bg-purple__light",
  title: "Total Employees",
  count: "",
  days: "Today",
  month: "",
  dots: "ri-more-fill",
  linkTo: "",
};
export const absentWoutLeave = {
  iconImg: userRed,
  iconBgColor: "color__red-light",
  monthColor: "color__red__dark",
  monthBgColor: "color__bg-red__light",
  title: "Absent without Leave",
  count: 0,
  days: "Last 30 Days",
  month: "",
  dots: "ri-more-fill",
  linkTo: "/timeattendance",
};

export const employementVisa = {
  iconImg: userPurple,
  iconColor: "color__purple-dark",
  iconBgColor: "visa-color__purple-light",
  title: "Employement Visa",
  countMin: "",
  countMax:"",
  daysShort: "0-30 Days",
  daysLong: "31-120 Days",
 
};
export const employeePassport = {
  iconImg: userRed,
  iconColor: "color__red-dark",
  iconBgColor: "visa-color__red-light",
  title: "Employee Passport",
  countMin: "",
  countMax:"",
  daysShort: "0-30 Days",
  daysLong: "31-180 Days",
};

export const emiratesId = {
  iconImg: userBlue,
  iconColor: "color__blue-dark",
  iconBgColor: "visa-color__blue-light",
  title: "Emirates ID",
  countMin: "",
  countMax:"",
  daysShort: "0-30 Days",
  daysLong: "",
};

export const medicalInsurance = {
  iconImg: userYellow,
  iconColor: "color__yellow-dark",
  iconBgColor: "visa-color__yellow-light",
  title: "Medical Insurance Card",
  countMin: "",
  countMax:"",
  daysShort: "0-30 Days",
  daysLong: "",
};

export const dmccAccessCard = {
  iconImg: userGreen,
  iconColor: "color__green-dark",
  iconBgColor: "visa-color__green-light",
  title: "DMCC Access Card",
  countMin: "",
  countMax:"",
  daysShort: "0-07 Days",
  daysLong: "",
};

export const onBoarding = {
  iconImg: userYellow,
  iconColor: "color__yellow-dark",
  iconBgColor: "color__yellow-light",
  monthColor: "color__yellow__dark",
  monthBgColor: "color__bg-yellow__light",
  title: "On Boarding",
  count: "3",
  days: "Last 30 Days",
  month: "",
  dots: "ri-more-fill",
  linkTo: "/onboarding",
};

export const lateLogging = {
  iconImg: userPurple,
  iconBgColor: "color__purple-light",
  monthColor: "color__purple__dark",
  monthBgColor: "color__bg-purple__light",
  title: "Late Logging",
  count: "6",
  days: "Last 30 Days",
  month: "",
  dots: "ri-more-fill",
  linkTo: "/latelogins",
};

export const attendance = {
  iconImg: userRed,
  iconColor: "color__red-dark",
  iconBgColor: "color__red-light",
  monthColor: "color__red__dark",
  monthBgColor: "color__bg-red__light",
  title: "Attendance",
  count: "46",
  days: "Today",
  month: "",
  dots: "ri-more-fill",
  linkTo: "/timeattendance",
};

export const noOfLeavesApproved = {
  iconImg: userBlue,
  iconColor: "color__blue-dark",
  iconBgColor: "color__blue-light",
  monthColor: "color__blue__dark",
  monthBgColor: "color__bg-blue__light",
  title: "No. of Leaves Approved",
  count: "3",
  days: "Last 30 Days",
  month: "+11 this month",
  dots: "ri-more-fill",
  linkTo: "/leavemanagement",
};

export const newEmployee = {
  iconImg: userYellow,
  iconColor: "color__yellow-dark",
  iconBgColor: "color__yellow-light",
  monthColor: "color__yellow__dark",
  monthBgColor: "color__bg-yellow__light",
  title: "New Employee",
  count: "2",
  days: "Last 30 Days",
  month: "",
  dots: "ri-more-fill",
};
export const employeesLeft = {
  iconImg: userGreen,
  iconColor: "color__green-dark",
  iconBgColor: "color__green-light",
  monthColor: "color__green__dark",
  monthBgColor: "color__bg-green__light",
  title: "Employees Left",
  count: "3",
  days: "Last 30 Days",
  month: "+ this month",
  dots: "ri-more-fill",
};

// Data from LeaveManagement page

export const totalLeaveRequest = {
  iconImg: userPurple,
  iconBgColor: "color__purple-light",
  monthColor: "color__purple__dark",
  monthBgColor: "color__bg-purple__light",
  title: "Total Leave Request",
  count: "",
  days: "Today",
  month: "+11 this month",
  dots: "ri-more-fill",
  linkTo: "",
};

export const totalLeaveApproved = {
  iconImg: userRed,
  iconColor: "color__red-dark",
  iconBgColor: "color__red-light",
  monthColor: "color__red__dark",
  monthBgColor: "color__bg-red__light",
  title: "Total Leave Approved",
  count: "",
  days: "30 Days",
  month: "+11 this month",
  dots: "ri-more-fill",
};

export const totalLeaveRequestPending = {
  iconImg: userBlue,
  iconColor: "color__blue-dark",
  iconBgColor: "color__blue-light",
  monthColor: "color__blue__dark",
  monthBgColor: "color__bg-blue__light",
  title: "Total Leave Request Pending",
  count: "",
  days: "30 Days",
  month: "+11 this month",
  dots: "ri-more-fill",
};
