import "../../styles/employeeDirectory.css";
import { useState, useEffect } from "react";
import axios from "axios";
import { Link, useNavigate, useParams } from "react-router-dom";
import { RiEditLine } from "react-icons/ri";
import formatDate from "../Reusable/dateUtils";
import formatDateTime from "../Reusable/dateConverter";
import GoogleMapComponent from "../Reusable/GoogleMapComponent";
import EmployeeProgressBar from "./ProgressBar";
import ResigDocsPopup from "../Reusable/ResigDocsPopup";

const EmployeeDirectory = () => {
  const accessToken = localStorage.getItem("accessToken");
  const { id } = useParams();
  const navigate = useNavigate();
  const [employeeData, setEmployeeData] = useState([]);
  const [showResigDocPopup, setShowResigDocPopup] = useState(false);
  const [selectedDocumentUrl, setSelectedDocumentUrl] = useState(null);

  const fetchEmployeeData = async (id) => {
    try {
      const timestamp = new Date().getTime();
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/employees/employeeInformation/${id}?timestamp=${timestamp}`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      const data = response.data?.data;
      setEmployeeData(data);
      console.log("Basic Information Update", data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchEmployeeData(id);
  }, [id]);

  const currentId = employeeData[0]?.users?.employee_information_id;

  console.log("Employee Directory", employeeData);

  const handleUpdateDocuments = (id, name) => {
    navigate(`/employeedocumentsupdate/${id}`, {
      state: { DocumentsDataToUpdate: employeeData, documentName: name },
    });
  };

  const docsLength = employeeData[0]?.documents?.length;

  console.log("docsLength", docsLength);

  const handleOpenDocumentPopup = (documentUrl) => {
    setSelectedDocumentUrl(documentUrl);
    if (documentUrl?.startsWith("https")) {
      setShowResigDocPopup(true);
    } else {
      alert("No File to View/Download");
    }
  };

  const handleAddMoreDocuments = (id) => {
    const employeeDataToUpdate = employeeData.find(
      (employee) => employee.employee_information_id === id
    );
    if (employeeDataToUpdate && docsLength < 6) {
      navigate(`/documentsupdate/${employeeData[0]?.id}`, {
        state: { DocumentsToUpdate: employeeDataToUpdate },
      });
    } else {
      alert("All Documents Added");
    }
  };

  return (
    <>
      {employeeData.map((employee) => (
        <div className="board__layout" key={employee.id}>
          <div className="section-header">
            <div className="main__titles">
              <button onClick={() => navigate("/empmanagement")}>
                <span>
                  <i className="ri-arrow-left-s-line"></i>
                </span>
                BACK
              </button>
            </div>
            <h1>Edit Employee Details</h1>
          </div>
          <div id="employee-details" className="employee_directory-container">
            <div className="employee_info">
              <div className="first_section">
                <div className="profile_picture">
                  <img
                    src={`${
                      employee?.users?.profile_picture
                    }?timestamp=${Date.now()}`}
                    alt=""
                  />
                </div>
                <div className="employee_title">
                  <h3>{employee?.employee_full_name}</h3>
                  <p>{employee?.users?.roles[0]?.role_name}</p>
                </div>
              </div>

              <div className="employee_updated-time">
                <h3>Profile Last Updated</h3>
                <p>{formatDateTime(employee?.updated_at)}</p>
              </div>

              <div className="employee_ids">
                <p>
                  Employee ID: <span>{employee?.users?.employee_id}</span>
                </p>
                <p>
                  Official Mail ID:{" "}
                  <span>{employee?.users?.official_email}</span>
                </p>
              </div>
            </div>
            <div style={{ margin: "2rem" }}>
              <EmployeeProgressBar employeeData={employeeData} />
            </div>
            <div className="employee-directory basic_information">
              <div className="bi_title">
                <h4>Basic Details</h4>
                <Link to={`/basicinformationupdate/${employee?.id}`}>
                  <RiEditLine />
                </Link>
              </div>
              <div className="detail">
                <div className="items">
                  <div className="item">
                    <p>Date of Birth</p>
                    <h6>{formatDate(employee?.dob)}</h6>
                  </div>
                  <div className="item">
                    <p>Personal Email Id</p>
                    <h6>{employee?.personal_mail_id}</h6>
                  </div>
                </div>
                <div className="items">
                  <div className="item">
                    <p>Gender</p>
                    <h6>{employee?.gender}</h6>
                  </div>
                  <div className="item">
                    <p>Marital Status</p>
                    <h6>{employee?.marital_status ? "Married" : "Single"}</h6>
                  </div>
                </div>
                <div className="items">
                  <div className="item">
                    <p>Official Mobile Number</p>
                    <h6>
                      {employee?.work_phone_number ? (
                        <h6>{employee?.work_phone_number}</h6>
                      ) : (
                        "Nil"
                      )}
                    </h6>
                  </div>
                  <div className="item">
                    <p>Personal Mobile Number</p>
                    <h6>{employee?.personal_phone_number}</h6>
                  </div>
                </div>
                <div className="items">
                  <div className="item">
                    <p>Emergency Contact Person</p>
                    <h6>
                      {employee?.emergency_contact_name ? (
                        <h6>{employee?.emergency_contact_name}</h6>
                      ) : (
                        "Nil"
                      )}
                    </h6>
                  </div>
                  <div className="item">
                    <p>Relation with Employee</p>
                    <h6>
                      {employee?.emergency_contact_relation ? (
                        <h6>{employee?.emergency_contact_relation}</h6>
                      ) : (
                        "Nil"
                      )}
                    </h6>
                  </div>
                </div>
                <div className="items">
                  <div className="item">
                    <p>Emergency Contact Number</p>
                    <h6>
                      {employee?.emergency_contact_number ? (
                        <h6>{employee?.emergency_contact_number}</h6>
                      ) : (
                        "Nil"
                      )}
                    </h6>
                  </div>
                  <div className="item">
                    <p>Emergency Contact Email</p>
                    <h6>
                      {employee?.emergency_contact_email ? (
                        <h6>{employee?.emergency_contact_email}</h6>
                      ) : (
                        "Nil"
                      )}
                    </h6>
                  </div>
                </div>
                <div className="items">
                  <div className="item">
                    <p>Nationality</p>
                    <h6>{employee?.nationality}</h6>
                  </div>
                </div>
              </div>
              <div className="languages">
                <div className="language">
                  <p>Language</p>
                  <p style={{ marginRight: "10px" }}>Speak</p>
                  <p>Read</p>
                  <p>Write</p>
                </div>
                <div>
                  {employee.languages.map((language) => (
                    <div className="language_display">
                      <p className="align_width">{language.language_name}</p>
                      <input
                        type="checkbox"
                        checked={language.speak}
                        className="language_check"
                        disabled
                      />
                      <input
                        type="checkbox"
                        checked={language.read}
                        className="language_check"
                        disabled
                      />
                      <input
                        type="checkbox"
                        checked={language.write}
                        className="language_check"
                        disabled
                      />
                    </div>
                  ))}
                </div>
              </div>
            </div>

            <div className="employee-directory basic_information">
              <div className="bi_title">
                <h4>Address Details</h4>
                <Link to={`/addressupdate/${employee?.id}`}>
                  <RiEditLine />
                </Link>
              </div>
              <div className="detail">
                <div className="items">
                  {employee.address.map((add) => (
                    <div className="item" key={add?.id}>
                      <p>
                        {add?.is_permanent
                          ? "Permanent Address"
                          : "Current Address"}
                      </p>
                      <h6>
                        {`${add?.street_address}`} <br />
                        {`${add?.state}`} <br />
                        {`${add?.country}`}
                      </h6>
                    </div>
                  ))}
                </div>
              </div>
              <div className="detail">
                <div className="items">
                  {employee.address.map((add) => (
                    <div className="item" key={add.id}>
                      <GoogleMapComponent address={add.latitude} />
                    </div>
                  ))}
                </div>
              </div>
            </div>

            <div className="employee-directory basic_information">
              <div className="bi_title">
                <h4>Joining Details</h4>
                <Link to={`/joiningupdate/${employee?.id}`}>
                  <RiEditLine />
                </Link>
              </div>
              <div className="detail">
                <div className="items">
                  <div className="item">
                    <p>Company Name</p>
                    <h6>
                      {employee?.users?.roles[0]?.company[0]?.company_name}
                    </h6>
                  </div>
                  <div className="item">
                    <p>Department</p>
                    <h6>{employee?.users?.roles[0]?.department_name}</h6>
                  </div>
                </div>
                <div className="items">
                  <div className="item">
                    <p>Joining Date</p>
                    <h6>{formatDate(employee?.joining_date)}</h6>
                  </div>
                  <div className="item">
                    <p>Probation End Date</p>
                    <h6>{formatDate(employee?.probation_end_date)}</h6>
                  </div>
                </div>
                <div className="items">
                  <div className="item">
                    <p>Reporting Manager</p>
                    <h6>
                      {employee?.reportingManager?.first_name}{" "}
                      {employee?.reportingManager?.last_name}
                    </h6>
                  </div>
                  <div className="item">
                    <p>Designation</p>
                    <h6>{employee?.users?.roles[0]?.role_name}</h6>
                  </div>
                </div>
                <div className="items">
                  <div className="item">
                    <p>Access Card Expiry Date</p>
                    <h6>
                      {employee.access_card_expiry_date
                        ? formatDate(employee?.access_card_expiry_date)
                        : "Nil"}
                    </h6>
                  </div>
                  <div className="item">
                    <p>Work Hours</p>
                    <h6>{employee?.work_hours} Hrs/ Day</h6>
                  </div>
                </div>
              </div>
            </div>

            <div className="employee-directory basic_information">
              <div className="bi_title">
                <h4>Qualification Details</h4>
                <Link to={`/qualificationupdate/${employee.id}`}>
                  <RiEditLine />
                </Link>
              </div>
              {employee.qualifications.map((qualification) => (
                <div
                  className="detail"
                  key={qualification?.id}
                  style={{ marginBottom: "1rem" }}
                >
                  <div className="items">
                    <div className="item">
                      <p>Education</p>
                      <h6 style={{ color: "black", fontWeight: "bolder" }}>
                        {qualification?.education_level_name}
                      </h6>
                    </div>
                    <div className="item">
                      <p>Field of Study</p>
                      <h6>{qualification?.field_of_study_name}</h6>
                    </div>
                  </div>
                  <div className="items">
                    <div className="item">
                      <p>Name of University</p>
                      <h6>{qualification?.university_name}</h6>
                    </div>
                    <div className="item">
                      <p>Country</p>
                      <h6>{qualification?.country}</h6>
                    </div>
                  </div>
                  <div className="items">
                    <div className="item">
                      <p>Major of Study</p>
                      <h6>{qualification?.major_of_study_name}</h6>
                    </div>
                    <div className="item">
                      <p>Year of Passout</p>
                      <h6>{formatDate(qualification?.passout_year)}</h6>
                    </div>
                  </div>
                  <br />
                </div>
              ))}
            </div>

            <div className="employee-directory basic_information">
              <div className="bi_title">
                <h4>Experience Details</h4>
                <Link to={`/experienceupdate/${employee?.id}`}>
                  <RiEditLine />
                </Link>
              </div>
              {employee.experiences.map((experience) => (
                <div
                  className="detail"
                  key={experience?.id}
                  style={{ marginBottom: "2.5rem" }}
                >
                  <div key={experience?.id}>
                    <div className="items">
                      <div className="item">
                        <p>Previous Company Name</p>
                        <h6 style={{ color: "black", fontWeight: "bolder" }}>
                          {experience?.previous_worked_firm}
                        </h6>
                      </div>
                      <div className="item">
                        <p>Previous Company Address</p>
                        <h6>{experience?.organization_address}</h6>
                      </div>
                    </div>
                    <div className="items">
                      <div className="item">
                        <p>City</p>
                        <h6>{experience?.organization_city}</h6>
                      </div>
                      <div className="item">
                        <p>Country</p>
                        <h6>{experience?.organization_country}</h6>
                      </div>
                    </div>
                    <div className="items">
                      <div className="item">
                        <p>Previous Designation</p>
                        <h6>{experience?.designation}</h6>
                      </div>
                      <div className="item">
                        <p>Joining Date</p>
                        <h6>{formatDate(experience?.date_of_joining)}</h6>
                      </div>
                    </div>
                    <div className="items">
                      <div className="item">
                        <p>Relieving Date</p>
                        <h6>{formatDate(experience?.relieving_date)}</h6>
                      </div>
                      <div className="item">
                        <p>Total Experience</p>
                        <h6>{`${experience?.experience_in_months} Months`}</h6>
                      </div>
                    </div>
                  </div>

                  {experience.references.map((reference) => (
                    <>
                      <div className="items">
                        <div className="item">
                          <p>Reference Person</p>
                          <h6>{reference?.reference_person}</h6>
                        </div>
                        <div className="item">
                          <p>Ref. Designation</p>
                          <h6>{reference?.reference_designation}</h6>
                        </div>
                      </div>
                      <div className="items">
                        <div className="item">
                          <p>Ref. Phone Number</p>
                          <h6>{reference?.reference_mobile_no}</h6>
                        </div>
                        <div className="item">
                          <p>Email Id</p>
                          <h6>{reference?.reference_email}</h6>
                        </div>
                      </div>
                    </>
                  ))}
                </div>
              ))}
            </div>

            <div className="employee-directory basic_information">
              <div className="bi_title">
                <h4>Documents</h4>
                <button
                  className="docs_add_more-btn"
                  onClick={() =>
                    handleAddMoreDocuments(employee?.employee_information_id)
                  }
                >
                  <span>
                    <i className="ri-add-line"></i>
                  </span>
                </button>
              </div>
              <table>
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>Number</th>
                    <th>Expiry Date</th>
                    <th>Issued Country</th>
                    <th>Action</th>
                    <th>Acess Files</th>
                  </tr>
                </thead>
                <tbody>
                  {employee.documents.map((document) => (
                    <tr>
                      <td>{` ${
                        document.name === "profilePicture"
                          ? "Profile Picture"
                          : document.name === "passport"
                          ? "Passport"
                          : document.name === "ID Card"
                          ? "Emirates Id"
                          : document.name
                      } 
                                `}</td>
                      <td>{document.number ? document.number : "--"}</td>
                      <td>
                        {document.expiryDate
                          ? formatDate(document?.expiryDate)
                          : "--"}
                      </td>
                      <td>
                        {document.country_of_issue
                          ? document.country_of_issue
                          : "--"}
                      </td>
                      <td>
                        <button
                          className="doc_download-btn"
                          onClick={() =>
                            handleUpdateDocuments(currentId, document.name)
                          }
                        >
                          Update
                        </button>
                      </td>
                      <td>
                        <button
                          className="doc_download-btn"
                          onClick={() => handleOpenDocumentPopup(document.url)}
                        >
                          {document.url.startsWith("https")
                            ? "View / Download"
                            : "No File"}
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
          {showResigDocPopup && (
            <ResigDocsPopup
              documentUrl={selectedDocumentUrl}
              onClose={() => setShowResigDocPopup(false)}
            />
          )}
          <div className="employee_directory-btn">
            <button
              type="button"
              className="btn_done"
              onClick={() => navigate(`/empmanagement`)}
            >
              Done
            </button>
          </div>
        </div>
      ))}
    </>
  );
};

export default EmployeeDirectory;
