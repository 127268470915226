import BasicInformation from "./BasicInformation";
import AddressDetails from "./AddressDetails";
import JoiningDetails from "./JoiningDetails";
import Qualification from "./Qualification";
import Experience from "./Experience";
import Documents from "./Documents";

const MultiStepForm = (props) => {

console.log("MultiStepForm", props)
  const onNext = (values) => {
    props.next(values);
  }; 

  const onBack = () => {
   props.previousButton()
  }

 

  if (props.step === 1) {
    return <BasicInformation empData={props.empData} next={onNext} />;
  }
  if (props.step === 2) {
    return <AddressDetails empData={props.empData} next={onNext} onBack={onBack} />;
  }
  if (props.step === 3) {
    return <JoiningDetails empData={props.empData} next={onNext} onBack={onBack} />;
  }
  if (props.step === 4) {
    return <Qualification empData={props.empData} next={onNext} onBack={onBack} />;
  }
  if (props.step === 5) {
    return <Experience empData={props.empData} next={onNext} skipButton={props.skip} onBack={onBack} />;
  }
  if (props.step === 6) {
    return <Documents empData={props.empData} next={onNext} onBack={onBack} />;
  }
};

export default MultiStepForm;
