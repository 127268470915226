import { useLocation, useNavigate } from "react-router-dom";
import "../../styles/dashboard.css";
import { capitalizeWords } from "./capitalizeWords";
import formatDate from "./dateUtils";

const EmployeeLeftDetails = () => {
  const location = useLocation();
  const { employeeExitList, selectedCompany } = location.state;
  let serialNumber = 0;

  return (
    <div className="board__layout">
      <div className="section-header">
        <h1>Employees Exit Details</h1>
        <p>{selectedCompany}</p>
      </div>
      <div>
        <table>
          <thead>
            <tr>
              <th>Sl No.</th>
              <th>ID</th>
              <th>Employee Details</th>
              <th>Joining Date</th>
              <th>Resignation Date</th>
              <th>Last Working Date</th>
            </tr>
          </thead>
          <tbody>
            {employeeExitList.length > 0 ? (
              employeeExitList.map((employee) => (
                <tr key={employee.id}>
                  <td>{++serialNumber}</td>
                  <td>{employee?.users?.employee_id}</td>
                  <td className="management_empDetails" style={{textAlign:'center'}}>
                    <div className="management_empName">
                      {capitalizeWords(employee?.users?.employee_full_name)}
                    </div>
                    <div className="management_designation">
                      {employee?.users?.roles[0]?.role_name}
                    </div>
                  </td>
                  <td>{formatDate(employee?.joining_date)}</td>
                  <td>{formatDate(employee?.resignation_date)}</td>
                  <td>{formatDate(employee?.last_working_date)}</td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="7">No data found.</td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default EmployeeLeftDetails;
