import "../../styles/dashboard.css";
import { useLocation, useNavigate } from "react-router-dom";
import { capitalizeWords } from "./capitalizeWords";
import formatDate from "./dateUtils";

const AccessCardDetails = () => {
  const location = useLocation();
  const accessCardData = location.state?.accessCardData;
  const navigate = useNavigate();

  const getDaysLeft = (expiryDate) => {
    const oneDay = 24 * 60 * 60 * 1000; 
    const currentDate = new Date();
    const expiry = new Date(expiryDate);
    const diffDays = Math.round((expiry - currentDate) / oneDay);
    return diffDays;
  };

  const handleUpdateAccessCardDetails = (id) => {
    navigate(`/employeedirectory/${id}`);
  };

  

  return (
    <div className="board__layout">
      <div className="section-header">
        <h1>Access Card Expiry Details</h1>
      </div>
      <div>
        <table>
          <thead>
            <tr>
              <th>SL No.</th>
              <th>Employee ID</th>
              <th>Employee Details</th>
              <th>Company Name</th>
              <th>Expiry Date</th>
              <th>Days Left</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {accessCardData.map((employee, index) => {
              const id = employee.employee_information_id;
              return (
                <tr key={index}>
                  <td>{index + 1}</td>
                  <td>{employee.employee_id}</td>
                  <td>
                    <div className="leave_empName">
                      {capitalizeWords(employee?.employee_full_name)}
                    </div>
                    <div className="leave_designation">
                      {capitalizeWords(employee?.roles[0]?.role_name)}
                    </div>
                  </td>

                  <td>
                    {capitalizeWords(
                      employee?.roles[0]?.company[0]?.company_name
                    )}
                  </td>

                  <td>{formatDate(employee?.access_card_expiry_date)}</td>
                  <td>{getDaysLeft(employee?.access_card_expiry_date)}</td>
                  <td>
                    <div className="visa_expiry-edit">
                      <button
                        onClick={() =>
                          handleUpdateAccessCardDetails(
                            employee?.employee_information_id
                          )
                        }
                      >
                        Update
                      </button>
                    </div>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default AccessCardDetails;
