import React, { useState } from "react";
import "../../styles/displayCards.css";
import absentEmpData from "../../assets/dummy-data/absentEmpData";

const AbsenceWithoutLeave = ({
  selectedCompany,
  attendanceData,
  leaveData,
}) => {
  const [selectedData, setSelectedData] = useState(null);

 

  const handleClick = (item) => {
    setSelectedData(item);
  };

  return (
    <>
      <div className="absence__display-container">
        {absentEmpData
          .filter((company) => company.empCompany === selectedCompany)
          .map((item, index) => (
            <div className="display__section" key={index}>
              <div
                className="personal__section"
                onClick={() => handleClick(item)}
              >
                <div className="employee__img">
                  <img src={item.empPicture} alt="employee" />
                </div>
                <div className="emp__details">
                  <h6>{item.empName}</h6>
                  <p>{item.dateOfAbsence}</p>
                </div>
              </div>
              <div className="leave__status-absent">
                {/* <span>
                <i className={item.statusIcon}></i>
              </span> */}
                <p>1 Day</p>
              </div>
            </div>
          ))}
      </div>

      {selectedData && (
        <div className="popup-overlay">
          <div className="absent_display_card-container">
            <div className="absent_display_card-details">
              <div className="top-banner-popup">
                <h1>Absent without Leave</h1>
                <button
                  className="absent_display_card-close-btn"
                  onClick={() => handleClick()}
                >
                  x
                </button>
              </div>

              <div className="absent_display_card-top">
                <div>
                  <div>
                    <label>Employee Name</label>
                    <p>{selectedData.empName}</p>
                  </div>
                  <div>
                    <label>Contact Number</label>
                    <p>{selectedData.empPhone}</p>
                  </div>
                </div>
                <div>
                  <div>
                    <label>Email Id</label>
                    <p>{selectedData.empEmail}</p>
                  </div>
                  <div>
                    <label>Total Leaves</label>
                    <p>1</p>
                  </div>
                </div>

                <div>
                  <div>
                    <label>Reporting Manager</label>
                    <p>{selectedData.reportingManager}</p>
                  </div>
                </div>
                <div>
                  <div className="date_of-absence">
                    <label>Date of Absence</label>
                    <p>{selectedData.dateOfAbsence}</p>
                  </div>
                </div>
              </div>
              <div className="absent_display_card-button">
                <button
                  className="absent_display_card-btn"
                  onClick={() => handleClick()}
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default AbsenceWithoutLeave;
