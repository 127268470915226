import React from "react";

const ConfirmationPopup = ({ confirm, cancel, title, question }) => {
  return (
    <div className="em_popup_overlay">
      <div className="em_delete-popup">
        <div className="em_delete-header">
          <h5 className="em_delete-title">{title}</h5>
        </div>
        <p>{question}</p>
        <div className="em_delete-popup_buttons">
          <button
            className="em_delete-cancel_btn"
            type="button"
            onClick={cancel}
          >
            No
          </button>
          <button
            className="em_delete-confirm_btn"
            type="button"
            onClick={confirm}
          >
            Yes
          </button>
        </div>
      </div>
    </div>
  );
};

export default ConfirmationPopup;
