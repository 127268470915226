import { useEffect, useState, useMemo } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { Formik, Field, Form, ErrorMessage, FieldArray } from "formik";
import TextError from "../MultiStep/TextError";
import * as Yup from "yup";
import Modal from "react-modal";
Modal.setAppElement("#root");

const DocumentsUpdate = () => {
  const accessToken = localStorage.getItem("accessToken");
  const { id } = useParams();
  const location = useLocation();
  const DocumentsToUpdate = location.state?.DocumentsToUpdate;
  const [selectedDocument, setSelectedDocument] = useState("");
  const [employeeData, setEmployeeData] = useState([]);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [documentInitialData, setDocumentInitialData] = useState({
    documents: [
      {
        name: "",
        document_number: "",
        expiry_date: "",
        file: "",
        country_of_issue: "",
        is_approved: 1,
      },
    ],
  });

  const fetchEmployeeData = async (id) => {
    try {
      const timestamp = new Date().getTime();
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/employees/employeeInformation/${id}`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      const data = response.data?.data;
      setEmployeeData(data);
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchEmployeeData(id);
  }, [id]);
  

  const handleSubmit = async (values, { setSubmitting }) => {
    try {
      const axiosConfig = {
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${accessToken}`,
          "Content-Type": "multipart/form-data",
        },
      };

      const formdata = new FormData();
      formdata.append("id", id);

      values.documents.forEach((doc, index) => {
        if (doc.id) {
          formdata.append(`documents[${index}][id]`, doc.id);
          formdata.append(`documents[${index}][name]`, doc.name);
          formdata.append(`documents[${index}][expiry_date]`, doc.expiry_date);
          formdata.append(
            `documents[${index}][country_of_issue]`,
            doc.country_of_issue
          );
          formdata.append(
            `documents[${index}][document_number]`,
            doc.document_number
          );
        } else {
          formdata.append(`documents[${index}][name]`, doc.name);
          formdata.append(`documents[${index}][expiry_date]`, doc.expiry_date);
          formdata.append(
            `documents[${index}][country_of_issue]`,
            doc.country_of_issue
          );
          formdata.append(
            `documents[${index}][document_number]`,
            doc.document_number
          );
        }

        if (doc.file instanceof File) {
          formdata.append(`documents[${index}][file]`, doc.file);
        }
      });

      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/employees/documents/update`,
        formdata,
        axiosConfig
      );

      if (response.status === 200) {
        setShowSuccessModal(true);
        fetchEmployeeData(id);
      } else {
        console.log("Error:", response.data);
      }
    } catch (error) {
      console.log("error", error);
    } finally {
      setSubmitting(false);
    }
  };

  const handleCloseModal = () => {
    setShowSuccessModal(false);
    navigate(`/employeedirectory/${id}`);
  };

  function isValidURL(url) {
    try {
      new URL(url);
      return true;
    } catch (error) {
      return false;
    }
  }

  function getFileNameFromUrl(url) {
    const urlParts = url.split("/");
    const fileName = urlParts[urlParts.length - 1];
    return fileName;
  }

  return (
    <div className="board__layout">
      <div className="section-header">
        <h1>Update Documents</h1>
      </div>

      <Formik initialValues={documentInitialData} onSubmit={handleSubmit}>
        {({ values, setFieldValue }) => (
          <Form style={{ padding: "30px" }}>
            <FieldArray name="documents">
              {({ insert, remove, push }) => (
                <div>
                  {values.documents.length > 0 &&
                    values.documents.map((doc, index) => (
                      <div key={index}>
                        {index > 0 && (
                          <>
                            <p className="more_documents-title">
                              More Documents
                            </p>
                          </>
                        )}
                        <div className="form-rows">
                          <div>
                            <label htmlFor={`documents.${index}.name`}>
                              Document Name
                            </label>
                            <Field
                              name={`documents.${index}.name`}
                              as="select"
                              onChange={(e) => {
                                setSelectedDocument(e.target.value);
                                setFieldValue(
                                  `documents.${index}.name`,
                                  e.target.value
                                );
                              }}
                            >
                              <option value="">Select the Document</option>
                              {[
                                "profilePicture",
                                "Resume",
                                "EID Card",                      
                                "Passport",
                                "Visa",
                                "Insurance Card",
                              ].map((option) =>
                                DocumentsToUpdate &&
                                DocumentsToUpdate.documents &&
                                !DocumentsToUpdate.documents.find(
                                  (doc) => doc.name === option
                                ) ? (
                                  <option key={option} value={option}>
                                    {option}
                                  </option>
                                ) : null
                              )}
                            </Field>
                            <ErrorMessage
                              component={TextError}
                              name={`documents.${index}.name`}
                            />
                          </div>

                          <div>
                            <label htmlFor={`documents.${index}.file`}>
                              {values.documents[index].name !== "profilePicture"
                                ? "Upload the Document"
                                : "Upload the Image"}
                            </label>
                            <input
                              name={`documents.${index}.file`}
                              className="custom-file-upload"
                              type="file"
                              id={`documents.${index}.file`}
                              onChange={(event) => {
                                setFieldValue(
                                  `documents.${index}.file`,
                                  event.currentTarget.files[0]
                                );
                              }}
                            />
                            <ErrorMessage
                              component={TextError}
                              name={`documents.${index}.file`}
                            />
                          </div>
                        </div>
                        {values.documents[index].name !== "Resume" &&
                          values.documents[index].name !== "profilePicture" && (
                            <div className="form-rows">
                              <div>
                                <label
                                  htmlFor={`documents.${index}.document_number`}
                                >
                                  Document Number
                                </label>
                                <Field
                                  name={`documents.${index}.document_number`}
                                  placeholder={`${selectedDocument} Number`}
                                  type="text"
                                />
                                <ErrorMessage
                                  component={TextError}
                                  name={`documents.${index}.document_number`}
                                />
                              </div>
                              <div>
                                <label
                                  htmlFor={`documents.${index}.expiry_date`}
                                >
                                  Expiry Date
                                </label>
                                <Field
                                  name={`documents.${index}.expiry_date`}
                                  placeholder="Expiry Date"
                                  type="date"
                                />
                                <ErrorMessage
                                  component={TextError}
                                  name={`documents.${index}.expiry_date`}
                                />
                              </div>
                            </div>
                          )}
                        {values.documents[index].name === "Passport" && (
                          <div className="form-rows">
                            <div>
                              <label
                                htmlFor={`documents.${index}.country_of_issue`}
                              >
                                Issuing Country
                              </label>
                              <Field
                                name={`documents.${index}.country_of_issue`}
                                placeholder={`${selectedDocument} Issuing Country`}
                                type="text"
                              />
                              <ErrorMessage
                                component={TextError}
                                name={`documents.${index}.country_of_issue`}
                              />
                            </div>
                            <div></div>
                          </div>
                        )}
                        <div className="col">
                          <button
                            type="button"
                            className="formik-delete-btn"
                            onClick={() => remove(index)}
                          >
                            Delete
                          </button>
                        </div>
                      </div>
                    ))}
                  <button
                    type="button"
                    className="formik-addMore-btn"
                    onClick={() =>
                      push({
                        name: "",
                        document_number: "",
                        expiry_date: "",
                        file: "",
                        issuing_country: "",
                      })
                    }
                  >
                    Add More documents +
                  </button>
                </div>
              )}
            </FieldArray>
            <div className="form-btn-div" style={{ marginTop: "1rem" }}>
              <button
                type="button"
                className="btn-skip"
                onClick={() => navigate(`/employeedirectory/${id}`)}
              >
                Close
              </button>
              <button type="submit" className="form-btn-submit">
                Update
              </button>
            </div>
          </Form>
        )}
      </Formik>

      <Modal
        isOpen={showSuccessModal}
        onRequestClose={() => setShowSuccessModal(false)}
        contentLabel="Data Updated Successfully"
        className="react-modal-content"
        overlayClassName="react-modal-overlay"
      >
        <h5>Details Updated Successfully</h5>
        <button onClick={handleCloseModal}>OK</button>
      </Modal>
    </div>
  );
};

export default DocumentsUpdate;
