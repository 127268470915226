import "../../styles/attendance.css";
import React, { useState } from "react";
import Select from "react-select";

const SetWorkingTime = ({ employeeTimeSheet }) => {
  const initialWorkingSchedules = [
    {
      working_days: ["Monday", "Tuesday", "Wednesday", "Thursday"],
      from_time: "10:00:00",
      to_time: "19:00:00",
      effective_from: "2023-09-09",
    },
    {
      working_days: ["Saturday"],
      from_time: "10:00:00",
      to_time: "14:00:00",
      effective_from: "2023-09-09",
    },
    {
      working_days: ["Friday"],
      from_time: "10:00:00",
      to_time: "16:00:00",
      effective_from: "2023-09-09",
    },
  ];

  const [workingSchedules, setWorkingSchedules] = useState(
    initialWorkingSchedules
  );
  const [selectedSchedule, setSelectedSchedule] = useState(workingSchedules[0]);

  const handleScheduleChange = (index) => {
    setSelectedSchedule(workingSchedules[index]);
  };

  const handleTimeChange = (index, field, value) => {
    const updatedSchedules = [...workingSchedules];
    updatedSchedules[index][field] = value;
    setWorkingSchedules(updatedSchedules);
  };

  const handleDaysChange = (index, selectedOptions) => {
    const updatedSchedules = [...workingSchedules];
    updatedSchedules[index].working_days = selectedOptions.map(
      (option) => option.value
    );
    setWorkingSchedules(updatedSchedules);
  };

  const getAvailableDays = (index) => {
    const selectedDays = new Set();

    workingSchedules.forEach((schedule, i) => {
      if (i !== index) {
        schedule.working_days.forEach((day) => {
          selectedDays.add(day);
        });
      }
    });

    return [
      {
        value: "Monday",
        label: "Monday",
        isDisabled: selectedDays.has("Monday"),
      },
      {
        value: "Tuesday",
        label: "Tuesday",
        isDisabled: selectedDays.has("Tuesday"),
      },
      {
        value: "Wednesday",
        label: "Wednesday",
        isDisabled: selectedDays.has("Wednesday"),
      },
      {
        value: "Thursday",
        label: "Thursday",
        isDisabled: selectedDays.has("Thursday"),
      },
      {
        value: "Friday",
        label: "Friday",
        isDisabled: selectedDays.has("Friday"),
      },
      {
        value: "Saturday",
        label: "Saturday",
        isDisabled: selectedDays.has("Saturday"),
      },
      {
        value: "Sunday",
        label: "Sunday",
        isDisabled: selectedDays.has("Sunday"),
      },
    ];
  };

  const handleDelete = (index) => {
    const updatedSchedules = [...workingSchedules];
    updatedSchedules.splice(index, 1);
    setWorkingSchedules(updatedSchedules);
  };

  const handleAddMore = () => {
    setWorkingSchedules([...workingSchedules, getEmptySchedule()]);
  };

  const getEmptySchedule = () => {
    return {
      working_days: [],
      from_time: "",
      to_time: "",
      effective_from: "",
    };
  };

  return (
    <div>
      {workingSchedules.map((schedule, index) => (
        <div key={index} className="work_schedule-container">
          <h6>{`Time Schedule ${index + 1}`}</h6>
          <div className="working_times">
            <div>
              <label>Start Time:</label>
              <input
                type="time"
                value={schedule.from_time}
                onChange={(e) =>
                  handleTimeChange(index, "from_time", e.target.value)
                }
              />
            </div>
            <div>
              <label>End Time:</label>
              <input
                type="time"
                value={schedule.to_time}
                onChange={(e) =>
                  handleTimeChange(index, "to_time", e.target.value)
                }
              />
            </div>
          </div>

          <div className="working_days">
            <label>Working Days:</label>
            <Select
              isMulti
              options={getAvailableDays(index)}
              value={schedule.working_days.map((day) => ({
                value: day,
                label: day,
              }))}
              onChange={(selectedOptions) =>
                handleDaysChange(index, selectedOptions)
              }
            />
          </div>
          <div className="delete-button-container">
            <button
              className="formik-delete-btn"
              onClick={() => handleDelete(index)}
            >
              Delete
            </button>
          </div>
        </div>
      ))}
      <button className="formik-addMore-btn" onClick={handleAddMore}>
        Add More
      </button>
    </div>
  );
};

export default SetWorkingTime;
