import React from "react";
import "../../styles/accessdenied.css";

function AccessDenied({ setShowAccessDeniedPopup }) {
  const closePopup = () => {
    setShowAccessDeniedPopup(false);
  };

  return (
    <div className="access-denied-container">
      <div className="access_close" onClick={closePopup}>
        <span>X</span>
      </div>
      <h1 className="jumbo-heading">
        <code>Access Denied</code>
      </h1>
      <hr className="line-divider" />
      <h5 className="warning-message">
        Permission Restricted.
      </h5>
      <h3 className="denied-emoji">🚫🚫🚫🚫</h3>
      <h6 className="error-code">
        <strong>Error Code</strong>: 403 Forbidden
      </h6>
    </div>
  );
}

export default AccessDenied;
