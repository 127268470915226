export const fieldsetStyles = {
  borderWidth: "1px",
  borderStyle: "groove",
  borderColor: "#D1D1D1",
  padding: "10px",
  borderRadius: "5px",
  position: "relative",
  marginTop: "3.2rem",
};

export const legendStyles = {
  width: "fit-content",
  fontSize: "1rem",
  fontWeight: "500",
  color: "gray",
  position: "absolute",
  top: "-12px",
  left: "10px",
  backgroundColor: "#fff",
  padding: "0 5px",
};
