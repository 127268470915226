import { useState } from "react";
import { useNavigate } from "react-router-dom";
import eyeIcon from "../../assets/images/actionEye.png";
import formatDate from "./dateUtils";
import { capitalizeWords } from "./capitalizeWords";

const ExitManagementTable = ({ employeeExitData, setEmployeeExitData }) => {
  const [sortOrder, setSortOrder] = useState("asc");
  const navigate = useNavigate();
  let serialNumber = 0;

  const handleViewMore = (employee) => {
    navigate(
      `/exitmanagementview/${employee?.users?.employee_information_id}`,
      {
        state: { employeeExitData: employee },
      }
    );
  };

  const handleSort = (columnName) => {
    const currentOrder = sortOrder[columnName];
    const newOrder = currentOrder === "asc" ? "desc" : "asc";
    setSortOrder({ [columnName]: newOrder });
    const sortedData = [...employeeExitData].sort((a, b) => {
      if (columnName === "date") {
        const dateA = new Date(a[columnName]);
        const dateB = new Date(b[columnName]);
        return newOrder === "asc" ? dateA - dateB : dateB - dateA;
      } else if (columnName === "employee_id") {
        return newOrder === "asc"
          ? a?.users?.employee_id.localeCompare(b?.users?.employee_id)
          : b?.users?.employee_id.localeCompare(a?.users?.employee_id);
      } else if (columnName === "employee_full_name") {
        return newOrder === "asc"
          ? capitalizeWords(a?.users?.employee_full_name).localeCompare(
              capitalizeWords(b?.users?.employee_full_name)
            )
          : capitalizeWords(b?.users?.employee_full_name).localeCompare(
              capitalizeWords(a?.users?.employee_full_name)
            );
      } else {
        return newOrder === "asc"
          ? a[columnName].localeCompare(b[columnName])
          : b[columnName].localeCompare(a[columnName]);
      }
    });
    setEmployeeExitData(sortedData);
  };

  return (
    <div>
      <table className="leave_table">
        <thead>
          <tr>
            <th>Sl No.</th>
            <th onClick={() => handleSort("employee_full_name")}>
              Employee Details{" "}
              {sortOrder["employee_full_name"] === "asc" ? "↑" : "↓"}
            </th>
            <th onClick={() => handleSort("joining_date")}>
              Joining Date {sortOrder["joining_date"] === "asc" ? "↑" : "↓"}
            </th>
            <th onClick={() => handleSort("resignation_apply_date")}>
              Res. Apply Date{" "}
              {sortOrder["resignation_apply_date"] === "asc" ? "↑" : "↓"}
            </th>
            <th onClick={() => handleSort("resignation_date")}>
              Res. Date {sortOrder["resignation_date"] === "asc" ? "↑" : "↓"}
            </th>
            <th onClick={() => handleSort("last_working_date")}>
              Last Working Date{" "}
              {sortOrder["last_working_date"] === "asc" ? "↑" : "↓"}
            </th>
            <th onClick={() => handleSort("country_exit_date")}>
              Exit Date {sortOrder["country_exit_date"] === "asc" ? "↑" : "↓"}
            </th>
            <th onClick={() => handleSort("visa_cancel_date")}>
              Visa Cancelled{" "}
              {sortOrder["visa_cancel_date"] === "asc" ? "↑" : "↓"}
            </th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {employeeExitData.length > 0 ? (
            employeeExitData.map((employee, index) => (
              <tr key={index}>
                <td>{++serialNumber}</td>
                <td className="management_empDetails">
                  <div className="management_empName">
                    {capitalizeWords(employee?.users?.employee_full_name)}
                  </div>
                  <div className="management_designation">
                    {employee?.users?.roles[0]?.role_name}
                  </div>
                  <div className="leave_id">
                    ID:{employee?.users?.employee_id}
                  </div>
                </td>
                <td>{formatDate(employee?.joining_date)}</td>
                <td>{formatDate(employee?.resignation_apply_date)}</td>
                <td>{formatDate(employee?.resignation_date)}</td>
                <td>{formatDate(employee?.last_working_date)}</td>
                <td>{formatDate(employee?.country_exit_date)}</td>
                <td>{formatDate(employee?.visa_cancel_date)}</td>
                <td className="table__data">
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <img
                      src={eyeIcon}
                      alt="icon"
                      onClick={() => handleViewMore(employee)}
                      style={{
                        marginRight: "5px",
                        cursor: "pointer",
                        width: "35px",
                        height: "30px",
                        borderRadius: "0",
                      }}
                    />
                  </div>
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="9">No data found.</td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};

export default ExitManagementTable;
