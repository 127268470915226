import { useLocation, useNavigate } from "react-router-dom";
import { capitalizeWords } from "./capitalizeWords";
import { subDays } from "date-fns";
import formatDate from "./dateUtils";

const PendingLeaveRequestsTable = () => {
  const location = useLocation();
  const pendingLeaves = location.state.pendingLeaves;
  const navigate = useNavigate();

  return (
    <div className="board__layout">
      <div className="section-header">
        <div className="header__titles">
          <button onClick={() => navigate("/leavemanagement")}>
            <span>
              <i className="ri-arrow-left-s-line"></i>
            </span>
            BACK
          </button>
          <h4>Pending Leave Requests in Last 30 Days</h4>
        </div>
      </div>

      <table className="table">
        <thead>
          <tr>
            <th>SL No.</th>
            <th colSpan="2">Employee Details</th>
            <th>Applied Date</th>
            <th>Leave Start Date</th>
            <th>Leave End Date</th>
            <th>No Of Days</th>
            <th>Leave Type</th>
            <th>Status</th>
          </tr>
        </thead>
        <tbody>
          {pendingLeaves
            .filter(
              (leave) =>
                leave.is_approved === null &&
                leave.is_hr_approved === null &&
                new Date(leave.apply_date) >= subDays(new Date(), 30)
            )

            .map((leave, index) => (
              <tr>
                <td>{index + 1}</td>
                <td className="leave_empImage">
                  <img
                    src={`${
                      leave?.user?.profile_picture
                    }?timestamp=${Date.now()}`}
                    alt="Employee"
                  />
                </td>
                <td className="leave_empDetails">
                  <div className="leave_empName">
                    {capitalizeWords(leave?.user?.employee_full_name)}
                  </div>
                  <div className="leave_designation">
                    {leave?.user?.roles[0]?.role_name}
                  </div>
                  <div className="leave_id">ID:{leave?.user?.employee_id}</div>
                </td>
                <td>{formatDate(leave.apply_date)}</td>
                <td>{formatDate(leave.leave_from)}</td>
                <td>{formatDate(leave.leave_to)}</td>
                <td>{leave.days_count}</td>
                <td>{leave.leaveType}</td>
                <td>
                  {leave.is_approved === 1 && (
                    <span className="approved">Approved</span>
                  )}
                  {leave.is_approved === 0 && (
                    <span className="rejected">Declined</span>
                  )}
                  {leave.is_approved === null && (
                    <span className="pending">Pending</span>
                  )}
                </td>
              </tr>
            ))}
        </tbody>
      </table>
    </div>
  );
};

export default PendingLeaveRequestsTable;
