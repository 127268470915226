import "../styles/employeeSalary.css";
import SearchTable from "../components/Reusable/SearchTable";
import { useNavigate } from "react-router-dom";
import EmployeeSalaryTable from "../components/Reusable/EmployeeSalaryTable";

const EmployeeSalary = () => {
  const navigate = useNavigate();
  return (
    <div className="board__layout">
      <div className="section-header">
        <h1>Employee Salary</h1>

        <div className="add__employee-salary">
          <button onClick={() => navigate("/addemployeesalary")}>
            Add Employee Payslip +
          </button>
        </div>
      </div>

      <div>
        <EmployeeSalaryTable />
      </div>
    </div>
  );
};

export default EmployeeSalary;
