import React, { useState, useEffect } from "react";
import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
import 'leaflet/dist/leaflet.css';

const GoogleMapComponent = ({ address }) => {
  const [latitude, setLatitude] = useState(null);
  const [longitude, setLongitude] = useState(null);

  useEffect(() => {
    const url = `https://nominatim.openstreetmap.org/search?q=${encodeURIComponent(
      address
    )}&format=json`;

    fetch(url)
      .then((response) => response.json())
      .then((data) => {
        if (data.length) {
          const { lat, lon } = data[0];
          setLatitude(lat);
          setLongitude(lon);
        }
      })
      .catch((error) => {
        console.error("Error fetching location data: ", error);
      });
  }, [address]);

  return (
    <div style={{ height: "400px", width:"400px"}}>
      {latitude && longitude && (
        <MapContainer center={[latitude, longitude]} zoom={13} style={{ height: "100%" }}>
          <TileLayer
            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          />
          <Marker position={[latitude, longitude]}>
            <Popup>{address}</Popup>
          </Marker>
        </MapContainer>
      )}
    </div>
  );
};




export default GoogleMapComponent;
