import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import formatDate from "./dateUtils";

const LeaveDisplayCard = ({ selectedCompany, updateTotalLeaveRequest }) => {
  const accessToken = localStorage.getItem("accessToken");
  const permissions = JSON.parse(localStorage.getItem("userPermissions"));
  const [attendanceData, setAttendanceData] = useState([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  const getCurrentDate = () => {
    const currentDate = new Date();
    const formattedDate = currentDate.toISOString().substr(0, 10);
    return formattedDate;
  };

  const getEmployeeMonthWiseLeave = async () => {
    try {
      const currentDate = new Date();
      const toDate = currentDate.toISOString().substr(0, 10);
      currentDate.setDate(currentDate.getDate() - 30);
      const fromDate = currentDate.toISOString().substr(0, 10);

      let apiUrl = "";
      const hasViewAllManagedLeaveHistory = permissions.some(
        (permission) => permission.name === "view_managed_leave_history"
      );
      const hasViewAllLeaveHistory = permissions.some(
        (permission) => permission.name === "view_all_leave_history"
      );

      if (hasViewAllManagedLeaveHistory) {
        apiUrl = `${process.env.REACT_APP_BASE_URL}/employees/leaveHistoryForManagers?from=${fromDate}&to=${toDate}`;
      } else if (hasViewAllLeaveHistory) {
        apiUrl = `${process.env.REACT_APP_BASE_URL}/employees/companyWiseLeaveHistory?from=${fromDate}&to=${toDate}&company=${selectedCompany}`;
      } else {
        console.log("User does not have required permissions.");
        return;
      }

      const response = await axios.get(apiUrl, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
      const dataObject = response.data?.data;
      const dataArray = Object.keys(dataObject).map((key) => dataObject[key]);

      setAttendanceData(dataArray);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  useEffect(() => {
    getEmployeeMonthWiseLeave();
  }, [selectedCompany]);

  useEffect(() => {
    const TotalLeaveRequest = attendanceData.length;
    updateTotalLeaveRequest(TotalLeaveRequest);
  }, [attendanceData, updateTotalLeaveRequest]);

  return (
    <div className="leave__display-container">
      {attendanceData.map((employee, index) => (
        <div
          className="display__section"
          onClick={() => navigate("/leavemanagement")}
        >
          <div className="personal__section">
            <div className="employee__img">
              <img src={employee.user.profile_picture} alt="employee" />
            </div>
            <div className="emp__details">
              <h6>{employee.user.employee_full_name}</h6>
              <p>{formatDate(employee.leave_from)}</p>
              <p>{formatDate(employee.leave_to)}</p>
            </div>
          </div>
          <div
            className={`${
              employee.is_approved === 1 ? "leave__status green" : ""
            } ${employee.is_approved === 0 ? "leave__status red" : ""} ${
              employee.is_approved !== 1 && employee.is_approved !== 0
                ? "leave__status orange"
                : ""
            }`}
          >
            <span>
              <i className="fa-solid fa-circle"></i>
            </span>
            <p>
              {employee.is_approved === 0 && "Declined"}
              {employee.is_approved === 1 && "Approved"}
              {employee.is_approved !== 0 &&
                employee.is_approved !== 1 &&
                "Pending"}
            </p>
          </div>
        </div>
      ))}
    </div>
  );
};

export default LeaveDisplayCard;
