import '../../styles/documentPopup.css'

const DocumentPopup = ({ documentUrl, onClose }) => {
    return (
      <div className="document-popup">
        <div className="document-header">
          <span>Document</span>
          <button className="close-document" onClick={onClose}>
            Close
          </button>
        </div>
        <iframe src={documentUrl} title="Document" width="100%" height="500px" />
      </div>
    );
  };

  export default DocumentPopup