import { useEffect, useState } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import Sidebar from "../Sidebar/Sidebar";
import Navbar from "../Navbar/Navbar";

const Layout = ({ children }) => {
 
  const [userData, setUserData] = useState(localStorage.getItem("userData"));
  const navigate = useNavigate();

  useEffect(() => {
    const userData = JSON.parse(localStorage.getItem("userData"));
    if (!userData) navigate("/");
    setUserData(userData);
  }, []);

  return (
    <div className="layout">
      <Sidebar />
      <div className="main__layout">
        <Navbar userData={userData} setuserData={setUserData} />
        <div className="content">{children ? children : <Outlet />}</div>
      </div>
    </div>
  );
};

export default Layout;
