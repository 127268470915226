
import './App.css';
import Layout from '../src/components/Layout/Layout'
import LoginPage from './pages/LoginPage';
import Router from './routes/Router';


function App() {

  return (
    <div>
      <Router />
      {/* <LoginPage /> */}
      {/* <Layout/> */}
    </div>
  );
}

export default App;
