import { useState, useEffect, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import editRole from "../assets/images/editRole.png";
import deleteRole from "../assets/images/deleterole.png";
import SearchTable from "../components/Reusable/SearchTable";

const RoleManagementTable = () => {
  const accessToken = localStorage.getItem("accessToken");
  const [selectedCompany, setSelectedCompany] = useState(
    localStorage.getItem("selectedCompany") || ""
  );
  const [selectedDepartment, setSelectedDepartment] = useState("");
  const [companyList, setCompanyList] = useState([]);
  const [departmentList, setDepartmentList] = useState([]);
  const [departmentId, setDepartmentId] = useState([]);
  const [roleManagementData, setRoleManagementData] = useState([]);
  const [sortOrder, setSortOrder] = useState("asc");
  const [currentPage, setCurrentPage] = useState(1);
  const navigate = useNavigate();

  const rowsPerPage = 10;
  let serialNumber = 1;

  const getCompany = () => {
    axios
      .get(`${process.env.REACT_APP_BASE_URL}/companies`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      })
      .then((response) => {
        const data = response.data?.data || [];
        setCompanyList(data);
      })
      .catch((error) => console.log(error));
  };

  const getDepartments = useCallback(
    (company = selectedCompany) => {
      return axios
        .get(
          `${process.env.REACT_APP_BASE_URL}/departments?company=${company}`,
          {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          }
        )
        .then((response) => {
          const data = response.data?.data || [];
          setDepartmentList(data);
          return data;
        })
        .catch((error) => console.log(error));
    },
    [selectedCompany]
  );

  useEffect(() => {
    getCompany();
  }, []);

  useEffect(() => {
    if (selectedCompany) {
      getDepartments(selectedCompany);
    }
  }, [selectedCompany, getDepartments]);

  const handleCompanyChange = (event) => {
    setSelectedCompany(event.target.value);
  };

  const handleDepartmentChange = (event) => {
    const selectedDepartmentName = event.target.value;
    const selectedDepartment = departmentList.find(
      (department) => department.department_name === selectedDepartmentName
    );
    const selectedDepartmentId = selectedDepartment
      ? selectedDepartment.id
      : null;
    setDepartmentId(selectedDepartmentId);
  };

  const handleSearch = () => {
    axios
      .get(
        `${process.env.REACT_APP_BASE_URL}/departments/${departmentId}/roles`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      )
      .then((response) => {
        const data = response.data?.data || [];
        setRoleManagementData(data);
      })
      .catch((error) => console.log(error));
  };

  const createRoleButtonClick = () => {
    navigate("/createrole");
  };

  const handleEditRole = (employee) => {
    navigate(`/updatepermissions/${departmentId}`, {
      state: { roleData: employee },
    });
  };

  const handleSort = (columnName) => {
    const currentOrder = sortOrder[columnName];
    const newOrder = currentOrder === "asc" ? "desc" : "asc";
    setSortOrder({ [columnName]: newOrder });
    const sortedData = [...roleManagementData].sort((a, b) => {
      return newOrder === "asc"
        ? a[columnName].localeCompare(b[columnName])
        : b[columnName].localeCompare(a[columnName]);
    });
    setRoleManagementData(sortedData);
  };

  const getCurrentPageData = () => {
    const startIndex = (currentPage - 1) * rowsPerPage;
    const endIndex = startIndex + rowsPerPage;
    return roleManagementData.slice(startIndex, endIndex);
  };

  const totalPages = Math.ceil(roleManagementData.length / rowsPerPage);

  const pageNumbers = Array.from({ length: totalPages }, (_, i) => i + 1);

  const goToPage = (pageNumber) => {
    if (pageNumber >= 1 && pageNumber <= totalPages) {
      setCurrentPage(pageNumber);
    }
  };

  const startingSerialNumber = (currentPage - 1) * rowsPerPage + 1;

  return (
    <div className="board__layout">
      <div className="section-header">
        <h1>Role Management</h1>
        <div className="attendance_header-btn">
          <button onClick={createRoleButtonClick}>Create Role +</button>
        </div>
      </div>

      <SearchTable
        selectedCompany={selectedCompany}
        selectedDepartment={selectedDepartment}
        companyList={companyList}
        departmentList={departmentList}
        handleCompanyChange={handleCompanyChange}
        handleDepartmentChange={handleDepartmentChange}
        handleSearch={handleSearch}
      />

      <table>
        <thead>
          <tr>
            <th>SL No.</th>
            <th onClick={() => handleSort("role_name")}>
              Role Name {sortOrder["role_name"] === "asc" ? "↑" : "↓"}
            </th>
            <th onClick={() => handleSort("company_name")}>
              Company Name {sortOrder["company_name"] === "asc" ? "↑" : "↓"}
            </th>
            <th onClick={() => handleSort("department_name")}>
              Department Name{" "}
              {sortOrder["department_name"] === "asc" ? "↑" : "↓"}
            </th>

            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {getCurrentPageData().map((employee, index) => (
            <tr key={employee?.emp_id}>
              <td>{startingSerialNumber + index}</td>
              <td>{employee?.role_name}</td>
              <td>{employee?.company[0]?.company_name}</td>
              <td>{employee?.department_name}</td>
              <td className="table__data">
                <div className="role_management-icons">
                  <img
                    src={editRole}
                    alt="icon"
                    onClick={() => handleEditRole(employee)}
                  />
                  <img src={deleteRole} alt="icon" />
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <div className="pagination">
        <button onClick={() => goToPage(currentPage - 1)}>Previous</button>
        <div className="page-numbers">
          {pageNumbers.map((pageNumber) => (
            <button
              key={pageNumber}
              onClick={() => goToPage(pageNumber)}
              className={currentPage === pageNumber ? "active" : ""}
            >
              {pageNumber}
            </button>
          ))}
        </div>
        <button onClick={() => goToPage(currentPage + 1)}>Next</button>
      </div>
    </div>
  );
};

export default RoleManagementTable;
