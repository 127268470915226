import { BsThreeDots } from "react-icons/bs";
import { useNavigate } from "react-router-dom";

const SingleCard = (props) => {
  const {
    iconImg,
    iconBgColor,
    monthBgColor,
    title,
    count,
    days,
    month,
    dots,
    linkTo,
  } = props.item;

  const navigate = useNavigate();

  return (
    <div className="single__card">
      <div className="card__contents">
        <div className="card__icons">
          <div className={`card__icon-img ${iconBgColor}`}>
            <img src={iconImg} alt="icon" />
          </div>
          <span className="card__icon-dots" style={{ cursor: "pointer" }}>
          <i onClick={() => navigate(linkTo)} className={dots}></i>
          </span>
        </div>
        <div className="card__content">
          <div className="card__details">
            <h5>{title}</h5>
            <h2>{count}</h2>
            <p>{days}</p>
          </div>
          <div className={`card__month-header ${monthBgColor}`}>
            <span className="card__month">{month}</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SingleCard;
