const navLinksOther = [
  {
    path: "/support",
    icon: "fa-solid fa-headset",
    title: "Support",
  },

  {
   
    icon: "fa-sharp fa-solid fa-gear",
    title: "Settings",
    children: [
      {
        title: "Roll Management",
        icon: "ri-arrow-right-s-fill",
        path: "/rolemanagement",
      },
    ],
  },
];

export default navLinksOther;
