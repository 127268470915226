import "../styles/employeeDetails.css";
import "../styles/attendance.css";
import "../styles/Calendar.css";

// import liabaries
import axios from "axios";
import { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { startOfMonth, endOfMonth } from "date-fns";

// import other internal components
import SingleCard from "../components/Reusable/SingleCard";
import Calendar from "react-calendar";
import InstanceCard from "../components/Reusable/InstanceCard";

// Icons from image gallery
import empIcon from "../assets/images/emp-icon.png";
import dateIcon from "../assets/images/joiningdate.png";
import calendarIcon from "../assets/images/calendar.png";

import {
  totalAttendances,
  totalPresents,
  monthlyLeave,
  shortHours,
  noOfInstances,
} from "../components/Reusable/CardData";

const EmployeeAttendanceDetails = () => {
  const accessToken = localStorage.getItem("accessToken");
  const permissions = JSON.parse(localStorage.getItem("userPermissions"));
  const location = useLocation();
  const selectedData = location.state?.selectedData;
  const navigate = useNavigate();

  const [monthlyAttendanceData, setMonthlyAttendanceData] = useState([]);

  const today = new Date();
  const initialStartDate = startOfMonth(today);
  const initialEndDate = endOfMonth(today);
  const [selectedStartDate, setSelectedStartDate] = useState(initialStartDate);
  const [selectedEndDate, setSelectedEndDate] = useState(initialEndDate);
  const [startDate, setStartDate] = useState(initialStartDate);
  const [endDate, setEndDate] = useState(initialEndDate);
  const [selectedRows, setSelectedRows] = useState([]);
  const [newLoginTime, setNewLoginTime] = useState([]);
  const [newLogoutTime, setNewLogoutTime] = useState([]);
  const [newRemarks, setNewRemarks] = useState([]);
  const [view, setView] = useState(false);
  const [loading, setLoading] = useState(true);
  const [minDate, setMinDate] = useState(null);
  const [lateCount, setLateCount] = useState();
  const [isEditMode, setIsEditMode] = useState(false);
  const [sortOrder, setSortOrder] = useState("asc");
  const selectedCompany = "SCALEX IT SOLUTIONS";
  let punchingTime;

  if (selectedCompany === "SCALEX IT SOLUTIONS") {
    punchingTime = "10:15:00";
  } else if (selectedCompany === "GREEN APPLE SOFTWARE TRADING LLC") {
    punchingTime = "09:45:00";
  } else if (selectedCompany === "BLUE BIRD SIM CARDS TRADING LLC") {
    punchingTime = "09:45:00";
  }

  const startDateOnChange = (startDate) => {
    setSelectedStartDate(startDate);
    setMinDate(startDate);
  };

  const endDateOnChange = (endDate) => {
    setSelectedEndDate(endDate);
  };

  const formatDate = (date) => {
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const day = date.getDate().toString().padStart(2, "0");
    return `${month}/${day}/${year}`;
  };

  const calculateTimeDifference = (startTime, endTime) => {
    const start = new Date(`2000-01-01T${startTime}`);
    const end = new Date(`2000-01-01T${endTime}`);
    const diffMilliseconds = end - start;

    const hours = Math.floor(diffMilliseconds / 3600000);
    const minutes = Math.floor((diffMilliseconds % 3600000) / 60000);
    const seconds = Math.floor((diffMilliseconds % 60000) / 1000);

    return `${hours.toString().padStart(2, "0")}:${minutes
      .toString()
      .padStart(2, "0")}:${seconds.toString().padStart(2, "0")}`;
  };

  const getEmployeeAttendance = async () => {
    try {
      const formattedStartDate = formatDate(selectedStartDate);
      const formattedEndDate = formatDate(selectedEndDate);

      let apiUrl = "";

      console.log("Permissions according to user", permissions);
      const hasViewAllManagedPermission = permissions.some(
        (permission) => permission.name === "view_attendances_managed_employee"
      );
      const hasViewPerGroupPermission = permissions.some(
        (permission) => permission.name === "view_attendances_per_group"
      );
      const hasViewPerAttendanceDetailsPermission = permissions.some(
        (permission) => permission.name === "view_employee_attendances_details"
      );

      if (hasViewPerAttendanceDetailsPermission) {
        apiUrl = `${process.env.REACT_APP_BASE_URL}/attendances/details?empId=${selectedData.emp_id}&from=${formattedStartDate}&to=${formattedEndDate}`;
      } else if (hasViewPerAttendanceDetailsPermission) {
        apiUrl = `${process.env.REACT_APP_BASE_URL}/attendances/details?empId=${selectedData.emp_id}&from=${formattedStartDate}&to=${formattedEndDate}`;
      } else {
        console.log("User does not have required permissions.");
        return;
      }

      const response = await axios.get(apiUrl, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
      const dataObject = response.data?.data;
      const dataArray = Object.keys(dataObject).map((key) => {
        const attendance = dataObject[key];
        const lateTime =
          attendance.login_time > punchingTime
            ? calculateTimeDifference(punchingTime, attendance.login_time)
            : "00:00:00";

        const isLate = attendance.login_time > punchingTime;

        return { ...attendance, lateTime, isLate };
      });

      setMonthlyAttendanceData(dataArray);

      const lateCount = dataArray.reduce((count, attendance) => {
        return count + (attendance.isLate ? 1 : 0);
      }, 0);

      setLateCount(lateCount);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  useEffect(() => {
    getEmployeeAttendance();
  }, []);

  const clearAllDates = () => {
    setSelectedStartDate(null);
    setSelectedEndDate(null);
  };

  const searchButtonClick = () => {
    getEmployeeAttendance();
    setView(false);
  };

  const hasUpdateAttendancePermission = permissions.some(
    (permission) => permission.name === "update_attendance"
  );

  const handleCheckboxChange = (index) => {
    if (!isEditMode) {
      if (selectedRows.includes(index)) {
        setSelectedRows(selectedRows.filter((rowIndex) => rowIndex !== index));
      } else {
        setSelectedRows([...selectedRows, index]);
      }
    }
  };

  const handleEditButtonClick = () => {
    if (selectedRows.length !== 1) {
      alert("Please select one employee to edit.");
      return;
    }

    if (hasUpdateAttendancePermission) {
      setIsEditMode(true); // Enable edit mode
    } else {
      alert("You don't have permission to edit the attendance");
    }
  };

  const handleUpdateButtonClick = async () => {
    await Promise.all(
      selectedRows.map(async (index) => {
        const updatedItem = {
          ...monthlyAttendanceData[index],
          login_time:
            newLoginTime[index] || monthlyAttendanceData[index].login_time,
          logout_time:
            newLogoutTime[index] || monthlyAttendanceData[index].logout_time,
          remarks: newRemarks[index] || monthlyAttendanceData[index].remarks,
        };

        const requestOptions = {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            "Content-Type": "application/json",
          },
        };

        const requestBody = {
          attendance_date: updatedItem.date,
          from_time_frame: updatedItem.login_time,
          to_time_frame: updatedItem.logout_time,
          status: updatedItem.status,
          remarks: updatedItem.remarks,
        };

        try {
          const response = await axios.put(
            `${process.env.REACT_APP_BASE_URL}/employees/attendances/${selectedData.emp_id}`,
            requestBody,
            requestOptions
          );
        } catch (error) {
          console.error("Error updating attendance:", error);
        }
      })
    );

    setSelectedRows([]);
    setNewLoginTime([]);
    setNewLogoutTime([]);
    setNewRemarks([]);
    getEmployeeAttendance();
  };

  const totalLateHours = monthlyAttendanceData.reduce(
    (total, attendance) => {
      const [hours, minutes, seconds] = attendance.lateTime.split(":");
      total.hours += parseInt(hours);
      total.minutes += parseInt(minutes);
      total.seconds += parseInt(seconds);
      return total;
    },
    { hours: 0, minutes: 0, seconds: 0 }
  );

  const totalLateTime = `${(
    totalLateHours.hours + Math.floor(totalLateHours.minutes / 60)
  )
    .toString()
    .padStart(2, "0")}:${(totalLateHours.minutes % 60)
    .toString()
    .padStart(2, "0")}:${totalLateHours.seconds.toString().padStart(2, "0")}`;

  const totalselectedDays = monthlyAttendanceData.length;

  const totalPresent = monthlyAttendanceData.filter(
    (item) => item.status !== "Absent"
  ).length;

  const holidayCount = monthlyAttendanceData.filter(
    (item) =>
      item.is_holiday === 1 &&
      new Date(item.date) >= selectedStartDate &&
      new Date(item.date) <= selectedEndDate
  ).length;

  const woCount = monthlyAttendanceData.filter(
    (item) =>
      item.status === "WO" &&
      new Date(item.date) >= selectedStartDate &&
      new Date(item.date) <= selectedEndDate
  ).length;

  const totalHolidayCount = holidayCount + woCount;

  const totalWorkingDays = totalselectedDays;

  const totalLeave = totalWorkingDays - totalPresent;

  const handleSort = (columnName) => {
    const currentOrder = sortOrder[columnName];
    const newOrder = currentOrder === "asc" ? "desc" : "asc";
    setSortOrder({ [columnName]: newOrder });
    const sortedData = [...monthlyAttendanceData].sort((a, b) => {
      if (columnName === "date") {
        const dateA = new Date(a[columnName]);
        const dateB = new Date(b[columnName]);
        return newOrder === "asc" ? dateA - dateB : dateB - dateA;
      } else if (columnName === "login_time" || columnName === "logout_time") {
        const timeA = timeStringToComparableValue(a[columnName]);
        const timeB = timeStringToComparableValue(b[columnName]);
        return newOrder === "asc" ? timeA - timeB : timeB - timeA;
      } else {
        return newOrder === "asc"
          ? a[columnName].localeCompare(b[columnName])
          : b[columnName].localeCompare(a[columnName]);
      }
    });
    setMonthlyAttendanceData(sortedData);
  };

  const timeStringToComparableValue = (timeStr) => {
    if (timeStr) {
      const [hours, minutes, seconds] = timeStr.split(":").map(Number);
      return hours * 3600 + minutes * 60 + seconds;
    }
    return 0;
  };

  return (
    <div className="board__layout">
      <div className="section-header">
        <div className="header__titles">
          <button onClick={() => navigate("/timeattendance")}>
            <span>
              <i className="ri-arrow-left-s-line"></i>
            </span>
            BACK
          </button>
          <h4>Employee Attendance Details</h4>
        </div>
      </div>

      <div className="section__content">
        <div className="employee__time-title">
          <div>
            <img
              src={`${selectedData.profile_picture}?timestamp=${Date.now()}`}
              alt="employee"
              style={{ borderRadius: "100px" }}
            />
          </div>
          <div>
            <h1>{selectedData.name}</h1>
            <p>{selectedData.position}</p>
          </div>
        </div>

        <div
          className="employee__time-details"
          style={{
            borderRight: "1px solid #0A9250",
            borderLeft: "1px solid #0A9250",
          }}
        >
          <div className="emp__detail">
            <div>
              <img src={empIcon} alt="icon" />
            </div>
            <div>
              <h6>Employee ID</h6>
              <p>{selectedData.employee_id}</p>
            </div>
          </div>
        </div>

        <div className="employee__time-details">
          <div className="emp__detail">
            <div>
              <img src={dateIcon} alt="icon" />
            </div>
            <div>
              <h6>Joining Date</h6>
              <p>{selectedData.joining_date}</p>
            </div>
          </div>
        </div>
      </div>

      <div className="attendance_cards">
        <SingleCard item={{ ...totalAttendances, count: totalWorkingDays }} />
        <SingleCard item={{ ...totalPresents, count: totalPresent }} />
        <SingleCard item={{ ...monthlyLeave, count: totalLeave }} />
      </div>

      <div className="attendance_card-section">
        <div className="short-hours">
          <InstanceCard item={{ ...shortHours, count: totalLateTime }} />
        </div>
        <div className="no-of-instances">
          <InstanceCard item={{ ...noOfInstances, count: lateCount }} />
        </div>
      </div>

      <div className="utility-bar">
        <div className="utility_bar-left">
          <div className="calendar">
            <div>
              {!selectedStartDate && !selectedEndDate && (
                <p style={{ margin: 0 }}>Select Dates</p>
              )}
            </div>
            <div>
              <img
                src={calendarIcon}
                alt="icon"
                onClick={() => setView(!view)}
              />
            </div>
            <div>
              {selectedStartDate && selectedEndDate && (
                <p style={{ margin: 0 }}>Selected Dates</p>
              )}
            </div>
          </div>
          {view && (
            <div className="ta_calendar-container">
              <div className="ta_calendar">
                <div>
                  <Calendar
                    onChange={startDateOnChange}
                    value={selectedStartDate}
                  />
                </div>
                <div>
                  <Calendar
                    onChange={endDateOnChange}
                    value={selectedEndDate}
                    minDate={selectedStartDate}
                  />
                </div>
              </div>
              <div className="ta_calendar-btn">
                <button className="clear-button" onClick={clearAllDates}>
                  Clear All
                </button>
                <button className="search-button" onClick={searchButtonClick}>
                  Search
                </button>
              </div>
            </div>
          )}

          {selectedStartDate && selectedEndDate && (
            <div className="selected_dates" style={{ fontSize: "14px" }}>
              {selectedStartDate.toLocaleDateString()} -{" "}
              {selectedEndDate.toLocaleDateString()}
            </div>
          )}
        </div>
        {hasUpdateAttendancePermission && (
          <div className="utility_bar-right">
            <div>
              {selectedRows.length > 0 ? (
                <button onClick={handleUpdateButtonClick}>
                  <span>
                    <i className="ri-refresh-fill"></i>Update
                  </span>
                </button>
              ) : (
                <button onClick={handleEditButtonClick}>
                  <span>
                    <i className="ri-edit-fill"></i>Edit
                  </span>
                </button>
              )}
            </div>
          </div>
        )}
      </div>

      <div className="attendance__table">
        <table className="table">
          <thead>
            <tr>
              <th>
                {hasUpdateAttendancePermission && <input type="checkbox" />}
              </th>
              <th onClick={() => handleSort("date")}>
                Date {sortOrder["date"] === "asc" ? "↑" : "↓"}
              </th>
              <th onClick={() => handleSort("login_time")}>
                Login Time {sortOrder["login_time"] === "asc" ? "↑" : "↓"}
              </th>
              <th onClick={() => handleSort("logout_time")}>
                Logout Time {sortOrder["logout_time"] === "asc" ? "↑" : "↓"}
              </th>
              <th onClick={() => handleSort("status")}>
                Status {sortOrder["status"] === "asc" ? "↑" : "↓"}
              </th>
              <th>Remarks</th>
            </tr>
          </thead>
          <tbody>
            {loading ? (
              <tr>
                <td colSpan="5">Loading...</td>
              </tr>
            ) : (
              monthlyAttendanceData.map((item, index) => {
                const startTime = item.login_time;
                const isLate = startTime > punchingTime;
                const isRowSelected = selectedRows.includes(index);
                const remarksValue = isRowSelected
                  ? newRemarks[index] || item.remarks
                  : item.remarks;
                return (
                  <tr key={index}>
                    <td>
                      {hasUpdateAttendancePermission && (
                        <input
                          type="checkbox"
                          checked={isRowSelected}
                          onChange={() => handleCheckboxChange(index)}
                        />
                      )}
                    </td>

                    <td>{item.date}</td>
                    <td>
                      {isRowSelected ? (
                        <input
                          type="text"
                          value={newLoginTime[index] || item.login_time}
                          onChange={(e) => {
                            const updatedTimes = [...newLoginTime];
                            updatedTimes[index] = e.target.value;
                            setNewLoginTime(updatedTimes);
                          }}
                        />
                      ) : (
                        item.login_time
                      )}
                    </td>
                    <td>
                      {isRowSelected ? (
                        <input
                          type="text"
                          value={newLogoutTime[index] || item.logout_time}
                          onChange={(e) => {
                            const updatedTimes = [...newLogoutTime];
                            updatedTimes[index] = e.target.value;
                            setNewLogoutTime(updatedTimes);
                          }}
                        />
                      ) : (
                        item.logout_time
                      )}
                    </td>
                    <td
                      className={
                        isLate
                          ? "lateStatus"
                          : item.status === "Present"
                          ? "ontimeStatus"
                          : "holidayStatus"
                      }
                    >
                      {item.status}
                    </td>
                    <td>
                      {isRowSelected ? (
                        <input
                          type="text"
                          value={newRemarks[index] || item.remarks}
                          onChange={(e) => {
                            const updatedRemarks = [...newRemarks];
                            updatedRemarks[index] = e.target.value;
                            setNewRemarks(updatedRemarks);
                          }}
                        />
                      ) : (
                        item.remarks
                      )}
                    </td>
                  </tr>
                );
              })
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default EmployeeAttendanceDetails;
