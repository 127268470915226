import "../MultiStep/multiStep.css";
import { useState, useRef, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import Modal from "react-modal";
Modal.setAppElement("#root");

const BasicInformationUpdate = () => {
  const accessToken = localStorage.getItem("accessToken");
  const { id } = useParams();
  const [employeeData, setEmployeeData] = useState([]);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [formData, setFormData] = useState({});
  const [languages, setLanguages] = useState([]);
  const firstNameRef = useRef(null);
  const navigate = useNavigate();

  const fetchEmployeeData = async (id) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/employees/employeeInformation/${id}`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      const data = response.data?.data;
      setEmployeeData(data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchEmployeeData(id);
  }, [id]);

  useEffect(() => {
    if (firstNameRef.current) {
      firstNameRef.current.focus();
    }

    if (employeeData.length > 0) {
      const { gender, marital_status } = employeeData[0];

      setFormData({
        gender: gender,
        marital_status: parseInt(marital_status, 10),
      });
    }
  }, [employeeData]);

  useEffect(() => {
    if (employeeData.length > 0) {
      const initialLanguages = employeeData[0].languages || [];
      setLanguages(initialLanguages);
    }
  }, [employeeData]);

  const handleChange = (e) => {
    const { name, value } = e.target;

    if (name === "gender") {
      setFormData((prevData) => ({ ...prevData, [name]: value }));
    } else if (name === "marital_status") {
      const maritalStatusValue = parseInt(value, 10);
      setFormData((prevData) => ({ ...prevData, [name]: maritalStatusValue }));
    } else {
      setFormData((prevData) => ({ ...prevData, [name]: value }));
    }
  };

  const handleCloseModal = () => {
    setShowSuccessModal(false);
    navigate(`/employeedirectory/${id}`);
  };

  const handleCheckboxChange = (index, type) => {
    setLanguages((prevLanguages) => {
      const newLanguages = [...prevLanguages];
      newLanguages[index][type] = !newLanguages[index][type];
      return newLanguages;
    });
  };

  const handleLanguageChange = (name, value, index) => {
    setLanguages((prevLanguages) => {
      const newLanguages = [...prevLanguages];
      newLanguages[index] = {
        ...newLanguages[index],
        [name]: value,
      };
      return newLanguages;
    });
  };

  const handleAddLanguage = () => {
    setLanguages([
      ...languages,
      { language_name: "", read: false, write: false, speak: false },
    ]);
  };

  const handleRemoveLanguage = (index) => {
    const updatedLanguages = [...languages];
    updatedLanguages.splice(index, 1);
    setLanguages(updatedLanguages);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const updatedBasicInfo = { ...formData, id: id };
      await axios.put(
        `${process.env.REACT_APP_BASE_URL}/employees/information`,
        updatedBasicInfo,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      // Update the language details
      const languageData = {
        id: id,
        languages: languages.map((language) => {
          return {
            language_name: language.language_name,
            read: language.read,
            write: language.write,
            speak: language.speak,
            is_approved: true,
          };
        }),
      };

      await axios.put(
        `${process.env.REACT_APP_BASE_URL}/employees/languages`,
        languageData,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      setShowSuccessModal(true);
    } catch (error) {
      console.log("Error updating data:", error.response.data);
    }
  };

  return (
    <div className="board__layout">
      <div className="section-header">
        <h1>Update Basic Information</h1>
      </div>

      {employeeData.map((employee) => (
        <form onSubmit={handleSubmit} style={{ padding: "30px" }}>
          <div>
            <div className="form-rows">
              <div>
                <label htmlFor="first_name">First Name</label>
                <input
                  type="text"
                  name="first_name"
                  id="first_name"
                  innerRef={firstNameRef}
                  placeholder="Enter your Employee First Name"
                  value={formData.first_name || employee.first_name}
                  onChange={handleChange}
                />
              </div>
              <div>
                <label htmlFor="last_name">Last Name</label>
                <input
                  type="text"
                  name="last_name"
                  id="last_name"
                  placeholder="Enter your Employee Last Name"
                  value={formData.last_name || employee.last_name}
                  onChange={handleChange}
                />
              </div>
            </div>

            <div className="form-rows">
              <div>
                <label htmlFor="dob">Date of Birth</label>
                <input
                  type="date"
                  name="dob"
                  id="dob"
                  placeholder="Enter your Employee Date of Birth"
                  value={formData.dob || employee.dob}
                  onChange={handleChange}
                />
              </div>
              <div>
                <label htmlFor="personal_mail_id">Email</label>
                <input
                  type="email"
                  name="personal_mail_id"
                  id="personal_mail_id"
                  placeholder="Enter Employee Personal Email Address"
                  value={formData.personal_mail_id || employee.personal_mail_id}
                  onChange={handleChange}
                />
              </div>
            </div>
            <div
              style={{
                display: "flex",
                columnGap: "27rem",
                rowGap: "1rem",
                marginTop: "1rem",
                marginLeft: "1rem",
              }}
            >
              <div>
                <label>Gender</label>
                <div>
                  <div>
                    <label>
                      <input
                        type="radio"
                        name="gender"
                        id="male"
                        value="Male"
                        checked={formData.gender === "Male"}
                        onChange={handleChange}
                      />
                      Male
                    </label>
                  </div>
                  <div style={{ whiteSpace: "nowrap" }}>
                    <label>
                      <input
                        type="radio"
                        name="gender"
                        id="female"
                        value="Female"
                        checked={formData.gender === "Female"}
                        onChange={handleChange}
                      />
                      Female
                    </label>
                  </div>
                </div>
              </div>

              <div>
                <label style={{ whiteSpace: "nowrap" }}>Marital Status</label>
                <div>
                  <div>
                    <label>
                      <input
                        type="radio"
                        name="marital_status"
                        id="single"
                        value="0"
                        checked={formData.marital_status === 0}
                        onChange={handleChange}
                      />
                      Single
                    </label>
                  </div>
                  <div>
                    <label>
                      <input
                        type="radio"
                        name="marital_status"
                        id="married"
                        value="1"
                        checked={formData.marital_status === 1}
                        onChange={handleChange}
                      />
                      Married
                    </label>
                  </div>
                </div>
              </div>
            </div>

            <div className="form-rows">
              <div>
                <label htmlFor="work_phone_number">Office Mobile Number</label>
                <input
                  type="text"
                  name="work_phone_number"
                  id="work_phone_number"
                  placeholder="Enter Employee Official Mobile Number"
                  onChange={handleChange}
                />
              </div>
              <div>
                <label htmlFor="personal_phone_number">
                  Personal Mobile Number
                </label>
                <input
                  type="text"
                  name="personal_phone_number"
                  id="personal_phone_number"
                  placeholder="Enter Employee Personal Mobile Number"
                  value={
                    formData.personal_phone_number ||
                    employee.personal_phone_number
                  }
                  onChange={handleChange}
                />
              </div>
            </div>
            <div className="form-rows">
              <div>
                <label htmlFor="emergency_contact_name">
                  Emergency Contact Person
                </label>
                <input
                  type="text"
                  name="emergency_contact_name"
                  id="emergency_contact_name"
                  placeholder="Enter Employee Emergency Contact Person Name"
                  value={
                    formData.emergency_contact_name ||
                    employee.emergency_contact_name ||
                    "Nil"
                  }
                  onChange={handleChange}
                />
              </div>
              <div>
                <label htmlFor="emergency_contact_relation">
                  Relation with Employee
                </label>
                <input
                  type="text"
                  name="emergency_contact_relation"
                  id="emergency_contact_relation"
                  placeholder="Enter Employee Emergency Contact Number"
                  value={
                    formData.emergency_contact_relation ||
                    employee.emergency_contact_relation ||
                    "Nil"
                  }
                  onChange={handleChange}
                />
              </div>
            </div>

            <div className="form-rows">
              <div>
                <label htmlFor="emergency_contact_number">
                  Emergency Contact Number
                </label>
                <input
                  type="text"
                  name="emergency_contact_number"
                  id="emergency_contact_number"
                  placeholder="Enter Employee Emergency Contact Number"
                  value={
                    formData.emergency_contact_number ||
                    employee.emergency_contact_number ||
                    "Nil"
                  }
                  onChange={handleChange}
                />
              </div>
              <div>
                <label htmlFor="emergency_contact_email">
                  Emergency Contact Email
                </label>
                <input
                  type="text"
                  name="emergency_contact_email"
                  id="emergency_contact_email"
                  placeholder="Enter Employee Emergency Contact Person Name"
                  value={
                    formData.emergency_contact_email ||
                    employee.emergency_contact_email ||
                    ""
                  }
                  onChange={handleChange}
                />
              </div>
            </div>

            <div className="form-rows">
              <div>
                <label htmlFor="nationality">Nationality</label>
                <input
                  name="nationality"
                  type="text"
                  placeholder="Choose your Nationality"
                  value={formData.nationality || employee.nationality}
                  onChange={handleChange}
                />
              </div>
              <div></div>
            </div>

            {/* Language Section */}

            {languages.map((language, index) => (
              <div key={index} className="form-rows">
                <div>
                  <label>Language Known</label>
                  <input
                    type="text"
                    name={`language[${index}].language_name`}
                    value={language.language_name || ""}
                    placeholder="Language Known"
                    onChange={(e) =>
                      handleLanguageChange(
                        `language_name`,
                        e.target.value,
                        index
                      )
                    }
                  />
                </div>
                <div className="language_skills">
                  <div className="ability">
                    <label>
                      <input
                        type="checkbox"
                        name={`language[${index}].read`}
                        checked={language.read}
                        onChange={() => handleCheckboxChange(index, "read")}
                      />
                      Read
                    </label>
                  </div>
                  <div className="ability">
                    <label>
                      <input
                        type="checkbox"
                        name={`language[${index}].write`}
                        checked={language.write}
                        onChange={() => handleCheckboxChange(index, "write")}
                      />
                      Write
                    </label>
                  </div>
                  <div className="ability">
                    <label>
                      <input
                        type="checkbox"
                        name={`language[${index}].speak`}
                        checked={language.speak}
                        onChange={() => handleCheckboxChange(index, "speak")}
                      />
                      Speak
                    </label>
                  </div>
                  <div className="button_delete">
                    <button
                      type="button"
                      onClick={() => handleRemoveLanguage(index)}
                    >
                      X
                    </button>
                  </div>
                </div>
              </div>
            ))}

            <div>
              <button
                type="button"
                className="formik-addMore-btn"
                onClick={handleAddLanguage}
              >
                + Add More Languages
              </button>
            </div>
          </div>

          <div className="form-btn-div">
            <button
              type="button"
              className="btn-skip"
              onClick={() => navigate(`/employeedirectory/${id}`)}
            >
              Close
            </button>
            <button type="submit" className="form-btn-submit">
              Update
            </button>
          </div>
        </form>
      ))}
      <Modal
        isOpen={showSuccessModal}
        onRequestClose={handleCloseModal}
        contentLabel="Data Updated Successfully"
        className="react-modal-content"
        overlayClassName="react-modal-overlay"
      >
        <h5>Details Updated Successfully</h5>
        <button onClick={handleCloseModal}>OK</button>
      </Modal>
    </div>
  );
};

export default BasicInformationUpdate;
