import { useState } from "react";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import axios from "axios";
import Modal from "react-modal";
Modal.setAppElement("#root");

const EmployeeDocumentsUpdate = () => {
  const accessToken = localStorage.getItem("accessToken");
  const { id } = useParams();
  const location = useLocation();
  const DocumentsDataToUpdate = location.state?.DocumentsDataToUpdate;
  const documentName = location.state?.documentName;
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const navigate = useNavigate();

  const handleCloseModal = () => {
    setShowSuccessModal(false);
    navigate(`/employeedirectory/${id}`);
  };

  const filteredDocuments = DocumentsDataToUpdate[0]?.users?.documents?.filter(
    (doc) => doc.name === documentName
  );

  const documentsDetailsArray = filteredDocuments.map((doc) => ({
    id: doc.id,
    name: doc.name,
    number: doc.number,
    url: doc.url,
    expiryDate: doc.expiryDate,
    country_of_issue: doc.country_of_issue,
  }));

  const [documentState, setDocumentState] = useState({
    name: documentsDetailsArray[0]?.name || "",
    id: documentsDetailsArray[0]?.id || "",
    number: documentsDetailsArray[0]?.number || "",
    url: documentsDetailsArray[0]?.url || "",
    expiryDate: documentsDetailsArray[0]?.expiryDate || "",
    country_of_issue: documentsDetailsArray[0]?.country_of_issue || "",
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setDocumentState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleUpdateDocument = () => {
    const apiUrl = `${process.env.REACT_APP_BASE_URL}/employees/documents/update`;

    const fileInput = document.querySelector('input[type="file"]');
    const newFile = fileInput.files[0];

    const formData = new FormData();

    if (!newFile && documentState.url) {
      formData.append("documents[0][document_url]", documentState.url);
    }

    if (newFile) {
      formData.append("documents[0][file]", newFile, newFile.name);
    }

    formData.append("id", id);
    formData.append("documents[0][id]", documentState.id);
    formData.append("documents[0][name]", documentState.name);
    formData.append("documents[0][expiry_date]", documentState.expiryDate);
    formData.append("documents[0][document_number]", documentState.number);
    formData.append(
      "documents[0][country_of_issue]",
      documentState.country_of_issue
    );

    const headers = {
      Accept: "application/json",
      Authorization: `Bearer ${accessToken}`,
    };

    axios
      .post(apiUrl, formData, { headers })
      .then((response) => {
        setShowSuccessModal(true);
      })
      .catch((error) => console.error("Error:", error));
  };

  return (
    <div className="board__layout">
      <div className="section-header">
        <h1>{`Update ${documentName}`}</h1>
      </div>

      <div className="form-item">
        <div className="form-rows">
          <div>
            <label htmlFor="file">{`Upload ${documentName}`}</label>
            <input name="file" className="custom-file-upload" type="file" />
          </div>
          {documentName !== "profilePicture" && documentName !== "Resume" ? (
            <div>
              <label htmlFor="document_number">{`${documentName} Number`}</label>
              <input
                type="text"
                name="document_number"
                placeholder="Document Number"
                value={documentState.number}
                onChange={(e) =>
                  setDocumentState({ ...documentState, number: e.target.value })
                }
              />
            </div>
          ) : (
            <div></div>
          )}
        </div>
        <div className="form-rows">
          {documentName !== "profilePicture" ? (
            <div>
              <label htmlFor="expiry_date">Expiry Date</label>
              <input
                type="date"
                name="expiry_date"
                placeholder="Expiry Date"
                value={documentState.expiryDate}
                onChange={(e) =>
                  setDocumentState({
                    ...documentState,
                    expiryDate: e.target.value,
                  })
                }
              />
            </div>
          ) : (
            <div></div>
          )}
          {documentName === "Passport" ? (
            <div>
              <label htmlFor="country_of_issue">Country of Issue</label>
              <input
                type="text"
                name="country_of_issue"
                placeholder="Passport issued Country"
                value={documentState.country_of_issue}
                onChange={(e) =>
                  setDocumentState({
                    ...documentState,
                    country_of_issue: e.target.value,
                  })
                }
              />
            </div>
          ) : (
            <div></div>
          )}
        </div>

        <div style={{ display: "none" }}>
          <label htmlFor="employee_info_id">Employee Information ID</label>
          <input type="number" name="employee_info_id" id="employee_info_id" />
          <label htmlFor="document_id">Document ID</label>
          <input
            type="number"
            name="document_id"
            id="document_id"
            value={documentState.id}
            onChange={(e) =>
              setDocumentState({ ...documentState, id: e.target.value })
            }
          />
        </div>
      </div>

      <div className="form-btn-div" style={{ marginTop: "1rem" }}>
        <button
          type="button"
          className="btn-skip"
          onClick={() => navigate(`/employeedirectory/${id}`)}
        >
          Close
        </button>
        <button onClick={handleUpdateDocument} className="form-btn-submit">
          Update
        </button>
      </div>

      <Modal
        isOpen={showSuccessModal}
        onRequestClose={() => setShowSuccessModal(false)}
        contentLabel="Data Updated Successfully"
        className="react-modal-content"
        overlayClassName="react-modal-overlay"
      >
        <h5>Details Updated Successfully</h5>
        <button onClick={handleCloseModal}>OK</button>
      </Modal>
    </div>
  );
};

export default EmployeeDocumentsUpdate;
