export function capitalizeWords(input) {
    if (typeof input !== 'string') {
      return input;
    }
    
    return input
      .split(" ")
      .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(" ");
  }
  