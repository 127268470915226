import "../../styles/dashboard.css";
import { useState } from "react";
import { useLocation } from "react-router-dom";
import { capitalizeWords } from "./capitalizeWords";
import formatDate from "./dateUtils";

const LateLoginsDetails = () => {
  const location = useLocation();
  const { lateLoginData, selectedCompany } = location.state;

  const punchingTimeStr = "1970-01-01T10:15:00";

  const punchingTime = new Date(punchingTimeStr);

  const [sortColumn, setSortColumn] = useState("employee_id");
  const [sortOrder, setSortOrder] = useState("asc");

  const toggleSortOrder = (column) => {
    if (sortColumn === column) {
      setSortOrder(sortOrder === "asc" ? "desc" : "asc");
    } else {
      setSortColumn(column);
      setSortOrder("asc");
    }
  };

  // Sorting function
  const sortData = (data) => {
    return data.sort((a, b) => {
      const valueA = a[sortColumn];
      const valueB = b[sortColumn];
      if (sortOrder === "asc") {
        return valueA.localeCompare(valueB);
      } else {
        return valueB.localeCompare(valueA);
      }
    });
  };

  const sortedData = sortData([...lateLoginData]);

  return (
    <div className="board__layout">
      <div className="section-header">
        <h1>Late Login Employees Details</h1>
        <p>{selectedCompany}</p>
      </div>
      <div>
        <table>
          <thead>
            <tr>
              <th>SL No.</th>
              <th onClick={() => toggleSortOrder("employee_id")}>
                Employee ID
                {sortColumn === "employee_id" && (
                  <span>{sortOrder === "asc" ? "↑" : "↓"}</span>
                )}
              </th>
              <th onClick={() => toggleSortOrder("name")}>
                Employee Details
                {sortColumn === "name" && (
                  <span>{sortOrder === "asc" ? "↑" : "↓"}</span>
                )}
              </th>
              <th>Date</th>
              <th>Login Time</th>
              <th>Logout Time</th>
              <th>Late Hours</th>
            </tr>
          </thead>
          <tbody>
            {sortedData.length > 0 ? (
              sortedData.map((employee, index) => {
                const loginTimeParts = employee.login_time.split(":");

                if (loginTimeParts.length === 3) {
                  const loginTime = new Date(
                    1970,
                    0,
                    1,
                    loginTimeParts[0],
                    loginTimeParts[1],
                    loginTimeParts[2]
                  );
                  if (!isNaN(loginTime)) {
                    const timeDifference = loginTime - punchingTime;

                    const hours = Math.floor(timeDifference / (60 * 60 * 1000));
                    const minutes = Math.floor(
                      (timeDifference % (60 * 60 * 1000)) / (60 * 1000)
                    );

                    return (
                      <tr key={index}>
                        <td>{index + 1}</td>
                        <td>{employee.employee_id}</td>
                        <td>
                          <div className="leave_empName">
                            {capitalizeWords(employee?.name)}
                          </div>
                          <div className="leave_designation">
                            {capitalizeWords(employee?.position)}
                          </div>
                        </td>
                        <td>{formatDate(employee.date)}</td>
                        <td>{employee.login_time}</td>
                        <td>{employee.logout_time}</td>
                        <td>{`${hours} : ${minutes} : 00`}</td>
                      </tr>
                    );
                  }
                }

                return (
                  <tr key={index}>
                    <td>{index + 1}</td>
                    <td>{employee.employee_id}</td>
                    <td>
                      <div className="leave_empName">
                        {capitalizeWords(employee?.name)}
                      </div>
                      <div className="leave_designation">
                        {capitalizeWords(employee?.position)}
                      </div>
                    </td>
                    <td>{formatDate(employee.date)}</td>
                    <td>{employee.login_time}</td>
                    <td>{employee.logout_time}</td>
                    <td>Unable to calculate</td>
                  </tr>
                );
              })
            ) : (
              <tr>
                <td colSpan="6">No data found.</td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default LateLoginsDetails;
