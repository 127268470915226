import "../../styles/employeeDirectory.css";
import { useState, useEffect, useRef } from "react";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import { useReactToPrint } from "react-to-print";
import formatDate from "./dateUtils";
import GoogleMapComponent from "./GoogleMapComponent";
import ResigDocsPopup from "./ResigDocsPopup";
import EmployeeProgressBar from "../MultiStep/ProgressBar";
import formatDateTime from "./dateConverter";
import AccessDenied from "./AccessDenied";

const ViewEmployeeInfo = () => {
  const permissions = JSON.parse(localStorage.getItem("userPermissions"));
  const accessToken = localStorage.getItem("accessToken");
  const { id } = useParams();
  const navigate = useNavigate();
  const componentPDF = useRef();
  const [employeeData, setEmployeeData] = useState([]);
  const [showAccessDeniedPopup, setShowAccessDeniedPopup] = useState(false);
  const [showResigDocPopup, setShowResigDocPopup] = useState(false);
  const [selectedDocumentUrl, setSelectedDocumentUrl] = useState(null);
  const [buttonEnable, setButtonEnable] = useState(true);

  const fetchEmployeeData = async (id) => {
    try {
      const timestamp = new Date().getTime();
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/employees/employeeInformation/${id}?timestamp=${timestamp}`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      const data = response.data?.data;
      setEmployeeData(data);
    } catch (error) {
      console.log(error);
    }
  };

  console.log("View Employee Info", employeeData);

  useEffect(() => {
    fetchEmployeeData(id);
  }, [id]);

  const generatePDF = useReactToPrint({
    content: () => componentPDF.current,
    documentTitle: "Employee Data",
  });

  const handleOpenDocumentPopup = (documentUrl) => {
    setSelectedDocumentUrl(documentUrl);
    if (documentUrl?.startsWith("https")) {
      setShowResigDocPopup(true);
    } else {
      alert("No File to View/Download");
    }
  };

  const handleDownalodDocument = (documentUrl, documentName) => {
    const link = document.createElement("a");
    link.href = documentUrl;
    link.download = documentName;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const handleEdit = (id) => {
    const hasEditPermission = permissions.some(
      (permission) => permission.name === "update_employee_info"
    );
    if (!hasEditPermission) {
      setShowAccessDeniedPopup(true);
      return;
    }

    navigate(`/employeedirectory/${id}`);
  };

  return (
    <>
      {employeeData.map((employee) => (
        <div className="board__layout" key={employee.id}>
          <div className="section-header">
            <div className="main__titles">
              <button onClick={() => navigate("/empmanagement")}>
                <span>
                  <i className="ri-arrow-left-s-line"></i>
                </span>
                BACK
              </button>
            </div>
            <button onClick={generatePDF} className="print_button">
              <i className="fa-solid fa-print"></i>
            </button>
          </div>
          <div
            id="employee-details"
            className="employee_directory-container"
            ref={componentPDF}
            style={{ width: "100%" }}
          >
            <div className="employee_info">
              <div className="first_section">
                <div className="profile_picture">
                  <img
                    src={`${
                      employee?.users?.profile_picture
                    }?timestamp=${Date.now()}`}
                    alt=""
                  />
                </div>
                <div className="employee_title">
                  <h3>{employee?.employee_full_name}</h3>
                  <p>{employee?.users?.roles[0]?.role_name}</p>
                </div>
              </div>

              <div className="employee_updated-time">
                <h3>Profile Last Updated</h3>
                <p>{formatDateTime(employee?.updated_at)}</p>
              </div>

              <div className="employee_ids">
                <p>
                  Employee ID: <span>{employee?.users?.employee_id}</span>
                </p>
                <p>
                  Official Mail ID:{" "}
                  <span>{employee?.users?.official_email}</span>
                </p>
              </div>
            </div>
            <div style={{ margin: "2rem" }}>
              <EmployeeProgressBar employeeData={employeeData} />
            </div>

            <div className="employee-directory basic_information">
              <div className="bi_title">
                <h4>Basic Details</h4>
              </div>
              <div className="detail">
                <div className="items">
                  <div className="item">
                    <p>Date of Birth</p>
                    <h6>{formatDate(employee.dob)}</h6>
                  </div>
                  <div className="item">
                    <p>Personal Email Id</p>
                    <h6>{employee.personal_mail_id}</h6>
                  </div>
                </div>
                <div className="items">
                  <div className="item">
                    <p>Gender</p>
                    <h6>{employee.gender}</h6>
                  </div>
                  <div className="item">
                    <p>Marital Status</p>
                    <h6>{employee.marital_status ? "Married" : "Single"}</h6>
                  </div>
                </div>
                <div className="items">
                  <div className="item">
                    <p>Official Mobile Number</p>
                    <h6>
                      {employee.work_phone_number ? (
                        <h6>{employee.work_phone_number}</h6>
                      ) : (
                        "Nil"
                      )}
                    </h6>
                  </div>
                  <div className="item">
                    <p>Personal Mobile Number</p>
                    <h6>{employee.personal_phone_number}</h6>
                  </div>
                </div>
                <div className="items">
                  <div className="item">
                    <p>Emergency Contact Person</p>
                    <h6>
                      {employee.emergency_contact_name ? (
                        <h6>{employee.emergency_contact_name}</h6>
                      ) : (
                        "Nil"
                      )}
                    </h6>
                  </div>
                  <div className="item">
                    <p>Relation with Employee</p>
                    <h6>
                      {employee.emergency_contact_relation ? (
                        <h6>{employee.emergency_contact_relation}</h6>
                      ) : (
                        "Nil"
                      )}
                    </h6>
                  </div>
                </div>
                <div className="items">
                  <div className="item">
                    <p>Emergency Contact Number</p>
                    <h6>
                      {employee.emergency_contact_number ? (
                        <h6>{employee.emergency_contact_number}</h6>
                      ) : (
                        "Nil"
                      )}
                    </h6>
                  </div>
                  <div className="item">
                    <p>Emergency Contact Email</p>
                    <h6>
                      {employee.emergency_contact_email ? (
                        <h6>{employee.emergency_contact_email}</h6>
                      ) : (
                        "Nil"
                      )}
                    </h6>
                  </div>
                </div>
                <div className="items">
                  <div className="item">
                    <p>Nationality</p>
                    <h6>{employee.nationality}</h6>
                  </div>
                </div>
              </div>
              {employee.languages.length > 0 && (
                <div className="languages">
                  <div className="language">
                    <p>Language</p>
                    <p>Speak</p>
                    <p>Read</p>
                    <p>Write</p>
                  </div>
                  <div>
                    {employee.languages.map((language) => (
                      <div className="language_display">
                        <p className="align_width">{language.language_name}</p>
                        <input
                          type="checkbox"
                          checked={language.speak}
                          className="language_check"
                          disabled
                        />
                        <input
                          type="checkbox"
                          checked={language.read}
                          className="language_check"
                          disabled
                        />
                        <input
                          type="checkbox"
                          checked={language.write}
                          className="language_check"
                          disabled
                        />
                      </div>
                    ))}
                  </div>
                </div>
              )}
            </div>

            <div className="employee-directory basic_information">
              <div className="bi_title">
                <h4>Address Details</h4>
              </div>
              <div className="detail">
                <div className="items">
                  {employee.address.map((add) => (
                    <div className="item" key={add.id}>
                      <p>
                        {add.is_permanent
                          ? "Permanent Address"
                          : "Current Address"}
                      </p>
                      <h6>
                        {`${add.street_address}`} <br />
                        {`${add.state}`} <br />
                        {`${add.country}`}
                      </h6>
                    </div>
                  ))}
                </div>
              </div>
              <div className="detail">
                <div className="items">
                  {employee.address.map((add) => (
                    <div className="item" key={add.id}>
                      <GoogleMapComponent address={add.latitude} />
                    </div>
                  ))}
                </div>
              </div>
            </div>

            <div className="employee-directory basic_information">
              <div className="bi_title">
                <h4>Joining Details</h4>
              </div>
              <div className="detail">
                <div className="items">
                  <div className="item">
                    <p>Company Name</p>
                    <h6>{employee.users.roles[0].company[0].company_name}</h6>
                  </div>
                  <div className="item">
                    <p>Department</p>
                    <h6>{employee.users.roles[0].department_name}</h6>
                  </div>
                </div>
                <div className="items">
                  <div className="item">
                    <p>Joining Date</p>
                    <h6>{formatDate(employee.joining_date)}</h6>
                  </div>
                  <div className="item">
                    <p>Probation End Date</p>
                    <h6>{formatDate(employee.probation_end_date)}</h6>
                  </div>
                </div>
                <div className="items">
                  <div className="item">
                    <p>Reporting Manager</p>
                    <h6>
                      {employee.reportingManager?.first_name}{" "}
                      {employee.reportingManager?.last_name}
                    </h6>
                  </div>
                  <div className="item">
                    <p>Designation</p>
                    <h6>{employee.users.roles[0]?.role_name}</h6>
                  </div>
                </div>
                <div className="items">
                  <div className="item">
                    <p>Access Card Expiry Date</p>
                    <h6>
                      {employee.access_card_expiry_date
                        ? formatDate(employee.access_card_expiry_date)
                        : "Nil"}
                    </h6>
                  </div>
                  <div className="item">
                    <p>Work Hours</p>
                    <h6>{employee.work_hours} Hrs/ Day</h6>
                  </div>
                </div>
              </div>
            </div>

            <div className="employee-directory basic_information">
              <div className="bi_title">
                <h4>Qualification Details</h4>
              </div>
              {employee.qualifications.map((qualification) => (
                <div
                  className="detail"
                  key={qualification.id}
                  style={{ marginBottom: "2rem" }}
                >
                  <div className="items">
                    <div className="item">
                      <p>Education</p>
                      <h6 style={{ color: "black", fontWeight: "bolder" }}>
                        {qualification.education_level_name}
                      </h6>
                    </div>
                    <div className="item">
                      <p>Field of Study</p>
                      <h6>{qualification.field_of_study_name}</h6>
                    </div>
                  </div>
                  <div className="items">
                    <div className="item">
                      <p>Name of University</p>
                      <h6>{qualification.university_name}</h6>
                    </div>
                    <div className="item">
                      <p>Country</p>
                      <h6>{qualification.country}</h6>
                    </div>
                  </div>
                  <div className="items">
                    <div className="item">
                      <p>Major of Study</p>
                      <h6>{qualification.major_of_study_name}</h6>
                    </div>
                    <div className="item">
                      <p>Year of Passout</p>
                      <h6>{formatDate(qualification.passout_year)}</h6>
                    </div>
                  </div>
                  <br />
                </div>
              ))}
            </div>

            <div className="employee-directory basic_information">
              <div className="bi_title">
                <h4>Experience Details</h4>
              </div>
              {employee.experiences.map((experience) => (
                <div
                  className="detail"
                  key={experience.id}
                  style={{ marginBottom: "2rem" }}
                >
                  <div key={experience.id}>
                    <div className="items">
                      <div className="item">
                        <p>Previous Company Name</p>
                        <h6 style={{ color: "black", fontWeight: "bolder" }}>
                          {experience?.previous_worked_firm}
                        </h6>
                      </div>
                      <div className="item">
                        <p>Previous Company Address</p>
                        <h6>{experience?.organization_address}</h6>
                      </div>
                    </div>
                    <div className="items">
                      <div className="item">
                        <p>City</p>
                        <h6>{experience?.organization_city}</h6>
                      </div>
                      <div className="item">
                        <p>Country</p>
                        <h6>{experience?.organization_country}</h6>
                      </div>
                    </div>
                    <div className="items">
                      <div className="item">
                        <p>Previous Designation</p>
                        <h6>{experience?.designation}</h6>
                      </div>
                      <div className="item">
                        <p>Joining Date</p>
                        <h6>{formatDate(experience?.date_of_joining)}</h6>
                      </div>
                    </div>
                    <div className="items">
                      <div className="item">
                        <p>Relieving Date</p>
                        <h6>{formatDate(experience?.relieving_date)}</h6>
                      </div>
                      <div className="item">
                        <p>Total Experience</p>
                        <h6>{`${experience?.experience_in_months} Months`}</h6>
                      </div>
                    </div>
                  </div>

                  {experience.references.map((reference) => (
                    <>
                      <div className="items">
                        <div className="item">
                          <p>Reference Person</p>
                          <h6>{reference?.reference_person}</h6>
                        </div>
                        <div className="item">
                          <p>Ref. Designation</p>
                          <h6>{reference?.reference_designation}</h6>
                        </div>
                      </div>
                      <div className="items">
                        <div className="item">
                          <p>Ref. Phone Number</p>
                          <h6>{reference?.reference_mobile_no}</h6>
                        </div>
                        <div className="item">
                          <p>Email Id</p>
                          <h6>{reference?.reference_email}</h6>
                        </div>
                      </div>
                    </>
                  ))}
                </div>
              ))}
            </div>

            <div className="employee-directory basic_information">
              <div className="bi_title">
                <h4>Documents</h4>
              </div>
              {employee.documents.map((document) => {
                const url = document.url;
                const filename = url.split("/").pop();
                return (
                  <div
                    className="detail"
                    key={document.id}
                    style={{ marginBottom: "2rem" }}
                  >
                    <div className="items">
                      <div className="item">
                        <p>Document Name</p>
                        <h6 style={{ color: "black", fontWeight: "bolder" }}>
                          {document.name === "profilePicture"
                            ? "Profile Picture"
                            : document.name === "passport"
                            ? "Passport"
                            : document.name === "EID"
                            ? "Emirates ID"
                            : document.name}
                        </h6>
                      </div>
                      <div className="item">
                        <p>
                          {document.name === "profilePicture"
                            ? "Profile Picture"
                            : document.name === "passport"
                            ? "Passport"
                            : document.name === "EID"
                            ? "Emirates ID"
                            : document.name}
                        </p>
                        <button
                          className="doc_download-btn"
                          onClick={() => handleOpenDocumentPopup(document.url)}
                        >
                          {document.url.startsWith("https")
                            ? "View / Download"
                            : "No File"}
                        </button>
                      </div>
                    </div>
                    {document.number !== "" && (
                      <div className="items">
                        {document.number && (
                          <div className="item">
                            <p>
                              {` ${
                                document.name === "profilePicture"
                                  ? "Profile Picture"
                                  : document.name === "passport"
                                  ? "Passport"
                                  : document.name === "EID"
                                  ? "Emirates ID"
                                  : document.name
                              } 
                                Number`}
                            </p>
                            <h6>{document.number}</h6>
                          </div>
                        )}
                        {document.expiryDate && (
                          <div className="item">
                            <p>Expiry Date</p>
                            <h6>{formatDate(document.expiryDate)}</h6>
                          </div>
                        )}
                        {document.country_of_issue && (
                          <div className="item" style={{ marginTop: "1rem" }}>
                            <p>Country of Issue</p>
                            <h6>{document.country_of_issue}</h6>
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                );
              })}
            </div>
            {showResigDocPopup && (
              <ResigDocsPopup
                documentUrl={selectedDocumentUrl}
                onClose={() => setShowResigDocPopup(false)}
              />
            )}
            {showAccessDeniedPopup && (
              <div className="em_popup-overlay">
                <div className="em_popup-content">
                  <AccessDenied
                    setShowAccessDeniedPopup={setShowAccessDeniedPopup}
                  />
                </div>
              </div>
            )}
            <div className="view_employee">
              <button className="update_btn" onClick={() => handleEdit(id)}>
                Edit
              </button>
            </div>
          </div>
        </div>
      ))}
    </>
  );
};

export default ViewEmployeeInfo;
