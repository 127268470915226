const InstanceCard = (props) => {
  const { iconImg, iconBgColor, title, count, days } = props.item;

  return (
    <div className="instance_card-container">
      <div className="instance_card-left">
        <div className={`instance_card-icon ${iconBgColor}`}>
          <img src={iconImg} alt="" />
        </div>
        <div className="instance_card-title">
          <h5>{title}</h5>
          <h2>{count}</h2>
        </div>
      </div>
      <div className="instance_card-right">
        <div className="instance_card-days">
          <p>{days}</p>
        </div>
      </div>
    </div>
  );
};

export default InstanceCard;
