import React, { useEffect, useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import ProgressBar from "react-bootstrap/ProgressBar";

const EmployeeProgressBar = ({ employeeData }) => {
  const [progressBarData, setProgressBarData] = useState({
    totalValues: 0,
    nullValues: 0,
    nonNullValues: 0,
    percentage: 0,
  });

  useEffect(() => {
    const omitFields = [
      "0.visa_cancel_date",
      "0.country_exit_date",
      "0.resignation_apply_date",
      "0.resignation_date",
      "0.last_working_date",
      "0.users.access_card_expiry_date",
      "0.access_card_expiry_date",
      "0.resign_remarks",
      "0.users.documents[0].is_approved",
      "0.users.documents[1].is_approved",
      "0.users.documents[2].is_approved",
      "0.users.documents[3].number",
      "0.users.documents[3].expiryDate",
      "0.users.documents[3].is_approved",
      "0.users.documents[4].number",
      "0.users.documents[4].expiryDate",
      "0.users.documents[4].is_approved",
      "0.users.documents[5].is_approved",
      "0.address[0].is_approved",
      "0.address[1].is_approved",
      "0.qualifications[0].skills",
      "0.qualifications[1].skills",
      "0.qualifications[2].skills",
      "0.documents[0].is_approved",
      "0.documents[1].is_approved",
      "0.documents[2].is_approved",
      "0.documents[3].number",
      "0.documents[3].expiryDate",
      "0.documents[3].is_approved",
      "0.documents[4].number",
      "0.documents[4].expiryDate",
      "0.documents[4].is_approved",
      "0.documents[5].is_approved",
    ];

    const calculateProgressBar = (data) => {
      let totalValues = 0;
      let nullValues = 0;
      let emptyFields = [];

      const traverse = (obj, path = "") => {
        for (const key in obj) {
          if (obj.hasOwnProperty(key)) {
            const fullPath = path ? `${path}.${key}` : key;

            if (!omitFields.includes(fullPath)) {
              const value = obj[key];

              if (value === null) {
                nullValues++;
                emptyFields.push(fullPath);
              } else if (Array.isArray(value)) {
                totalValues++;
                if (value.length === 0) {
                  nullValues++;
                  emptyFields.push(fullPath);
                } else {
                  value.forEach((item, index) => {
                    traverse(item, `${fullPath}[${index}]`);
                  });
                }
              } else if (typeof value === "object") {
                totalValues++;
                traverse(value, fullPath);
              } else {
                totalValues++;
              }
            }
          }
        }
      };

      traverse(data);

      const nonNullValues = totalValues - nullValues;
      const percentage = (nonNullValues / totalValues) * 100;

      console.log("Empty fields:", emptyFields);

      return { totalValues, nullValues, nonNullValues, percentage };
    };

    const result = calculateProgressBar(employeeData);
    setProgressBarData(result);
  }, [employeeData]);

  return (
    <ProgressBar
      now={progressBarData.percentage}
      label={`${progressBarData.percentage.toFixed(2)}%`}
    />
  );
};

export default EmployeeProgressBar;
