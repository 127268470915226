import "../styles/exitManagement.css";
import "../styles/payroll.css";
import { useState, useCallback, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import SearchTable from "../components/Reusable/SearchTable";
import ExitManagementTable from "../components/Reusable/ExitManagementTable";

const ExitManagement = () => {
  const navigate = useNavigate();
  const accessToken = localStorage.getItem("accessToken");
  const [selectedCompany, setSelectedCompany] = useState("");
  const [selectedDepartment, setSelectedDepartment] = useState("");
  const [companyList, setCompanyList] = useState([]);
  const [departmentList, setDepartmentList] = useState([]);
  const [employeeExitData, setEmployeeExitData] = useState([]);

  const getCompany = () => {
    axios
      .get(`${process.env.REACT_APP_BASE_URL}/companies`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      })
      .then((response) => {
        const data = response.data?.data || [];
        setCompanyList(data);
      })
      .catch((error) => console.log(error));
  };

  const getDepartments = useCallback(
    (company = selectedCompany) => {
      return axios
        .get(
          `${process.env.REACT_APP_BASE_URL}/departments?company=${company}`,
          {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          }
        )
        .then((response) => {
          const data = response.data?.data || [];
          setDepartmentList(data);
          return data;
        })
        .catch((error) => console.log(error));
    },
    [selectedCompany]
  );

  useEffect(() => {
    getCompany();
  }, []);

  useEffect(() => {
    if (selectedCompany) {
      getDepartments(selectedCompany);
    }
  }, [selectedCompany, getDepartments]);

  const handleCompanyChange = (event) => {
    setSelectedCompany(event.target.value);
  };
  const handleDepartmentChange = (event) => {
    setSelectedDepartment(event.target.value);
  };

  const getEmployeeExitData = () => {
    axios
      .get(`${process.env.REACT_APP_BASE_URL}/employees/ExitEmployeeData?`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      })
      .then((response) => {
        const data = response.data?.data || [];
        setEmployeeExitData(data);
      })
      .catch((error) => console.log(error));
  };

  useEffect(() => {
    getEmployeeExitData();
  }, []);

  const handleSearch = () => {
    const apiUrl =
      selectedCompany && selectedDepartment
        ? `${process.env.REACT_APP_BASE_URL}/employees/ExitEmployeeData?company=${selectedCompany}&department=${selectedDepartment}`
        : `${process.env.REACT_APP_BASE_URL}/employees/ExitEmployeeData`;

    axios
      .get(apiUrl, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      })
      .then((response) => {
        const data = response.data?.data || [];
        setEmployeeExitData(data);
      })
      .catch((error) => console.log(error));
  };

  return (
    <div className="board__layout">
      <div className="section-header">
        <h1>Exit Management</h1>
        <button
          className="resignation-btn"
          onClick={() => navigate("/exitmanagementform")}
        >
          Add Resignation +
        </button>
      </div>

      <div className="exit__mgt-container">
        <div>
          <SearchTable
            selectedCompany={selectedCompany}
            selectedDepartment={selectedDepartment}
            companyList={companyList}
            departmentList={departmentList}
            handleCompanyChange={handleCompanyChange}
            handleDepartmentChange={handleDepartmentChange}
            handleSearch={handleSearch}
          />
        </div>
        <div style={{ marginTop: "10px" }}>
          <ExitManagementTable
            employeeExitData={employeeExitData}
            setEmployeeExitData={setEmployeeExitData}
          />
        </div>
      </div>
    </div>
  );
};

export default ExitManagement;
