import { Formik, Field, Form, ErrorMessage, FieldArray } from "formik";
import * as Yup from "yup";
import countryList from "react-select-country-list";
import { useState, useMemo } from "react";
import { BsArrowLeft, BsArrowRight } from "react-icons/bs";
import { fieldsetStyles, legendStyles } from "../Reusable/styles";

const AddressDetails = (props) => {
  console.log("AddressDetails", props);
  const countries = useMemo(() => countryList().getData(), []);
  const [isCurrentAddress, setIsCurrentAddress] = useState([]);

  const handleCheckboxChange = (index, values, setFieldValue) => {
    const updatedAddresses = values.address.map((address, i) => {
      if (i === index) {
        return {
          ...address,
          is_permanent: address.is_permanent === 1 ? 0 : 1,
        };
      } else {
        return address;
      }
    });
    setFieldValue("address", updatedAddresses);
  };

  const validationSchema = Yup.object().shape({
    address: Yup.array().of(
      Yup.object().shape({
        street_address: Yup.string().required("Street address is required"),
        state: Yup.string().required("State is required"),
        country: Yup.string().required("Country is required"),
        is_permanent: Yup.boolean().required("Required"),
      })
    ),
  });

  const handleSubmit = (values) => {
    props.next(values);
  };

  const handlePrevious = (values) => {
    props.onBack(values);
  };

  return (
    <div>
      <Formik
        initialValues={props.empData}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ values, setFieldValue }) => (
          <Form>
            <FieldArray name="address">
              {({ insert, remove, push }) => (
                <div>
                  {values.address.length > 0 &&
                    values.address.map((add, index) => (
                      <fieldset style={fieldsetStyles}>
                        <legend style={legendStyles}>{`Address Details ${
                          index + 1
                        }`}</legend>
                        <div className="form-rows">
                          <div>
                            <label htmlFor={`address.${index}.street_address`}>
                              Street Address
                            </label>
                            <Field
                              name={`address.${index}.street_address`}
                              placeholder="Street Address of Employee"
                              type="text"
                            />
                            <ErrorMessage
                              name={`address.${index}.street_address`}
                              component="div"
                              className="error-message"
                            />
                          </div>
                          <div>
                            <label htmlFor={`address.${index}.state`}>
                              State
                            </label>
                            <Field
                              name={`address.${index}.state`}
                              placeholder="State"
                              type="text"
                            />
                            <ErrorMessage
                              name={`address.${index}.state`}
                              component="div"
                              className="error-message"
                            />
                          </div>
                        </div>
                        <div className="form-rows">
                          <div>
                            <label htmlFor={`address.${index}.country`}>
                              Country
                            </label>
                            <Field
                              name={`address.${index}.country`}
                              as="select"
                            >
                              <option value="">Select Country</option>

                              {countries.map((country, index) => (
                                <option value={country.id} key={index}>
                                  {country.label}
                                </option>
                              ))}
                            </Field>
                            <ErrorMessage
                              name={`address.${index}.country`}
                              component="div"
                              className="error-message"
                            />
                          </div>
                          <div style={{ marginTop: "2.5rem" }}>
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "space-between",
                              }}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  columnGap: "10px",
                                  flexWrap: "wrap",
                                }}
                              >
                                <Field
                                  type="checkbox"
                                  name={`address.${index}.is_permanent`}
                                  value="is_permanent"
                                  checked={!values.address[index].is_permanent}
                                  onChange={() =>
                                    handleCheckboxChange(
                                      index,
                                      values,
                                      setFieldValue
                                    )
                                  }
                                  disabled={isCurrentAddress.some(
                                    (value, i) => value && i !== index
                                  )}
                                />
                                <label style={{ marginTop: "8px" }}>
                                  Current Address
                                </label>
                              </div>
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  columnGap: "10px",
                                  flexWrap: "wrap",
                                }}
                              >
                                <Field
                                  type="checkbox"
                                  name={`address.${index}.is_permanent`}
                                  value="is_permanent"
                                  checked={values.address[index].is_permanent}
                                  onChange={() =>
                                    handleCheckboxChange(
                                      index,
                                      values,
                                      setFieldValue
                                    )
                                  }
                                  disabled={!values.address[index].is_permanent}
                                />
                                <label style={{ marginTop: "8px" }}>
                                  Permanent Address
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="col">
                          {index > 0 && (
                            <button
                              type="button"
                              className="formik-delete-btn"
                              onClick={() => remove(index)}
                            >
                              Delete
                            </button>
                          )}
                        </div>
                      </fieldset>
                    ))}
                  <button
                    type="button"
                    className="formik-addMore-btn"
                    onClick={() => {
                      const DEFAULT_ADDRESS = {
                        street_address: "",
                        state: "",
                        country: "",
                        is_permanent: 1,
                        is_approved: 1,
                        latitude: "",
                        longitude: "",
                      };
                      push(DEFAULT_ADDRESS);
                    }}
                  >
                    Add More Address +
                  </button>
                </div>
              )}
            </FieldArray>
            <div className="form-btn-div">
              <button
                onClick={handlePrevious}
                type="button"
                className="form-btn-previous"
              >
                <BsArrowLeft /> Previous
              </button>
              <button type="submit" className="form-btn-submit">
                Save & Continue <BsArrowRight />
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default AddressDetails;
