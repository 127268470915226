import "./navbar.css";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { IoNotifications, IoChatboxEllipsesSharp } from "react-icons/io5";
import { capitalizeWords } from "../Reusable/capitalizeWords";

const Navbar = ({ userData }) => {
  // useEffect(() => {
  //   console.log("Navbar - userData:", userData);
  // }, [userData]);

  const [showProfileMenu, setShowProfileMenu] = useState(false);
  const navigate = useNavigate();

  if (!userData || !userData.user || !Array.isArray(userData.user)) {
    return null;
  }

  const userArray = userData.user;
  const userImage = userArray.map((user) => user?.profile_picture);

  const handleProfileMenuClick = () => {
    setShowProfileMenu(!showProfileMenu);
  };

  const handleProfile = () => {
    navigate(`/employeedirectory/${userArray[0]?.employee_information_id}`);
    setShowProfileMenu(!showProfileMenu);
  };
  const handleViewProfile = () => {
    navigate(`/viewdirectory/${userArray[0]?.employee_information_id}`);
    setShowProfileMenu(!showProfileMenu);
  };

  const handleLogout = () => {
    localStorage.removeItem("accessToken");
    localStorage.removeItem("refreshToken");
    localStorage.removeItem("userermissions");
    localStorage.setItem("selectedCompany", " ");
    localStorage.clear();
    navigate("/");
  };

  const cacheBuster = Math.random();

  return (
    <div>
      <div>
        <nav>
          <div className="notification">
            <IoNotifications className="font-icon" />
            {/* <span className="badge">10</span> */}
          </div>
          <IoChatboxEllipsesSharp className="font-icon" />
          <img
            src={`${userImage}?cache=${cacheBuster}`}
            alt="user-pic"
            className="user-pic"
            onClick={handleProfileMenuClick}
          />
          {userArray.map((user, index) => (
            <div className="sub-menu-wrap" key={index}>
              {showProfileMenu && (
                <div className="sub-menu">
                  <div className="user-info">
                    <div>
                      <img
                        src={`${user?.profile_picture}?cache=${cacheBuster}`}
                        alt="user-pic"
                      />
                    </div>
                    <div
                      onClick={handleViewProfile}
                      style={{ cursor: "pointer" }}
                    >
                      <h4>{capitalizeWords(user?.employee_full_name)}</h4>
                      <p>{userData.role}</p>
                    </div>
                  </div>
                  <hr />
                  <div className="sub-menu-link" onClick={handleProfile}>
                    <div className="sub-menu-icon">
                      <i className="fa-solid fa-user"></i>
                    </div>
                    <p>Edit Profile</p>
                    <span>&gt;</span>
                  </div>
                  <div className="sub-menu-link">
                    <div className="sub-menu-icon">
                      <i className="fa-solid fa-gear"></i>
                    </div>
                    <p>Settings & Privacy</p>
                    <span>&gt;</span>
                  </div>
                  <div className="sub-menu-link">
                    <div className="sub-menu-icon">
                      <i className="fa-solid fa-circle-question" fa-2xl></i>
                    </div>
                    <p>Help & Support</p>
                    <span>&gt;</span>
                  </div>
                  <div className="sub-menu-link" onClick={handleLogout}>
                    <div className="sub-menu-icon">
                      <i className="fa-solid fa-right-from-bracket"></i>
                    </div>
                    <p>Logout</p>
                    <span>&gt;</span>
                  </div>
                </div>
              )}
            </div>
          ))}
        </nav>
      </div>
    </div>
  );
};

export default Navbar;
