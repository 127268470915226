import Employee from "../images/employee01.png";
import Emp6 from "../images/emp06.jpg";
import IDPhoto from "../images/employee-card01.png";

const employeeData = [
  {
    id: "1",
    empPicture: Emp6,
    empIdPhoto: IDPhoto,
    empName: "James Mathew",
    empCompany: "Scalex IT Solutions",
    empId: "5478",
    designation: "Software Developer",
    department: "IT",
    empPhone: "+971 568470250",
    empEmail: "empname@company.com",
    status: "Active",
    action: "...",
    date: "2023-05-22",
    resignationDate: "20-03-2023",
    exitDate: "30-03-2023",
    loginTime: "9.27 AM",
    logoutTime: "6.24 PM",
    details: "View More",
    payslipType: "Monthly",
    salary: 45000,
    createDate: "2023-05-22",
    salaryStatus: "Paid",
    leaveDay: "Today",
    statusIcon: "fa-solid fa-circle",
    leaveStatus: "pending",
    leaveStartDate: "10-03-2023",
    leaveEndDate: "12-03-2023",
    jobStartDate: "14-01-2020",
    jobEndDate: "25-03-2022",
    wpsSalary: 32000,
    wpsStatus: "Paid",
    commissionStatus: "Paid",
    reportingManager: "Mr. Raj Kumar",
  },
  {
    id: "2",
    empPicture: Employee,
    empIdPhoto: IDPhoto,
    empCompany: "The Thought Factory",
    empName: "Suhail Ameer",
    empId: "5090",
    department: "IT",
    designation: "Software Developer",
    empPhone: "+971 568470250",
    empEmail: "empname@company.com",
    status: "Active",
    action: "...",
    date: "2023-05-22",
    resignationDate: "20-03-2023",
    exitDate: "30-03-2023",
    loginTime: "9.27 AM",
    logoutTime: "6.24 PM",
    details: "View More",
    payslipType: "Monthly",
    salary: 45000,
    createDate: "2023-05-22",
    salaryStatus: "Paid",
    leaveDay: "Today",
    statusIcon: "fa-solid fa-circle",
    leaveStatus: "pending",
    leaveStartDate: "10-03-2023",
    leaveEndDate: "12-03-2023",
    jobStartDate: "14-01-2020",
    jobEndDate: "25-03-2022",
    wpsSalary: 32000,
    wpsStatus: "Paid",
    commissionStatus: "Paid",
    reportingManager: "Mr. Raj Kumar",
  },
  
  {
    id: "3",
    empPicture: Employee,
    empIdPhoto: IDPhoto,
    empName: "Sidharth Raj",
    empCompany: "Rhapsody",
    empId: "5478",
    department: "Sales",
    designation: "Software Developer",
    empPhone: "+971 568470250",
    empEmail: "empname@company.com",
    status: "InActive",
    action: "...",
    date: "2023-05-22",
    resignationDate: "20-03-2023",
    exitDate: "30-03-2023",
    loginTime: "9.27 AM",
    logoutTime: "6.24 PM",
    details: "View More",
    payslipType: "Monthly",
    salary: 45000,
    createDate: "2023-05-22",
    salaryStatus: "Paid",
    leaveDay: "Today",
    statusIcon: "fa-solid fa-circle",
    leaveStatus: "pending",
    leaveStartDate: "10-03-2023",
    leaveEndDate: "12-03-2023",
    jobStartDate: "14-01-2020",
    jobEndDate: "25-03-2022",
    wpsSalary: 32000,
    wpsStatus: "Paid",
    commissionStatus: "Paid",
    reportingManager: "Mr. Raj Kumar",
  },
  {
    id: "4",
    empPicture: Employee,
    empIdPhoto: IDPhoto,
    empName: "Roshan Abrahm",
    empCompany: "Scalex IT Solutions",
    empId: "4478",
    department: "HR",
    designation: "Software Developer",
    empPhone: "+971 568470250",
    empEmail: "empname@company.com",
    status: "Active",
    action: "...",
    date: "2023-05-22",
    resignationDate: "20-03-2023",
    exitDate: "30-03-2023",
    loginTime: "9.27 AM",
    logoutTime: "6.24 PM",
    details: "View More",
    payslipType: "Monthly",
    salary: 45000,
    createDate: "2023-05-22",
    salaryStatus: "Paid",
    leaveDay: "Today",
    statusIcon: "fa-solid fa-circle",
    leaveStatus: "pending",
    leaveStartDate: "10-03-2023",
    leaveEndDate: "12-03-2023",
    jobStartDate: "14-01-2020",
    jobEndDate: "25-03-2022",
    wpsSalary: 32000,
    wpsStatus: "Paid",
    commissionStatus: "Paid",
    reportingManager: "Mr. Raj Kumar",
  },
  {
    id: "5",
    empPicture: Employee,
    empIdPhoto: IDPhoto,
    empName: "Jilcy TJ",
    empCompany: "Rhapsody",
    department: "HR",
    empId: "3344",
    designation: "Software Developer",
    empPhone: "+971 568470250",
    empEmail: "empname@company.com",
    status: "Active",
    action: "...",
    date: "2023-05-22",
    resignationDate: "20-03-2023",
    exitDate: "30-03-2023",
    loginTime: "9.27 AM",
    logoutTime: "6.24 PM",
    details: "View More",
    payslipType: "Monthly",
    salary: 45000,
    createDate: "2023-05-22",
    salaryStatus: "Paid",
    leaveDay: "Today",
    statusIcon: "fa-solid fa-circle",
    leaveStatus: "pending",
    leaveStartDate: "10-03-2023",
    leaveEndDate: "12-03-2023",
    jobStartDate: "14-01-2020",
    jobEndDate: "25-03-2022",
    wpsSalary: 32000,
    wpsStatus: "Paid",
    commissionStatus: "Paid",
    reportingManager: "Mr. Raj Kumar",
  },
  {
    id: "6",
    empPicture: Employee,
    empIdPhoto: IDPhoto,
    empName: "Anvar Sadik",
    empCompany: "The Thought Factory",
    empId: "5478",
    department: "Sales",
    designation: "Software Developer",
    empPhone: "+971 568470250",
    empEmail: "empname@company.com",
    status: "Active",
    action: "...",
    date: "2023-05-22",
    resignationDate: "20-03-2023",
    exitDate: "30-03-2023",
    loginTime: "9.27 AM",
    logoutTime: "6.24 PM",
    details: "View More",
    payslipType: "Monthly",
    salary: 45000,
    createDate: "2023-05-22",
    salaryStatus: "Paid",
    leaveDay: "Today",
    statusIcon: "fa-solid fa-circle",
    leaveStatus: "pending",
    leaveStartDate: "10-03-2023",
    leaveEndDate: "12-03-2023",
    jobStartDate: "14-01-2020",
    jobEndDate: "25-03-2022",
    wpsSalary: 32000,
    wpsStatus: "Paid",
    commissionStatus: "Paid",
    reportingManager: "Mr. Raj Kumar",
  },
  {
    id: "7",
    empPicture: Employee,
    empIdPhoto: IDPhoto,
    empName: "Kishore Kumar",
    empId: "8378",
    department: "Sales",
    designation: "Software Developer",
    empPhone: "+971 568470250",
    empEmail: "empname@company.com",
    empCompany: "Scalex IT Solutions",
    status: "Active",
    action: "...",
    date: "2023-05-22",
    resignationDate: "20-03-2023",
    exitDate: "30-03-2023",
    loginTime: "9.27 AM",
    logoutTime: "6.24 PM",
    details: "View More",
    payslipType: "Monthly",
    salary: 45000,
    createDate: "14-01-2023",
    salaryStatus: "Paid",
    leaveDay: "Today",
    statusIcon: "fa-solid fa-circle",
    leaveStatus: "pending",
    leaveStartDate: "10-03-2023",
    leaveEndDate: "12-03-2023",
    jobStartDate: "14-01-2020",
    jobEndDate: "25-03-2022",
    wpsSalary: 32000,
    wpsStatus: "Paid",
    commissionStatus: "Paid",
    reportingManager: "Mr. Raj Kumar",
  },
  {
    id: "8",
    empPicture: Employee,
    empIdPhoto: IDPhoto,
    empName: "Reshma Jacob",
    empId: "5478",
    designation: "Software Developer",
    empCompany: "Rhapsody",
    department: "IT",
    empPhone: "+971 568470250",
    empEmail: "empname@company.com",
    status: "Active",
    action: "...",
    date: "14-01-2023",
    resignationDate: "20-03-2023",
    exitDate: "30-03-2023",
    loginTime: "9.27 AM",
    logoutTime: "6.24 PM",
    details: "View More",
    payslipType: "Monthly",
    salary: 45000,
    createDate: "14-01-2023",
    salaryStatus: "Paid",
    leaveDay: "Today",
    statusIcon: "fa-solid fa-circle",
    leaveStatus: "pending",
    leaveStartDate: "10-03-2023",
    leaveEndDate: "12-03-2023",
    jobStartDate: "14-01-2020",
    jobEndDate: "25-03-2022",
    wpsSalary: 32000,
    wpsStatus: "Paid",
    commissionStatus: "Paid",
    reportingManager: "Mr. Raj Kumar",
  },
  {
    id: "9",
    empPicture: Employee,
    empIdPhoto: IDPhoto,
    empName: "Aneesh Salam",
    empId: "5478",
    empCompany: "Rhapsody",
    designation: "Software Developer",
    department: "Sales",
    empPhone: "+971 568470250",
    empEmail: "empname@company.com",
    status: "Active",
    action: "...",
    date: "14-01-2023",
    resignationDate: "20-03-2023",
    exitDate: "30-03-2023",
    loginTime: "9.27 AM",
    logoutTime: "6.24 PM",
    details: "View More",
    payslipType: "Monthly",
    salary: 45000,
    createDate: "14-01-2023",
    salaryStatus: "Paid",
    leaveDay: "Today",
    statusIcon: "fa-solid fa-circle",
    leaveStatus: "pending",
    leaveStartDate: "10-03-2023",
    leaveEndDate: "12-03-2023",
    jobStartDate: "14-01-2020",
    jobEndDate: "25-03-2022",
    wpsSalary: 32000,
    wpsStatus: "Paid",
    commissionStatus: "Paid",
    reportingManager: "Mr. Raj Kumar",
  },
  {
    id: "10",
    empPicture: Employee,
    empIdPhoto: IDPhoto,
    empName: "Unni Krishnan",
    empId: "3478",
    department: "HR",
    designation: "Software Developer",
    empPhone: "+971 568470250",
    empEmail: "empname@company.com",
    status: "Active",
    empCompany: "Scalex IT Solutions",
    action: "...",
    date: "14-01-2023",
    resignationDate: "20-03-2023",
    exitDate: "30-03-2023",
    loginTime: "9.27 AM",
    logoutTime: "6.24 PM",
    details: "View More",
    payslipType: "Monthly",
    salary: 45000,
    createDate: "14-01-2023",
    salaryStatus: "Paid",
    leaveDay: "Today",
    statusIcon: "fa-solid fa-circle",
    leaveStatus: "pending",
    leaveStartDate: "10-03-2023",
    leaveEndDate: "12-03-2023",
    jobStartDate: "14-01-2020",
    jobEndDate: "25-03-2022",
    wpsSalary: 32000,
    wpsStatus: "Paid",
    commissionStatus: "Paid",
    reportingManager: "Mr. Raj Kumar",
  },
  {
    id: "11",
    empPicture: Employee,
    empIdPhoto: IDPhoto,
    empName: "Annie Varghese",
    empCompany: "The Thought Factory",
    empId: "5478",
    designation: "Software Developer",
    department:"IT",
    empPhone: "+971 568470250",
    empEmail: "empname@company.com",
    status: "Active",
    action: "...",
    date: "14-01-2023",
    resignationDate: "20-03-2023",
    exitDate: "30-03-2023",
    loginTime: "9.27 AM",
    logoutTime: "6.24 PM",
    details: "View More",
    payslipType: "Monthly",
    salary: 45000,
    createDate: "14-01-2023",
    salaryStatus: "Paid",
    leaveDay: "Today",
    statusIcon: "fa-solid fa-circle",
    leaveStatus: "pending",
    leaveStartDate: "10-03-2023",
    leaveEndDate: "12-03-2023",
    jobStartDate: "2020-01-14",
    jobEndDate: "25-03-2022",
    wpsSalary: 32000,
    wpsStatus: "Paid",
    commissionStatus: "Paid",
    reportingManager: "Mr. Raj Kumar",
  },
  {
    id: "12",
    empPicture: Employee,
    empIdPhoto: IDPhoto,
    empName: "Annie Varghese",
    empId: "5478",
    designation: "Software Developer",
    empPhone: "+971 568470250",
    empEmail: "empname@company.com",
    status: "Active",
    empCompany: "Scalex IT Solutions",
    action: "...",
    date: "14-01-2023",
    resignationDate: "20-03-2023",
    exitDate: "30-03-2023",
    loginTime: "9.27 AM",
    logoutTime: "6.24 PM",
    details: "View More",
    payslipType: "Monthly",
    salary: 45000,
    createDate: "14-01-2023",
    salaryStatus: "Paid",
    leaveDay: "Today",
    statusIcon: "fa-solid fa-circle",
    leaveStatus: "pending",
    leaveStartDate: "10-03-2023",
    leaveEndDate: "12-03-2023",
    jobStartDate: "14-01-2020",
    jobEndDate: "25-03-2022",
    wpsSalary: 32000,
    wpsStatus: "Paid",
    commissionStatus: "Paid",
    reportingManager: "Mr. Raj Kumar",
  },
  {
    id: "13",
    empPicture: Employee,
    empIdPhoto: IDPhoto,
    empName: "Annie Varghese",
    empId: "5478",
    empCompany: "Scalex IT Solutions",
    designation: "Software Developer",
    empPhone: "+971 568470250",
    empEmail: "empname@company.com",
    status: "Active",
    action: "...",
    date: "14-01-2023",
    resignationDate: "20-03-2023",
    exitDate: "30-03-2023",
    loginTime: "9.27 AM",
    logoutTime: "6.24 PM",
    details: "View More",
    payslipType: "Monthly",
    salary: 45000,
    createDate: "14-01-2023",
    salaryStatus: "Paid",
    leaveDay: "Today",
    statusIcon: "fa-solid fa-circle",
    leaveStatus: "pending",
    leaveStartDate: "10-03-2023",
    leaveEndDate: "12-03-2023",
    jobStartDate: "14-01-2020",
    jobEndDate: "25-03-2022",
    wpsSalary: 32000,
    wpsStatus: "Paid",
    commissionStatus: "Paid",
    reportingManager: "Mr. Raj Kumar",
  },
  {
    id: "14",
    empPicture: Employee,
    empIdPhoto: IDPhoto,
    empName: "Annie Varghese",
    empCompany: "The Thought Factory",
    empId: "5478",
    designation: "Software Developer",
    empPhone: "+971 568470250",
    empEmail: "empname@company.com",
    status: "Active",
    action: "...",
    date: "14-01-2023",
    resignationDate: "20-03-2023",
    exitDate: "30-03-2023",
    loginTime: "9.27 AM",
    logoutTime: "6.24 PM",
    details: "View More",
    payslipType: "Monthly",
    salary: 45000,
    createDate: "14-01-2023",
    salaryStatus: "Paid",
    leaveDay: "Today",
    statusIcon: "fa-solid fa-circle",
    leaveStatus: "pending",
    leaveStartDate: "10-03-2023",
    leaveEndDate: "12-03-2023",
    jobStartDate: "14-01-2020",
    jobEndDate: "25-03-2022",
    wpsSalary: 32000,
    wpsStatus: "Paid",
    commissionStatus: "Paid",
    reportingManager: "Mr. Raj Kumar",
  },
  {
    id: "15",
    empPicture: Employee,
    empIdPhoto: IDPhoto,
    empName: "Annie Varghese",
    empCompany: "The Thought Factory",
    empId: "5478",
    designation: "Software Developer",
    empPhone: "+971 568470250",
    empEmail: "empname@company.com",
    status: "Active",
    action: "...",
    date: "14-01-2023",
    resignationDate: "20-03-2023",
    exitDate: "30-03-2023",
    loginTime: "9.27 AM",
    logoutTime: "6.24 PM",
    details: "View More",
    payslipType: "Monthly",
    salary: 45000,
    createDate: "14-01-2023",
    salaryStatus: "Paid",
    leaveDay: "Today",
    statusIcon: "fa-solid fa-circle",
    leaveStatus: "pending",
    leaveStartDate: "10-03-2023",
    leaveEndDate: "12-03-2023",
    jobStartDate: "14-01-2020",
    jobEndDate: "25-03-2022",
    wpsSalary: 32000,
    wpsStatus: "Paid",
    commissionStatus: "Paid",
    reportingManager: "Mr. Raj Kumar",
  },
  {
    id: "16",
    empPicture: Employee,
    empIdPhoto: IDPhoto,
    empName: "Annie Varghese",
    empCompany: "The Thought Factory",
    empId: "5478",
    designation: "Software Developer",
    empPhone: "+971 568470250",
    empEmail: "empname@company.com",
    status: "Active",
    action: "...",
    date: "14-01-2023",
    resignationDate: "20-03-2023",
    exitDate: "30-03-2023",
    loginTime: "9.27 AM",
    logoutTime: "6.24 PM",
    details: "View More",
    payslipType: "Monthly",
    salary: 45000,
    createDate: "14-01-2023",
    salaryStatus: "Paid",
    leaveDay: "Today",
    statusIcon: "fa-solid fa-circle",
    leaveStatus: "pending",
    leaveStartDate: "10-03-2023",
    leaveEndDate: "12-03-2023",
    jobStartDate: "14-01-2020",
    jobEndDate: "25-03-2022",
    wpsSalary: 32000,
    wpsStatus: "Paid",
    commissionStatus: "Paid",
    reportingManager: "Mr. Raj Kumar",
  },
  {
    id: "17",
    empPicture: Employee,
    empIdPhoto: IDPhoto,
    empName: "Annie Varghese",
    empId: "5478",
    empCompany: "The Thought Factory",
    designation: "Software Developer",
    empPhone: "+971 568470250",
    empEmail: "empname@company.com",
    status: "Active",
    action: "...",
    date: "14-01-2023",
    resignationDate: "20-03-2023",
    exitDate: "30-03-2023",
    loginTime: "9.27 AM",
    logoutTime: "6.24 PM",
    details: "View More",
    payslipType: "Monthly",
    salary: 45000,
    createDate: "14-01-2023",
    salaryStatus: "Paid",
    leaveDay: "Today",
    statusIcon: "fa-solid fa-circle",
    leaveStatus: "pending",
    leaveStartDate: "10-03-2023",
    leaveEndDate: "12-03-2023",
    jobStartDate: "14-01-2020",
    jobEndDate: "25-03-2022",
    wpsSalary: 32000,
    wpsStatus: "Paid",
    commissionStatus: "Paid",
    reportingManager: "Mr. Raj Kumar",
  },
  {
    id: "18",
    empPicture: Employee,
    empIdPhoto: IDPhoto,
    empName: "Annie Varghese",
    empCompany: "The Thought Factory",
    empId: "5478",
    designation: "Software Developer",
    empPhone: "+971 568470250",
    empEmail: "empname@company.com",
    status: "Active",
    action: "...",
    date: "14-01-2023",
    resignationDate: "20-03-2023",
    exitDate: "30-03-2023",
    loginTime: "9.27 AM",
    logoutTime: "6.24 PM",
    details: "View More",
    payslipType: "Monthly",
    salary: 45000,
    createDate: "14-01-2023",
    salaryStatus: "Paid",
    leaveDay: "Today",
    statusIcon: "fa-solid fa-circle",
    leaveStatus: "pending",
    leaveStartDate: "10-03-2023",
    leaveEndDate: "12-03-2023",
    jobStartDate: "14-01-2020",
    jobEndDate: "25-03-2022",
    wpsSalary: 32000,
    wpsStatus: "Paid",
    commissionStatus: "Paid",
    reportingManager: "Mr. Raj Kumar",
  },
  {
    id: "19",
    empPicture: Employee,
    empIdPhoto: IDPhoto,
    empName: "Annie Varghese",
    empId: "5478",
    empCompany: "The Thought Factory",
    designation: "Software Developer",
    empPhone: "+971 568470250",
    empEmail: "empname@company.com",
    status: "Active",
    action: "...",
    date: "14-01-2023",
    resignationDate: "20-03-2023",
    exitDate: "30-03-2023",
    loginTime: "9.27 AM",
    logoutTime: "6.24 PM",
    details: "View More",
    payslipType: "Monthly",
    salary: 45000,
    createDate: "14-01-2023",
    salaryStatus: "Paid",
    leaveDay: "Today",
    statusIcon: "fa-solid fa-circle",
    leaveStatus: "pending",
    leaveStartDate: "10-03-2023",
    leaveEndDate: "12-03-2023",
    jobStartDate: "14-01-2020",
    jobEndDate: "25-03-2022",
    wpsSalary: 32000,
    wpsStatus: "Paid",
    commissionStatus: "Paid",
    reportingManager: "Mr. Raj Kumar",
  },
  {
    id: "20",
    empPicture: Employee,
    empIdPhoto: IDPhoto,
    empName: "Annie Varghese",
    empCompany: "The Thought Factory",
    empId: "5478",
    designation: "Software Developer",
    empPhone: "+971 568470250",
    empEmail: "empname@company.com",
    status: "Active",
    action: "...",
    date: "14-01-2023",
    resignationDate: "20-03-2023",
    exitDate: "30-03-2023",
    loginTime: "9.27 AM",
    logoutTime: "6.24 PM",
    details: "View More",
    payslipType: "Monthly",
    salary: 45000,
    createDate: "14-01-2023",
    salaryStatus: "Paid",
    leaveDay: "Today",
    statusIcon: "fa-solid fa-circle",
    leaveStatus: "pending",
    leaveStartDate: "10-03-2023",
    leaveEndDate: "12-03-2023",
    jobStartDate: "14-01-2020",
    jobEndDate: "25-03-2022",
    wpsSalary: 32000,
    wpsStatus: "Paid",
    commissionStatus: "Paid",
    reportingManager: "Mr. Raj Kumar",
  },
  {
    id: "21",
    empPicture: Employee,
    empIdPhoto: IDPhoto,
    empName: "Santhosh Kumar",
    empId: "5478",
    designation: "Software Developer",
    empCompany: "Scalex IT Solutions",
    empPhone: "+971 568470250",
    empEmail: "empname@company.com",
    status: "Active",
    action: "...",
    date: "14-01-2023",
    resignationDate: "20-03-2023",
    exitDate: "30-03-2023",
    loginTime: "9.27 AM",
    logoutTime: "6.24 PM",
    details: "View More",
    payslipType: "Monthly",
    salary: 45000,
    createDate: "14-01-2023",
    salaryStatus: "Paid",
    leaveDay: "Today",
    statusIcon: "fa-solid fa-circle",
    leaveStatus: "pending",
    leaveStartDate: "10-03-2023",
    leaveEndDate: "12-03-2023",
    jobStartDate: "14-01-2020",
    jobEndDate: "25-03-2022",
    wpsSalary: 32000,
    wpsStatus: "Paid",
    commissionStatus: "Paid",
    reportingManager: "Mr. Raj Kumar",
  },
  {
    id: "22",
    empPicture: Employee,
    empIdPhoto: IDPhoto,
    empName: "Santhosh Kumar",
    empId: "5478",
    empCompany: "Scalex IT Solutions",
    designation: "Software Developer",
    empPhone: "+971 568470250",
    empEmail: "empname@company.com",
    status: "Active",
    action: "...",
    date: "14-01-2023",
    resignationDate: "20-03-2023",
    exitDate: "30-03-2023",
    loginTime: "9.27 AM",
    logoutTime: "6.24 PM",
    details: "View More",
    payslipType: "Monthly",
    salary: 45000,
    createDate: "14-01-2023",
    salaryStatus: "Paid",
    leaveDay: "Today",
    statusIcon: "fa-solid fa-circle",
    leaveStatus: "pending",
    leaveStartDate: "10-03-2023",
    leaveEndDate: "12-03-2023",
    jobStartDate: "14-01-2020",
    jobEndDate: "25-03-2022",
    wpsSalary: 32000,
    wpsStatus: "Paid",
    commissionStatus: "Paid",
    reportingManager: "Mr. Raj Kumar",
  },
  {
    id: "23",
    empPicture: Employee,
    empIdPhoto: IDPhoto,
    empName: "Santhosh Kumar",
    empId: "5478",
    designation: "Software Developer",
    empPhone: "+971 568470250",
    empCompany: "Scalex IT Solutions",
    empEmail: "empname@company.com",
    status: "Active",
    action: "...",
    date: "14-01-2023",
    resignationDate: "20-03-2023",
    exitDate: "30-03-2023",
    loginTime: "9.27 AM",
    logoutTime: "6.24 PM",
    details: "View More",
    payslipType: "Monthly",
    salary: 45000,
    createDate: "14-01-2023",
    salaryStatus: "Paid",
    leaveDay: "Today",
    statusIcon: "fa-solid fa-circle",
    leaveStatus: "pending",
    leaveStartDate: "10-03-2023",
    leaveEndDate: "12-03-2023",
    jobStartDate: "14-01-2020",
    jobEndDate: "25-03-2022",
    wpsSalary: 32000,
    wpsStatus: "Paid",
    commissionStatus: "Paid",
    reportingManager: "Mr. Raj Kumar",
  },
  {
    id: "24",
    empPicture: Employee,
    empIdPhoto: IDPhoto,
    empName: "Santhosh Kumar",
    empCompany: "Rhapsody",
    empId: "5478",
    designation: "Software Developer",
    empPhone: "+971 568470250",
    empEmail: "empname@company.com",
    status: "Active",
    action: "...",
    date: "14-01-2023",
    resignationDate: "20-03-2023",
    exitDate: "30-03-2023",
    loginTime: "9.27 AM",
    logoutTime: "6.24 PM",
    details: "View More",
    payslipType: "Monthly",
    salary: 45000,
    createDate: "14-01-2023",
    salaryStatus: "Paid",
    leaveDay: "Today",
    statusIcon: "fa-solid fa-circle",
    leaveStatus: "pending",
    leaveStartDate: "10-03-2023",
    leaveEndDate: "12-03-2023",
    jobStartDate: "14-01-2020",
    jobEndDate: "25-03-2022",
    wpsSalary: 32000,
    wpsStatus: "Paid",
    commissionStatus: "Paid",
    reportingManager: "Mr. Raj Kumar",
  },
  {
    id: "25",
    empPicture: Employee,
    empIdPhoto: IDPhoto,
    empName: "Santhosh Kumar",
    empCompany: "Rhapsody",
    empId: "5478",
    designation: "Software Developer",
    empPhone: "+971 568470250",
    empEmail: "empname@company.com",
    status: "Active",
    action: "...",
    date: "14-01-2023",
    resignationDate: "20-03-2023",
    exitDate: "30-03-2023",
    loginTime: "9.27 AM",
    logoutTime: "6.24 PM",
    details: "View More",
    payslipType: "Monthly",
    salary: 45000,
    createDate: "14-01-2023",
    salaryStatus: "Paid",
    leaveDay: "Today",
    statusIcon: "fa-solid fa-circle",
    leaveStatus: "pending",
    leaveStartDate: "10-03-2023",
    leaveEndDate: "12-03-2023",
    jobStartDate: "14-01-2020",
    jobEndDate: "25-03-2022",
    wpsSalary: 32000,
    wpsStatus: "Paid",
    commissionStatus: "Paid",
    reportingManager: "Mr. Raj Kumar",
  },
  {
    id: "26",
    empPicture: Employee,
    empIdPhoto: IDPhoto,
    empName: "Santhosh Kumar",
    empCompany: "Rhapsody",
    empId: "5478",
    designation: "Software Developer",
    empPhone: "+971 568470250",
    empEmail: "empname@company.com",
    status: "Active",
    action: "...",
    date: "14-01-2023",
    resignationDate: "20-03-2023",
    exitDate: "30-03-2023",
    loginTime: "9.27 AM",
    logoutTime: "6.24 PM",
    details: "View More",
    payslipType: "Monthly",
    salary: 45000,
    createDate: "14-01-2023",
    salaryStatus: "Paid",
    leaveStartDate: "10-03-2023",
    leaveEndDate: "12-03-2023",
    leaveDay: "Today",
    statusIcon: "fa-solid fa-circle",
    leaveStatus: "pending",
    jobStartDate: "14-01-2020",
    jobEndDate: "25-03-2022",
    wpsSalary: 32000,
    wpsStatus: "Paid",
    commissionStatus: "Paid",
    reportingManager: "Mr. Raj Kumar",
  },
  {
    id: "27",
    empPicture: Employee,
    empIdPhoto: IDPhoto,
    empName: "Santhosh Kumar",
    empCompany: "Rhapsody",
    empId: "5478",
    designation: "Software Developer",
    empPhone: "+971 568470250",
    empEmail: "empname@company.com",
    status: "Active",
    action: "...",
    date: "14-01-2023",
    resignationDate: "20-03-2023",
    exitDate: "30-03-2023",
    loginTime: "9.27 AM",
    logoutTime: "6.24 PM",
    details: "View More",
    payslipType: "Monthly",
    salary: 45000,
    createDate: "14-01-2023",
    salaryStatus: "Paid",
    leaveDay: "Today",
    statusIcon: "fa-solid fa-circle",
    leaveStatus: "pending",
    leaveStartDate: "10-03-2023",
    leaveEndDate: "12-03-2023",
    jobStartDate: "14-01-2020",
    jobEndDate: "25-03-2022",
    wpsSalary: 32000,
    wpsStatus: "Paid",
    commissionStatus: "Paid",
    reportingManager: "Mr. Raj Kumar",
  },
  {
    id: "28",
    empPicture: Employee,
    empIdPhoto: IDPhoto,
    empName: "Santhosh Kumar",
    empCompany: "Rhapsody",
    empId: "5478",
    designation: "Software Developer",
    empPhone: "+971 568470250",
    empEmail: "empname@company.com",
    status: "Active",
    action: "...",
    date: "14-01-2023",
    resignationDate: "20-03-2023",
    exitDate: "30-03-2023",
    loginTime: "9.27 AM",
    logoutTime: "6.24 PM",
    details: "View More",
    payslipType: "Monthly",
    salary: 45000,
    createDate: "14-01-2023",
    salaryStatus: "Paid",
    leaveDay: "Today",
    statusIcon: "fa-solid fa-circle",
    leaveStatus: "pending",
    leaveStartDate: "10-03-2023",
    leaveEndDate: "12-03-2023",
    jobStartDate: "14-01-2020",
    jobEndDate: "25-03-2022",
    wpsSalary: 32000,
    wpsStatus: "Paid",
    commissionStatus: "Paid",
    reportingManager: "Mr. Raj Kumar",
  },
  {
    id: "29",
    empPicture: Employee,
    empIdPhoto: IDPhoto,
    empName: "Santhosh Kumar",
    empCompany: "Rhapsody",
    empId: "5478",
    designation: "Software Developer",
    empPhone: "+971 568470250",
    empEmail: "empname@company.com",
    status: "Active",
    action: "...",
    date: "14-01-2023",
    resignationDate: "20-03-2023",
    exitDate: "30-03-2023",
    loginTime: "9.27 AM",
    logoutTime: "6.24 PM",
    details: "View More",
    payslipType: "Monthly",
    salary: 45000,
    createDate: "14-01-2023",
    salaryStatus: "Paid",
    leaveDay: "Today",
    statusIcon: "fa-solid fa-circle",
    leaveStatus: "pending",
    leaveStartDate: "10-03-2023",
    leaveEndDate: "12-03-2023",
    jobStartDate: "14-01-2020",
    jobEndDate: "25-03-2022",
    wpsSalary: 32000,
    wpsStatus: "Paid",
    commissionStatus: "Paid",
    reportingManager: "Mr. Raj Kumar",
  },
  {
    id: "30",
    empPicture: Employee,
    empIdPhoto: IDPhoto,
    empName: "Santhosh Kumar",
    empCompany: "Rhapsody",
    empId: "5478",
    designation: "Software Developer",
    empPhone: "+971 568470250",
    empEmail: "empname@company.com",
    status: "Active",
    action: "...",
    date: "14-01-2023",
    resignationDate: "20-03-2023",
    exitDate: "30-03-2023",
    loginTime: "9.27 AM",
    logoutTime: "6.24 PM",
    details: "View More",
    payslipType: "Monthly",
    salary: 45000,
    createDate: "14-01-2023",
    salaryStatus: "Paid",
    leaveDay: "Today",
    statusIcon: "fa-solid fa-circle",
    leaveStatus: "pending",
    leaveStartDate: "10-03-2023",
    leaveEndDate: "12-03-2023",
    jobStartDate: "14-01-2020",
    jobEndDate: "25-03-2022",
    wpsSalary: 32000,
    wpsStatus: "Paid",
    commissionStatus: "Paid",
    reportingManager: "Mr. Raj Kumar",
  },
  {
    id: "2",
    empPicture: Employee,
    empIdPhoto: IDPhoto,
    empCompany: "The Thought Factory",
    empName: "Kunjumon T V",
    empId: "4090",
    department: "IT",
    designation: "Software Developer",
    empPhone: "+971 568470250",
    empEmail: "empname@company.com",
    status: "Active",
    action: "...",
    date: "2023-05-22",
    resignationDate: "20-03-2023",
    exitDate: "30-03-2023",
    loginTime: "9.27 AM",
    logoutTime: "6.24 PM",
    details: "View More",
    payslipType: "Monthly",
    salary: 45000,
    createDate: "2023-05-22",
    salaryStatus: "Paid",
    leaveDay: "Today",
    statusIcon: "fa-solid fa-circle",
    leaveStatus: "pending",
    leaveStartDate: "10-03-2023",
    leaveEndDate: "12-03-2023",
    jobStartDate: "14-01-2020",
    jobEndDate: "25-03-2022",
    wpsSalary: 32000,
    wpsStatus: "Paid",
    commissionStatus: "Paid",
    reportingManager: "Mr. Raj Kumar",
  },
];

export default employeeData;
