import "../../styles/dashboard.css";
import { useLocation, useNavigate } from "react-router-dom";
import { capitalizeWords } from "./capitalizeWords";
import formatDate from "./dateUtils";
import * as XLSX from "xlsx";


const InsuranceCardDetails = () => {
  const location = useLocation();
  const insuranceData = location.state?.insuranceData;
  const navigate = useNavigate();

  const getInsuranceNumber = (documents) => {
    const insuranceDocument = documents.find((doc) => doc.name === "Insurance Card");
    return insuranceDocument ? insuranceDocument.number : "";
  };

  const getExpiryDate = (documents) => {
    const insuranceDocument = documents.find((doc) => doc.name === "Insurance Card");
    return insuranceDocument ? insuranceDocument.expiryDate : "";
  };

  const getDaysLeft = (documents) => {
    const insuranceDocument = documents.find((doc) => doc.name === "Insurance Card");
    if (insuranceDocument && insuranceDocument.expiryDate) {
      const expiryDate = new Date(insuranceDocument.expiryDate);
      const today = new Date();
      const timeDifference = expiryDate - today;
      const daysLeft = Math.ceil(timeDifference / (1000 * 60 * 60 * 24));
      return daysLeft >= 0 ? daysLeft : 0;
    }
    return "";
  };

  const handleUpdateInsuranceCardDetails = (id) => {
    const employeeDataToUpdate = insuranceData.find(
      (employee) => employee.employee_information_id === id
    );
    if (employeeDataToUpdate) {
      navigate(`/insurancecardupdate/${id}`, {
        state: { insuranceDataToUpdate: employeeDataToUpdate },
      });
    }
  };

  const downloadDataAsExcel = () => {
    const filename = "InsuranceDataExpiry.xlsx";
    const data = insuranceData.map((employee, index) => ({
      "SL No.": index + 1,
      "Employee ID": employee.employee_id,
      "Employee Details": capitalizeWords(employee?.employee_full_name),
      "Company Name": capitalizeWords(
        employee?.roles[0]?.company[0]?.company_name
      ),
      "Insurance Number": getInsuranceNumber(employee.documents),
      "Expiry Date": getExpiryDate(employee.documents),
      "Days Left": getDaysLeft(employee.documents),
    }));

    const worksheet = XLSX.utils.json_to_sheet(data);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Insurance Data");
    XLSX.writeFile(workbook, filename);
  };

  return (
    <div className="board__layout">
      <div className="section-header">
        <h1>Insurance Card Expiry Details</h1>
        <button className="expiry_download-btn" onClick={downloadDataAsExcel}>
          Download
        </button>
      </div>
      <div>
        <table>
          <thead>
            <tr>
              <th>SL No.</th>
              <th>Employee ID</th>
              <th>Employee Details</th>
              <th>Company Name</th>
              <th>Insurance Number</th>
              <th>Expiry Date</th>
              <th>Days Left</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {insuranceData.map((employee, index) => (
              <tr key={index}>
                <td>{index + 1}</td>
                <td>{employee.employee_id}</td>
                <td>
                  <div className="leave_empName">
                    {capitalizeWords(employee.employee_full_name)}
                  </div>
                  <div className="leave_designation">
                    {capitalizeWords(employee.roles[0].role_name)}
                  </div>
                </td>

                <td>
                  {capitalizeWords(employee?.roles[0].company[0].company_name)}
                </td>
                <td>{getInsuranceNumber(employee.documents)}</td>
                <td>{formatDate(getExpiryDate(employee.documents))}</td>
                <td>{getDaysLeft(employee.documents)}</td>
                <td>
                  <div className="visa_expiry-edit">
                    <button
                      onClick={() =>
                        handleUpdateInsuranceCardDetails(
                          employee.employee_information_id
                        )
                      }
                    >
                      Update
                    </button>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default InsuranceCardDetails;
