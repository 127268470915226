import "../styles/employeeSalary.css";
import { useState, useEffect, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { useFormik } from "formik";
import * as Yup from "yup";
import TextError from "../components/Reusable/TextError";

const AddEmployeeSalaryForm = () => {
  const accessToken = localStorage.getItem("accessToken");
  const [selectedCompany, setSelectedCompany] = useState("");
  const [selectedDepartment, setSelectedDepartment] = useState("");
  const [companyList, setCompanyList] = useState([]);
  const [departmentList, setDepartmentList] = useState([]);
  const [selectedDepartmentId, setSelectedDepartmentId] = useState("");
  const navigate = useNavigate();
  const currentYear = new Date().getFullYear();

  const initialValues = {
    employeeId: "",
    first_name: "",
    companyName: "",
    department: "",
    wpsSalary: "",
    commission: "",
    salary_month: "",
    paySlip: null,
  };

  const validationSchema = Yup.object({
    employeeId: Yup.string()
      .min(3, "Must be 3 characters or more")
      .max(15, "Must be 15 characters or less")
      .required("Required"),
    first_name: Yup.string()
      .min(3, "Must be 3 characters or more")
      .max(20, "Must be 20 characters or less")
      .required("Required"),
    companyName: Yup.string().required("Required"),
    department: Yup.string().required("Required!"),
    wpsSalary: Yup.number()
      .required("Required!")
      .positive("Salary must be a positive number"),
    commission: Yup.number()
      .required("Required!")
      .positive("Commission must be a positive number"),
    salary_month: Yup.date()
      .required("Enter a valid date")
      .typeError("Invalid date format")
      .min(new Date(), "Salary month cannot be in the past")
      .test("year", "Year must be between 2000 and 2100", function (value) {
        if (!value) return true;
        const year = new Date(value).getFullYear();
        return year >= 2000 && year <= 2100 && year <= currentYear;
      }),
    paySlip: Yup.mixed()
      .required("Payslip is not uploaded")
      .test("fileType", "Invalid file format", (value) => {
        if (value && value.name) {
          return (
            value.type === "application/pdf" ||
            value.type === "image/jpeg" ||
            value.type === "image/jpg" ||
            value.type === "image/png"
          );
        }
        return true;
      }),
  });

  const getCompany = () => {
    axios
      .get(`${process.env.REACT_APP_BASE_URL}/companies`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      })
      .then((response) => {
        const data = response.data?.data || [];
        setCompanyList(data);
      })
      .catch((error) => console.log(error));
  };

  useEffect(() => {
    getCompany();
  }, []);

  const getDepartments = useCallback(
    (company = selectedCompany) => {
      return axios
        .get(`${process.env.REACT_APP_BASE_URL}/companies/${company}/departments`, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        })
        .then((response) => {
          const data = response.data?.data || [];
          setDepartmentList(data);
          return data;
        })
        .catch((error) => console.log(error));
    },
    [selectedCompany]
  );

  const handleSubmit = (values) => {
    console.log("Form data:", values);
  };

  const handleInputChange = (event, type, formik) => {
    const { name, value } = event.target;
    if (type === "company") {
      console.log("value", value);
      setSelectedCompany(value);
      formik.setFieldValue(name, value);
      getDepartments(value);
    } else if (type === "department") {
      setSelectedDepartment(value);
      setSelectedDepartmentId(value);
      formik.setFieldValue(name, value);
    }
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: handleSubmit,
  });

  return (
    <div className="board__layout">
      <div className="section-header">
        <div className="header__titles">
          <button onClick={() => navigate("/employeesalary")}>
            <span>
              <i className="ri-arrow-left-s-line"></i>
            </span>
            BACK
          </button>
          <h4>Add Pay Slip</h4>
        </div>
      </div>

      <form onSubmit={formik.handleSubmit} style={{ padding: "30px" }}>
        <div className="form-rows">
          <div>
            <label htmlFor="employeeId">Employee Id</label>
            <input
              type="text"
              id="employeeId"
              name="employeeId"
              placeholder="Enter Employee Id"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.employeeId}
            />
            {formik.errors.employeeId && formik.touched.employeeId && (
              <div className="error-message">{formik.errors.employeeId}</div>
            )}
          </div>

          <div>
            <label htmlFor="first_name">Employee First Name</label>
            <input
              type="text"
              id="first_name"
              name="first_name"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.first_name}
            />
            {formik.errors.first_name && formik.touched.first_name && (
              <div className="error-message">{formik.errors.first_name}</div>
            )}
          </div>
        </div>

        <div className="form-rows">
          <div>
            <label htmlFor="companyName">Company Name</label>
            <select
              name="companyName"
              onChange={(e) => handleInputChange(e, "company", formik)}
              onBlur={formik.handleBlur}
              value={selectedCompany}
            >
              <option value="">Select Company Name</option>
              {companyList.map((company) => (
                <option key={company.id} value={company.id}>
                  {company.company_name}
                </option>
              ))}
            </select>
            {formik.errors.companyName && formik.touched.companyName && (
              <div className="error-message">{formik.errors.companyName}</div>
            )}
          </div>

          <div>
            <label htmlFor="department">Department</label>
            <select
              name="department"
              onChange={(e) => handleInputChange(e, "department", formik)}
              onBlur={formik.handleBlur}
              value={selectedDepartment}
            >
              <option value="">Select Department</option>
              {departmentList.map((department) => (
                <option key={department.id} value={department.id}>
                  {department.department_name}
                </option>
              ))}
            </select>
            {formik.errors.department && formik.touched.department && (
              <div className="error-message">{formik.errors.department}</div>
            )}
          </div>
        </div>

        <div className="form-rows">
          <div>
            <label htmlFor="wpsSalary">WPS Salary</label>
            <input
              type="text"
              id="wpsSalary"
              name="wpsSalary"
              placeholder="Enter WPS Salary"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.wpsSalary}
            />
            {formik.errors.wpsSalary && formik.touched.wpsSalary && (
              <div className="error-message">{formik.errors.wpsSalary}</div>
            )}
          </div>

          <div>
            <label htmlFor="commission">Commission</label>
            <input
              type="text"
              id="commission"
              name="commission"
              placeholder="Enter Commission Amount"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.commission}
            />
            {formik.errors.commission && formik.touched.commission && (
              <div className="error-message">{formik.errors.commission}</div>
            )}
          </div>
        </div>

        <div className="form-rows">
          <div>
        <label htmlFor="paySlip">Salary Month</label>
            <input
              name="salary_month"
              type="date"
              id="salary_month"
              onChange={(e) => {
                formik.setFieldValue("salary_month", e.target.value);
              }}
              onBlur={formik.handleBlur}
            />

            {formik.errors.salary_month && formik.touched.salary_month && (
              <div className="error-message">{formik.errors.salary_month}</div>
            )}
          </div>

          <div>
            <label htmlFor="paySlip">Upload Payslip</label>
            <input
              name="paySlip"
              className="custom-file-upload"
              type="file"
              id="paySlip"
              onChange={(e) => {
                formik.setFieldValue("paySlip", e.currentTarget.files[0]);
              }}
              onBlur={formik.handleBlur}
            />
            {formik.errors.paySlip && formik.touched.paySlip && (
              <div className="error-message">{formik.errors.paySlip}</div>
            )}
          </div>
        </div>

        <div className="user-btn-section">
          <div style={{ marginTop: "1rem" }}>
            <button type="submit" className="form-btn-submit">
              Save & Update
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default AddEmployeeSalaryForm;
