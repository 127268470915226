import "../styles/attendance.css";
import React, { useState, useEffect, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import SetWorkingTime from "../components/Reusable/SetWorkingTime";

const TimeSettings = () => {
  const accessToken = localStorage.getItem("accessToken");
  const [selectedCompany, setSelectedCompany] = useState("");
  const [selectedDepartment, setSelectedDepartment] = useState("");
  const [companyList, setCompanyList] = useState([]);
  const [departmentList, setDepartmentList] = useState([]);
  const [employeeList, setEmployeeList] = useState([]);
  const [selectedEmployee, setSelectedEmployee] = useState("");
  const [selectedRadio, setSelectedRadio] = useState("company");
  const [employeeTimeSheet, setEmployeeTimeSheet] = useState([]);
  const [isEmployeeTimeSheetEmpty, setIsEmployeeTimeSheetEmpty] =
    useState(false);

  const navigate = useNavigate();

  const getCompany = () => {
    axios
      .get(`${process.env.REACT_APP_BASE_URL}/companies`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      })
      .then((response) => {
        const data = response.data?.data || [];
        setCompanyList(data);
      })
      .catch((error) => console.log(error));
  };

  const getDepartments = useCallback(
    (company = selectedCompany) => {
      return axios
        .get(
          `${process.env.REACT_APP_BASE_URL}/companies/${company}/departments`,
          {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          }
        )
        .then((response) => {
          const data = response.data?.data || [];
          setDepartmentList(data);
          return data;
        })
        .catch((error) => console.log(error));
    },
    [selectedCompany]
  );

  const getEmployeeList = useCallback(
    (department = selectedDepartment) => {
      axios
        .get(
          `${process.env.REACT_APP_BASE_URL}/departments/${department}/employees`,
          {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          }
        )
        .then((response) => {
          const data = response.data.data || [];
          setEmployeeList(data);
        })
        .catch((error) => console.log(error));
    },
    [selectedDepartment]
  );

  useEffect(() => {
    getCompany();
  }, []);

  useEffect(() => {
    getDepartments();
  }, [getDepartments]);

  useEffect(() => {
    getEmployeeList();
  }, [getEmployeeList]);

  const getEmployeeTimeSheet = useCallback(
    (employeeId = selectedEmployee) => {
      setTimeout(() => {
        axios
          .get(
            `${process.env.REACT_APP_BASE_URL}/employees/${employeeId}/work-schedule`,
            {
              headers: {
                Authorization: `Bearer ${accessToken}`,
              },
            }
          )
          .then((response) => {
            const data = response.data || [];
            setEmployeeTimeSheet(data);
          })
          .catch((error) => console.log(error));
      }, 100);
    },
    [selectedEmployee]
  );

  const handleRadioChange = (event) => {
    setSelectedRadio(event.target.value);
    setSelectedDepartment("");
    setSelectedEmployee("");
  };

  useEffect(() => {
    if (selectedEmployee) {
      getEmployeeTimeSheet();
    }
  }, [selectedEmployee]);

  useEffect(() => {
    setIsEmployeeTimeSheetEmpty(employeeTimeSheet.length === 0);
  }, [employeeTimeSheet]);

  const handleInputChange = (event, type) => {
    const { value } = event.target;
    if (type === "company") {
      setSelectedCompany(value);
      setSelectedDepartment("");
      setSelectedEmployee("");
    } else if (type === "department") {
      setSelectedDepartment(value);
      setSelectedEmployee("");
    } else if (type === "employee") {
      setSelectedEmployee(value);
    }
  };

  return (
    <div className="board__layout">
      <div className="section-header">
        <h4>Time Settings</h4>
      </div>

      <div className="time_settings-body">
        <div className="select_categories">
          <div className="radio_buttons">
            <input
              type="radio"
              name="radio_category"
              id="radio_company"
              value="company"
              checked={selectedRadio === "company"}
              onChange={handleRadioChange}
            />
            <label
              htmlFor="radio_company"
              className={`radio_buttons ${
                selectedRadio === "company" ? "active" : ""
              }`}
            >
              Company
            </label>
          </div>
          <div className="radio_buttons">
            <input
              type="radio"
              name="radio_category"
              id="radio_department"
              value="department"
              checked={selectedRadio === "department"}
              onChange={handleRadioChange}
            />
            <label
              htmlFor="radio_department"
              className={`radio_buttons ${
                selectedRadio === "department" ? "active" : ""
              }`}
            >
              Department
            </label>
          </div>
          <div className="radio_buttons">
            <input
              type="radio"
              name="radio_category"
              id="radio_employee"
              value="employee"
              checked={selectedRadio === "employee"}
              onChange={handleRadioChange}
            />
            <label
              htmlFor="radio_employee"
              className={`radio_buttons ${
                selectedRadio === "employee" ? "active" : ""
              }`}
            >
              Employee
            </label>
          </div>
        </div>
        <div>
          <p style={{ fontSize: "22px", fontWeight: "500" }}>
            Set Working Time
          </p>
        </div>
        <div className="form-rows">
          <div>
            <label htmlFor="effective_date">Effective From</label>
            <input type="date" name="effective_date" />
          </div>
          <div>
            <label htmlFor="company">Select Company</label>
            <select
              name="company"
              id="company"
              value={selectedCompany}
              onChange={(e) => handleInputChange(e, "company")}
            >
              <option value="">Select Company</option>
              {companyList.map((company) => (
                <option key={company.id} value={company.id}>
                  {company.company_name}
                </option>
              ))}
            </select>
          </div>
        </div>

        <div className="form-rows">
          {selectedRadio !== "company" && (
            <div>
              <label htmlFor="department">Select Department</label>
              <select
                name="department"
                id="department"
                value={selectedDepartment}
                onChange={(e) => handleInputChange(e, "department")}
              >
                <option value="">Select Department</option>
                {departmentList.map((department) => (
                  <option key={department.id} value={department.id}>
                    {department.department_name}
                  </option>
                ))}
              </select>
            </div>
          )}
          {selectedRadio !== "company" && selectedRadio !== "employee" && (
            <div></div>
          )}
          {selectedRadio === "employee" && (
            <div>
              <label htmlFor="employee_name">Employee Name</label>
              <select
                name="employee_name"
                id="employee_name"
                value={selectedEmployee}
                onChange={(e) => handleInputChange(e, "employee")}
              >
                <option value="">Employee Name</option>
                {employeeList.map((employee) => (
                  <option key={employee.id} value={employee.id}>
                    {`${employee.employee_full_name} - ${employee.employee_id}`}
                  </option>
                ))}
              </select>
            </div>
          )}
        </div>
        <div className="time-settings-title">
          <p>
            {`Configure the standard working time of operation for this selected ${
              selectedRadio === "employee"
                ? "Employee"
                : selectedRadio === "company"
                ? "Company"
                : selectedRadio === "department"
                ? "Department"
                : ""
            }`}
          </p>
        </div>

        <SetWorkingTime employeeTimeSheet={employeeTimeSheet} />

        <div className="time_settings-btn">
          <button
            className="btn-skip"
            onClick={() => navigate("/timeattendance")}
          >
            Close
          </button>
          <button className="btn-submit">Save</button>
        </div>
      </div>
    </div>
  );
};

export default TimeSettings;
