const navLinksMenu = [
  {
    title: "Dashboard",
    icon: "ri-dashboard-fill",
    path: "/dashboard",
  },
  {
    title: "Employee Management",
    icon: "fa-solid fa-user-plus",
    path: "/empmanagement",
  },
  {
    title: "Time & Attendance",
    icon: "fa-solid fa-calendar-day",
    path: "/timeattendance",
  },
  {
    title: "Leave Management",
    icon: "fa-solid fa-users",
    path: "/leavemanagement",
  },
  {
    title: "Payroll Management",
    icon: "ri-hand-coin-fill",
    path:"",
    children: [
      {
        title: "Employee Salary",
        icon: "ri-arrow-right-s-fill",
        path: "/employeesalary",
      },
      {
        title: "Payment History",
        icon: "ri-arrow-right-s-fill",
        path: "/paymenthistory",
      },
    ],
  },
  {
    title: "Onboarding",
    icon: "ri-suitcase-3-fill",
    path: "/onboarding",
  },
  // {
  //   title: "Recruitment",
  //   icon: "fa-solid fa-people-arrows",
  //   path: "/recruitment",
  // },
  // {
  //   title: "Learning & Development",
  //   icon: "fa-solid fa-head-side-virus",
  //   path: "/learn&develop",
  // },
  {
    title: "Exit Management",
    icon: "fa-solid fa-right-from-bracket",
    path: "/exitmanagement",
  },
];

export default navLinksMenu;
