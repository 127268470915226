import "../../styles/employeeDetails.css";
import Calendar from "react-calendar";
import calendarIcon from "../../assets/images/calendar.png";
import { CSVLink } from "react-csv";

const UtilityBarDates = ({
  startDate,
  endDate,
  selectedStartDate,
  selectedEndDate,
  minDate,
  view,
  setView,
  startDateOnChange,
  endDateOnChange,
  clearAllDates,
  searchButtonClick,
  attendanceData,
  getStatusText,
}) => {
  const csvHeaders = [
    { label: "Employee Name", key: "user.employee_full_name" },
    { label: "Employee ID", key: "user.employee_id" },
    { label: "Applied Date", key: "apply_date" },
    { label: "Start Date", key: "leave_from" },
    { label: "End Date", key: "leave_to" },
    { label: "Days", key: "days_count" },
    { label: "Leave Type", key: "leaveType" },
    { label: "Status", key: "status" },
  ];

  const csvData = attendanceData.map((employee) => ({
    ...employee,
    status: getStatusText(employee),
  }));

  return (
    <div className="utility-bar">
      <div className="utility_bar-left">
        <div className="calendar">
          <div>
            {!selectedStartDate && !selectedEndDate && (
              <p style={{ margin: 0 }}>Select Dates</p>
            )}
          </div>
          <div>
            <img src={calendarIcon} alt="icon" onClick={() => setView(!view)} />
          </div>
          <div>
            {selectedStartDate && selectedEndDate && (
              <p style={{ margin: 0 }}>Selected Dates</p>
            )}
          </div>
        </div>
        {view && (
          <div className="ub_calendar-container">
            <div className="ub_calendar">
              <div>
                <Calendar onChange={startDateOnChange} value={startDate} />
              </div>
              <div>
                <Calendar
                  onChange={endDateOnChange}
                  value={endDate}
                  minDate={minDate}
                />
              </div>
            </div>
            <div className="ub_calendar-btn">
              <button className="clear-button" onClick={clearAllDates}>
                Clear All
              </button>
              <button className="search-button" onClick={searchButtonClick}>
                Search
              </button>
            </div>
          </div>
        )}

        {selectedStartDate && selectedEndDate && (
          <div className="selected_dates" style={{ fontSize: "14px" }}>
            {selectedStartDate.toLocaleDateString()} -{" "}
            {selectedEndDate.toLocaleDateString()}
          </div>
        )}
      </div>
      <div className="utility_bar-right">
        <button>
          <span>
            <i className="ri-filter-2-fill"></i>
          </span>
        </button>
        <CSVLink
          data={csvData}
          headers={csvHeaders}
          filename={"leave_data.csv"}
          className="download-button"
        >
          <button>
            <span>
              <i className="ri-download-line"></i> Download
            </span>
          </button>
        </CSVLink>
      </div>
    </div>
  );
};

export default UtilityBarDates;
