import { useEffect, useState, useCallback } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { Formik, Field, Form, ErrorMessage, FieldArray } from "formik";
import * as Yup from "yup";
import TextError from "../MultiStep/TextError";
import Modal from "react-modal";
Modal.setAppElement("#root");

const JoiningDetailsUpdate = () => {
  const accessToken = localStorage.getItem("accessToken");
  const { id } = useParams();
  const [employeeData, setEmployeeData] = useState([]);
  const [selectedCompany, setSelectedCompany] = useState("");
  const [selectedDepartment, setSelectedDepartment] = useState("");
  const [companyList, setCompanyList] = useState([]);
  const [departmentList, setDepartmentList] = useState([]);
  const [designationList, setDesignationList] = useState([]);
  const [reportingManagerList, setReportingManagerList] = useState([]);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [selectedDepartmentId, setSelectedDepartmentId] = useState("");
  const navigate = useNavigate();

  const fetchEmployeeData = async (id) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/employees/employeeInformation/${id}`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      const data = response.data?.data;
      setEmployeeData(data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchEmployeeData(id);
  }, [id]);

  const getCompany = () => {
    axios
      .get(`${process.env.REACT_APP_BASE_URL}/companies`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      })
      .then((response) => {
        const data = response.data?.data || [];
        setCompanyList(data);
      })
      .catch((error) => console.log(error));
  };

  const getDepartments = useCallback(
    (company = selectedCompany) => {
      return axios
        .get(
          `${process.env.REACT_APP_BASE_URL}/departments?company=${company}`,
          {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          }
        )
        .then((response) => {
          const data = response.data?.data || [];
          setDepartmentList(data);
          return data;
        })
        .catch((error) => console.log(error));
    },
    [selectedCompany]
  );

  const getDesignation = useCallback(
    (department = selectedDepartment) => {
      axios
        .get(
          `${process.env.REACT_APP_BASE_URL}/departments/${selectedDepartmentId}/roles`,
          {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          }
        )
        .then((response) => {
          const data = response.data?.data || [];
          setDesignationList(data);
        })
        .catch((error) => console.log(error));
    },
    [selectedDepartment]
  );

  const getReportingManagerList = useCallback(
    (department = selectedDepartment) => {
      axios
        .get(
          `${process.env.REACT_APP_BASE_URL}/employees/managers`,
          {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          }
        )
        .then((response) => {
          const data = response.data.data || [];
          setReportingManagerList(data);
        })
        .catch((error) => console.log(error));
    },
    [selectedDepartment]
  );

  console.log("Reporting Managers List", reportingManagerList)

  useEffect(() => {
    getCompany();
  }, []);

  useEffect(() => {
    getDepartments();
  }, [getDepartments]);

  useEffect(() => {
    getDesignation();
    getReportingManagerList();
  }, [getDesignation, getReportingManagerList]);

  const JoiningDetailsValidation = Yup.object({
    companyName: Yup.string().required("Company Name is required"),
    department: Yup.string().required("Department is required"),
    joining_date: Yup.date().required("Joining Date is required"),
    reportingManager: Yup.string().required("Reporting Manager is required"),
    designation: Yup.string().required("Designation is required"),
  });

  console.log("Reporting Manager List", reportingManagerList)

  const handleSubmit = async (values) => {
    console.log("handle submit", values)
    try {
      const companyId = companyList.find(
        (company) => company.company_name === values.companyName
      )?.id;
      const departmentId = departmentList.find(
        (department) => department.department_name === values.department
      )?.id;
      const roleId = designationList.find(
        (designation) => designation.role_name === values.designation
      )?.id;
      const reportingManagerId = reportingManagerList.find(
        (manager) => manager.employee_full_name === values.reportingManager
      )?.employee_information_id
      ;

      console.log("Reporting manager id", reportingManagerId)

      const response = await axios.put(
        `${process.env.REACT_APP_BASE_URL}/employees/joiningDetails`,
        {
          id: employeeData[0].id,
          reporting_manager_id: reportingManagerId,
          joining_date: values.joining_date,
          access_card_expiry_date: values.access_card_expiry_date,
          work_hours: values.work_hours,
          role_id: roleId,
          company_id: companyId,
          department_name: departmentId,
        },
        
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            "Content-Type": "application/json",
          },
        }
      );
      console.log("show the response",response);
      setShowSuccessModal(true);
    } catch (error) {
      console.error("Error updating data", error);
    }
  };

  const handleCloseModal = () => {
    setShowSuccessModal(false);
    navigate(`/employeedirectory/${id}`);
  };

  const handleInputChange = (event, type, formik) => {
    const { name, value } = event.target;
    if (type === "company") {
      setSelectedCompany(value);
      formik.setFieldValue(name, value);
      getDepartments(value);
    } else if (type === "department") {
      const selectedDepartmentObject = departmentList.find(
        (department) => department.department_name === value
      );
      if (selectedDepartmentObject) {
        const selectedDepartmentId = selectedDepartmentObject.id;
        setSelectedDepartment(value);
        setSelectedDepartmentId(selectedDepartmentId);
        formik.setFieldValue(name, value);
      }
    }
  };

  const initialValues = {
    companyName:
      employeeData[0]?.users?.roles[0]?.company[0]?.company_name || "",
    department: employeeData[0]?.users?.roles[0]?.department_name || "",
    joining_date: employeeData[0]?.joining_date || "",
    reportingManager:
      employeeData[0]?.reportingManager?.first_name +
        " " +
        employeeData[0]?.reportingManager?.last_name || "",
    designation: employeeData[0]?.users?.roles[0]?.role_name || "",
    work_hours: employeeData[0]?.work_hours || "",
    access_card_expiry_date: employeeData[0]?.access_card_expiry_date || "",
  };

  return (
    <div className="board__layout">
      <div className="section-header">
        <h1>Update Joining Details</h1>
      </div>
      {employeeData.map((employee) => (
        <Formik
          initialValues={initialValues}
          enableReinitialize={true}
          onSubmit={handleSubmit}
          validationSchema={JoiningDetailsValidation}
        >
          {(formik) => (
            <Form style={{ padding: "30px" }}>
              <div className="form-rows">
                <div>
                  <label htmlFor="companyName">Company Name</label>
                  <Field
                    name="companyName"
                    as="select"
                    value={formik.values.companyName}
                    onChange={(e) => handleInputChange(e, "company", formik)}
                  >
                    <option value="">Select Company</option>

                    {companyList.map((company) => (
                      <option key={company.id} value={company.company_name}>
                        {company.company_name}
                      </option>
                    ))}
                  </Field>
                  <ErrorMessage name="companyName" component={TextError} />
                </div>
                <div>
                  <label htmlFor="department">Department</label>
                  <Field
                    name="department"
                    as="select"
                    value={formik.values.department}
                    onChange={(e) => handleInputChange(e, "department", formik)}
                  >
                    <option value="">Select the Department</option>
                    {departmentList.map((department) => (
                      <option
                        key={department.id}
                        value={department.department_name}
                      >
                        {department.department_name}
                      </option>
                    ))}
                  </Field>

                  <ErrorMessage name="department" component={TextError} />
                </div>
              </div>

              <div className="form-rows">
                <div>
                  <label htmlFor="joining_date">Joining Date</label>
                  <Field type="date" name="joining_date" id="joining_date" />
                  <ErrorMessage name="joining_date" component={TextError} />
                </div>
                <div>
                  <label htmlFor="reportingManager">Reporting Manager</label>
                  <Field name="reportingManager" as="select">
                    <option value="">{formik.values.reportingManager}</option>
                    {console.log("RML",reportingManagerList)}
                    {reportingManagerList.map((reportingManager) => (
                      <option
                      key={reportingManager.employee_information_id}
                      value={reportingManager.employee_full_name}
                      >                  
                        {`${reportingManager.employee_full_name} - ${reportingManager.employee_id}`}
                      </option>
                    ))}
                  </Field>
                  <ErrorMessage name="reportingManager" component={TextError} />
                </div>
              </div>

              <div className="form-rows">
                <div>
                  <label htmlFor="designation">Designation</label>
                  <Field name="designation" as="select">
                    <option value="">{formik.values.designation}</option>
                    {designationList.map((designation) => (
                      <option
                        key={designation.id}
                        value={designation.role_name}
                      >
                        {designation.role_name}
                      </option>
                    ))}
                  </Field>
                  <ErrorMessage name="designation" component={TextError} />
                </div>
                <div>
                  <div>
                    <label htmlFor="access_card_expiry_date">
                      Access Card Expiry Date
                    </label>
                    <Field
                      type="date"
                      name="access_card_expiry_date"
                      id="access_card_expiry_date"
                    />
                  </div>
                </div>
              </div>
              <div className="form-rows">
                <div>
                  <label htmlFor="work_hours">Work Hours</label>
                  <Field
                    type="text"
                    name="work_hours"
                    id="work_hours"
                    placeholder="Enter Employee Work Hours"
                  />
                </div>
                <div></div>
              </div>

              <div className="form-btn-div" style={{ marginTop: "1rem" }}>
                <button
                  type="button"
                  className="btn-skip"
                  onClick={() => navigate(`/employeedirectory/${id}`)}
                >
                  Close
                </button>
                <button type="submit" className="form-btn-submit">
                  Update
                </button>
              </div>
            </Form>
          )}
        </Formik>
      ))}

      <Modal
        isOpen={showSuccessModal}
        onRequestClose={() => setShowSuccessModal(false)}
        contentLabel="Data Updated Successfully"
        className="react-modal-content"
        overlayClassName="react-modal-overlay"
      >
        <h5>Details Updated Successfully</h5>
        <button onClick={handleCloseModal}>OK</button>
      </Modal>
    </div>
  );
};

export default JoiningDetailsUpdate;
