import React, { useState } from "react";
import "./sidebar.css";
import { Link, NavLink } from "react-router-dom";
import companyLogo from "../../assets/images/HR360_Logo.png";
import navLinksMenu from "../Sidebar/navLinksMenu";
import navLinksOther from "../Sidebar/navLinksOther";

const Sidebar = ({ userData }) => {
  const [activeMenuMain, setActiveMenuMain] = useState(null);
  const [activeMenuOther, setActiveMenuOther] = useState(null);

  const toggleMenuMain = (menuIndex) => {
    if (navLinksMenu[menuIndex].title === "Employee Salary and Payment") {
      setActiveMenuMain(activeMenuMain === menuIndex ? null : menuIndex);
    } else {
      setActiveMenuMain(activeMenuMain === menuIndex ? null : menuIndex);
      setActiveMenuOther(null);
    }
  };

  const toggleMenuOther = (menuIndex) => {
    setActiveMenuOther(activeMenuOther === menuIndex ? null : menuIndex);
    setActiveMenuMain(null);
  };

  return (
    <div className="sidebar">
      <div className="sidebar__top">
        <img src={companyLogo} alt="Company Logo" />
      </div>

      <div className="sidebar__content">
        <div className="menu">
          <ul className="nav__list">
            <h5 className="main__menu-title">MAIN MENU</h5>
            {navLinksMenu.map((item, index) => (
              <li className="nav__item" key={index}>
                {item.children ? (
                  <Link
                    className={`nav__link ${
                      activeMenuMain === index ? "active" : ""
                    }`}
                    onClick={() => toggleMenuMain(index)}
                  >
                    <i className={item.icon}></i>
                    {item.title}
                  </Link>
                ) : (
                  <NavLink
                    to={item.path}
                    activeClassName="nav__active"
                    className={`nav__link ${
                      activeMenuMain === index ? "active" : ""
                    }`}
                    onClick={() => toggleMenuMain(index)}
                  >
                    <i className={item.icon}></i>
                    {item.title}
                  </NavLink>
                )}

                {item.children && (
                  <ul
                    className={`submenu ${
                      activeMenuMain === index ? "active" : ""
                    }`}
                  >
                    {item.children.map((subItem, subIndex) => (
                      <li className="nav__item" key={subIndex}>
                        <NavLink
                          to={subItem.path}
                          activeClassName="nav__active"
                          className="nav__link"
                        >
                          <i className={subItem.icon}></i>
                          {subItem.title}
                        </NavLink>
                      </li>
                    ))}
                  </ul>
                )}
              </li>
            ))}
          </ul>

          <ul className="nav__list" >
            <h5 className="main__menu-title">OTHER</h5>
            {navLinksOther.map((item, index) => (
              <li className="nav__item" key={index}>
                {item.children ? (
                  <Link
                    className={`nav__link ${
                      activeMenuOther === index ? "active" : ""
                    }`}
                    onClick={() => toggleMenuOther(index)}
                  >
                    <i className={item.icon}></i>
                    {item.title}
                  </Link>
                ) : (
                  <NavLink
                    to={item.path}
                    activeClassName="nav__active"
                    className={`nav__link ${
                      activeMenuOther === index ? "active" : ""
                    }`}
                    onClick={() => toggleMenuOther(index)}
                  >
                    <i className={item.icon}></i>
                    {item.title}
                  </NavLink>
                )}

                {item.children && (
                  <ul
                    className={`submenu ${
                      activeMenuOther === index ? "active" : ""
                    }`}
                  >
                    {item.children.map((subItem, subIndex) => (
                      <li className="nav__item" key={subIndex}>
                        <NavLink
                          to={subItem.path}
                          activeClassName="nav__active"
                          className="nav__link"
                        >
                          <i className={subItem.icon}></i>
                          {subItem.title}
                        </NavLink>
                      </li>
                    ))}
                  </ul>
                )}
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
