import "../styles/leaveMore.css";
import { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import purpleIcon from "../assets/images/user-group-purple.png";
import redIcon from "../assets/images/user-group-red.png";
import blueIcon from "../assets/images/user-group-blue.png";
import Popup from "../components/Reusable/Popup";
import DocumentPopup from "../components/Reusable/DocumentPopup";

const ApproveDeclineLeave = ({
  setShowApproveDeclinePage,
  employeeData,
  setSelectedEmployeeData,
  getEmployeeMonthWiseLeave,
  selectedCompany,
}) => {
  const navigate = useNavigate();
  const accessToken = localStorage.getItem("accessToken");
  const permissions = JSON.parse(localStorage.getItem("userPermissions"));
  const [showApprovePopup, setShowApprovePopup] = useState(false);
  const [showDeclinePopup, setShowDeclinePopup] = useState(false);
  const [showForwardPopup, setShowForwardPopup] = useState(false);
  const [showDocumentPopup, setShowDocumentPopup] = useState(false);
  const [isApproved, setIsApproved] = useState(false);
  const [leaveCount, setLeaveCount] = useState([]);

  console.log("ApproveDeclineLeave", permissions)
  console.log()

  const fetchLeaveCount = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/employees/${employeeData.user?.employee_information_id}/leaveCount`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      const data = response.data?.data;
      setLeaveCount(data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchLeaveCount();
  }, []);

  const handleApprove = () => {
    setShowApprovePopup(true);
  };

  const handleDecline = () => {
    setShowDeclinePopup(true);
  };

  const handleForward = () => {
    setShowForwardPopup(true);
  };

  const hasViewManagedLeaveHistoryPermission = permissions.some(
    (permission) => permission.name === "view_managed_leave_history"
  );
  const hasApproveLeavePermission = permissions.some(
    (permission) => permission.name === "approve_leave"
  );
  const hasViewAllLeaveHistoryPermission = permissions.some(
    (permission) => permission.name === "view_all_leave_history"
  );
  const hasApproveLeaveHRPermission = permissions.some(
    (permission) => permission.name === "approve_leave_hr"
  );

  const handleApproveYesButton = async (leaveId) => {
    console.log("handleForwardYesButton", leaveId)
    let apiUrl = "";
    let approvalType = "";
    let approvalField = "";
    let forwardType = "";

    if (hasViewManagedLeaveHistoryPermission && hasApproveLeavePermission) {
      apiUrl = `${process.env.REACT_APP_BASE_URL}/employees/leaves/managerLeaveApproval`;
      approvalType = "is_approved";
      forwardType = "forward";
      approvalField = "deny_reason";
    } else if (
      hasViewAllLeaveHistoryPermission &&
      hasApproveLeaveHRPermission
    ) {
      apiUrl = `${process.env.REACT_APP_BASE_URL}/employees/leaves/HrLeaveApproval`;
      approvalType = "is_hr_approved";
      approvalField = "deny_reason";
    } else {
      console.log("User does not have required permissions.");
      return;
    }

    try {
      const myHeaders = new Headers();
      myHeaders.append("Accept", "application/json");
      myHeaders.append("Authorization", `Bearer ${accessToken}`);
      myHeaders.append("Content-Type", "application/json");

     
      const raw = JSON.stringify({
        leave_id: leaveId,
        [approvalType]: 1, //handleApproveYesButton
        [forwardType]: 0,
        [approvalField]: "Leave Approved",
      });

      console.log("API URL:", apiUrl);
      console.log("Request payload:", raw);
      console.log("Authorization Token:", accessToken);

      const requestOptions = {
        method: "PUT",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      for (let pair of myHeaders.entries()) {
        console.log(`${pair[0]}: ${pair[1]}`);
      }

      const response = await fetch(apiUrl, requestOptions);
      const data = await response.json();

      setShowApprovePopup(false);
      fetchLeaveCount();
      setShowApproveDeclinePage(null);
      getEmployeeMonthWiseLeave();
      console.log("Server Response:", data);

      if (data.success) {
        setIsApproved(true);
      } else {
        alert("Approval failed:", data.message);
      }
    } catch (error) {
      console.log("Error", error);
    }
  };

  
  const handleForwardYesButton = async (leaveId) => {
    console.log("handleForwardYesButton", leaveId)
    let apiUrl = "";
    let approvalType = "";
    let approvalField = "";
    let forwardType = "";

    if (hasViewManagedLeaveHistoryPermission && hasApproveLeavePermission) {
      apiUrl = `${process.env.REACT_APP_BASE_URL}/employees/leaves/managerLeaveApproval`;
      approvalType = "is_approved";
      forwardType = "forward";
      approvalField = "deny_reason";
      console.log("when handle app", apiUrl)
    } else if (
      hasViewAllLeaveHistoryPermission &&
      hasApproveLeaveHRPermission
    ) {
      apiUrl = `${process.env.REACT_APP_BASE_URL}/employees/leaves/HrLeaveApproval`;
      approvalType = "is_hr_approved";
      approvalField = "deny_reason";
    } else {
      console.log("User does not have required permissions.");
      return;
    }

    try {
      const myHeaders = new Headers();
      myHeaders.append("Accept", "application/json");
      myHeaders.append("Authorization", `Bearer ${accessToken}`);
      myHeaders.append("Content-Type", "application/json");

      const raw = JSON.stringify({
        leave_id: leaveId,
        [approvalType]: 1, //handleForwardYesButton
        [forwardType]: 1,
        [approvalField]: "Because",
      });

        
      console.log("API URL:", apiUrl);
      console.log("Request payload:", raw);
      console.log("Authorization Token:", accessToken);


      const requestOptions = {
        method: "PUT",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      for (let pair of myHeaders.entries()) {
        console.log(`${pair[0]}: ${pair[1]}`);
      }

      const response = await fetch(apiUrl, requestOptions);
      const data = await response.json();
      setShowApprovePopup(false);
      fetchLeaveCount();
      setShowApproveDeclinePage(null);
      getEmployeeMonthWiseLeave();
      console.log("Server Response:", data);

      if (data.success) {
        setSelectedEmployeeData(null);
        setIsApproved(true);
      }
    } catch (error) {
      console.log(error);
    }
  };

  // handleDeclineYesButton
  const handleDeclineYesButton = async (leaveId) => {
    let apiUrl = "";
    let approvalType = "";
    let approvalField = "";
    let forwardType = "";

    if (hasViewManagedLeaveHistoryPermission && hasApproveLeavePermission) {
      apiUrl = `${process.env.REACT_APP_BASE_URL}/employees/leaves/managerLeaveApproval`;
      approvalType = "is_approved";
      forwardType = "forward";
      approvalField = "deny_reason";
    } else if (
      hasViewAllLeaveHistoryPermission &&
      hasApproveLeaveHRPermission
    ) {
      apiUrl = `${process.env.REACT_APP_BASE_URL}/employees/leaves/HrLeaveApproval`;
      approvalType = "is_hr_approved";
      approvalField = "deny_reason";
    } else {
      console.log("User does not have required permissions.");
      return;
    }

    try {
      const myHeaders = new Headers();
      myHeaders.append("Accept", "application/json");
      myHeaders.append("Authorization", `Bearer ${accessToken}`);
      myHeaders.append("Content-Type", "application/json");

      const raw = JSON.stringify({
        leave_id: leaveId,
        [approvalType]: 0,
        [forwardType]: 0,
        [approvalField]: "Declined",
      });

      const requestOptions = {
        method: "PUT",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const response = await fetch(apiUrl, requestOptions);
      const data = await response.json();
      setShowDeclinePopup(false);
      fetchLeaveCount();
      setShowApproveDeclinePage(null);
      getEmployeeMonthWiseLeave();

      if (data.success) {
        alert("Success:", data.message);
        setSelectedEmployeeData(null);
      } else {
        alert("Decline failed:", data.message);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleApproveNoButton = () => {
    setShowApprovePopup(false);
    setSelectedEmployeeData(null);
  };

  const handleDeclineNoButton = () => {
    setShowDeclinePopup(false);
    setSelectedEmployeeData(null);
    getEmployeeMonthWiseLeave();
  };
  const handleForwardNoButton = () => {
    setShowForwardPopup(false);
  };

  const handleOpenDocumentPopup = () => {
    setShowDocumentPopup(true);
  };

  const empCountry = employeeData?.user?.roles[0]?.company[0]?.country;

  const leaveTypes = [...new Set(leaveCount.map((leave) => leave.LeaveType))];

  const totalLeaves = leaveCount
    .filter(
      (leave) =>
        leave.country === empCountry && leaveTypes.includes(leave.LeaveType)
    )
    .reduce((total, leave) => total + leave.count, 0);

  const totalLeavesTaken = leaveCount
    .filter(
      (leave) =>
        leave.country === empCountry && leaveTypes.includes(leave.LeaveType)
    )
    .reduce((total, leave) => total + leave.leave_days_taken, 0);

  const availableLeaves = totalLeaves - totalLeavesTaken;

  return (
    <div className="emp_more-container">
      <div className="emp_more-header">
        <h3>Approve / Decline Leave Request</h3>
        <button
          type="button"
          className="close"
          onClick={() => setShowApproveDeclinePage(null)}
        >
          X
        </button>
      </div>

      <div className="emp_more-details">
        <div className="emp_more-info">
          <div className="title">
            <div className="emp_image">
              <img src={employeeData.user?.profile_picture} alt="Employee" />
            </div>
            <div className="info_emp-details">
              <h4>{employeeData.user?.employee_full_name}</h4>
              <p>{employeeData.user?.roles[0]?.role_name}</p>
            </div>
          </div>
          <div className="emp_edit-leaves">
            <div className="description total_leaves">
              <div className="icon purple">
                <img src={purpleIcon} alt="icon" />
              </div>
              <div>
                <p>Total Leaves</p>
                <h3>
                  {totalLeaves} <span>Yearly</span>
                </h3>
              </div>
              <ul>
                {leaveCount
                  .filter((leave) => leave.country === empCountry)
                  .map((leave, index) => (
                    <li key={index}>
                      {leave.LeaveType} {leave.count}
                    </li>
                  ))}
              </ul>
            </div>

            <div className="description leaves_taken">
              <div className="icon red">
                <img src={redIcon} alt="icon" />
              </div>
              <div>
                <p>Leaves Taken</p>
                <h3>
                  {totalLeavesTaken} <span>Yearly</span>
                </h3>
              </div>
              <ul>
                {leaveCount
                  .filter((leave) => leave.country === empCountry)
                  .map((leave, index) => (
                    <li key={index}>
                      {leave.LeaveType} {leave.leave_days_taken}
                    </li>
                  ))}
              </ul>
            </div>
            <div className="description available_leaves">
              <div className="icon blue">
                <img src={blueIcon} alt="icon" />
              </div>
              <div>
                <p>Available Leaves</p>
                <h3>
                  {availableLeaves} <span>Yearly</span>
                </h3>
              </div>
              <ul>
                {leaveCount
                  .filter((leave) => leave.country === empCountry)
                  .map((leave, index) => (
                    <li key={index}>
                      {leave.LeaveType} {leave.count - leave.leave_days_taken}
                    </li>
                  ))}
              </ul>
            </div>
          </div>
        </div>

        <div className="info_section">
          <div className="employee_id-date">
            <p>Employee ID:</p>
            <h6>{employeeData?.user?.employee_id}</h6>
            <p>Applied Date:</p>
            <h6>{employeeData?.apply_date}</h6>
          </div>
          <div>
            {employeeData.user?.employee_information_id && (
              <Link
                to={`/employeeleavehistory?employeeInformationId=${employeeData.user?.employee_information_id}`}
              >
                <p className="leave_history" style={{ cursor: "pointer" }}>
                  View Leave History
                </p>
              </Link>
            )}
          </div>
        </div>
        <div className="leave_status">
          <div className="leave_desc">
            <p>Leave Start Date</p>
            <span>{employeeData?.leave_from}</span>
          </div>
          <div className="leave_desc">
            <p>Leave End Date</p>
            <span>{employeeData?.leave_to}</span>
          </div>
          <div className="leave_desc">
            <p>Leave Type</p>
            <span>{employeeData?.leaveType}</span>
          </div>
          <div className="leave_desc">
            <p>No. of Days</p>
            <span>{employeeData?.days_count}</span>
          </div>
          <div className="leave_desc">
            <p>
              <span>
                {employeeData?.is_hr_approved || employeeData?.is_approved
                  ? "Approved Manager"
                  : employeeData?.is_hr_approved === 0 ||
                    employeeData?.is_approved === 0
                  ? "Rejected Manager"
                  : "Authorized Manager"}
              </span>
            </p>
            <span>
              {employeeData?.is_hr_approved || employeeData?.is_approved
                ? employeeData?.approving_manager
                : employeeData?.is_hr_approved === 0 ||
                  employeeData?.is_approved === 0
                ? employeeData?.rejecting_manager
                : null}
            </span>
          </div>
        </div>

        <div className="leave_reason">
          <label>Reason for leave</label>
          <p>{employeeData?.purpose}</p>
        </div>
        <div className="view_document" onClick={handleOpenDocumentPopup}>
          View Document &gt;
        </div>
        <div className="leave_remarks">
          <label htmlFor="">Remarks/Notes</label>
          <textarea name="remarks" id="remarks" rows="2"></textarea>
        </div>
        <div className="leave_buttons">
          <button
            type="button"
            className="approve"
            onClick={handleApprove}
            disabled={
              employeeData.is_approved === 1 ||
              employeeData.is_hr_approved === 1 ||
              employeeData.is_approved === 0 ||
              employeeData.is_hr_approved === 0
            }
          >
            Approve
          </button>
          {hasViewManagedLeaveHistoryPermission &&
            hasApproveLeavePermission && (
              <button
                type="button"
                className="approveforward"
                onClick={handleForward}
              >
                Approve & Forward
              </button>
            )}
          <button
            type="button"
            className="decline"
            onClick={handleDecline}
            disabled={
              employeeData.is_approved === 1 ||
              employeeData.is_hr_approved === 1 ||
              employeeData.is_approved === 0 ||
              employeeData.is_hr_approved === 0
            }
          >
            Decline
          </button>
        </div>
      </div>

      {showApprovePopup && (
        <Popup
          title="Approve Leave Request"
          message="Are you sure that you are approving this request?"
          onConfirm={() => handleApproveYesButton(employeeData?.leave_id)}
          onCancel={handleApproveNoButton}
        />
      )}

      {showDeclinePopup && (
        <Popup
          title="Decline Leave Request"
          message="Are you sure that you are declining this request?"
          onConfirm={() => handleDeclineYesButton(employeeData?.leave_id)}
          onCancel={handleDeclineNoButton}
        />
      )}
      {showForwardPopup && (
        <Popup
          title="Approve Leave Request"
          message="Are you sure that you are approving this request?"
          onConfirm={() => handleForwardYesButton(employeeData?.leave_id)}
          onCancel={handleForwardNoButton}
        />
      )}
      {showDocumentPopup && (
        <DocumentPopup
          documentUrl={employeeData.document_url}
          onClose={() => setShowDocumentPopup(false)}
        />
      )}
    </div>
  );
};

export default ApproveDeclineLeave;
