import "../styles/accessdenied.css";
import restrict from "../assets/images/restrict.jpg";
import { useNavigate } from "react-router-dom";

const Page403 = () => {
  const navigate = useNavigate();

  const goToHomePage = () => {
    navigate("/dashboard");
  };
  return (
    <div style={{ backgroundColor: "#ffffff", marginTop: "-80px",  }}>
      <div >
        <div className="page403_main" style={{height:'100vh'}}>
          <div className="restrict">
            <img src={restrict} alt="" />
          </div>
          <div className="page403_content">
            <h1>403</h1>
            <p>Forbidden access is denied</p>
            <button onClick={goToHomePage}>Go to Home Page</button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Page403;
