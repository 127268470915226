import "../../styles/empManagement.css";
import "../../styles/attendance.css";
import eyeIcon from "../../assets/images/actionEye.png";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import formatDate from "./dateUtils";

const TimeNAttndanceTable = ({ attendanceData, setAttendanceData }) => {
  const accessToken = localStorage.getItem("accessToken");
  const permissions = JSON.parse(localStorage.getItem("userPermissions"));
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [selectedStartDate, setSelectedStartDate] = useState(null);
  const [selectedEndDate, setSelectedEndDate] = useState(null);
  const [sortOrder, setSortOrder] = useState("asc");
  const [currentPage, setCurrentPage] = useState(1);
  const navigate = useNavigate();
  const rowsPerPage = 10;
  let serialNumber = 1;

  const startDateOnChange = (startDate) => {
    setStartDate(startDate);
    setSelectedStartDate(startDate);
  };

  const endDateOnChange = (endDate) => {
    setEndDate(endDate);
    setSelectedEndDate(endDate);
  };

  const handleViewMore = (employee) => {
    navigate(`/employeeattendancedetails/${employee.emp_id}`, {
      state: { selectedData: employee },
    });
  };

  const handleSort = (columnName) => {
    const currentOrder = sortOrder[columnName];
    const newOrder = currentOrder === "asc" ? "desc" : "asc";
    setSortOrder({ [columnName]: newOrder });
    const sortedData = [...attendanceData].sort((a, b) => {
      if (columnName === "date") {
        const dateA = new Date(a[columnName]);
        const dateB = new Date(b[columnName]);
        return newOrder === "asc" ? dateA - dateB : dateB - dateA;
      } else if (columnName === "login_time" || columnName === "logout_time") {
        const timeA = timeStringToComparableValue(a[columnName]);
        const timeB = timeStringToComparableValue(b[columnName]);
        return newOrder === "asc" ? timeA - timeB : timeB - timeA;
      } else {
        return newOrder === "asc"
          ? a[columnName].localeCompare(b[columnName])
          : b[columnName].localeCompare(a[columnName]);
      }
    });
    setAttendanceData(sortedData);
  };

  const timeStringToComparableValue = (timeStr) => {
    if (timeStr) {
      const [hours, minutes, seconds] = timeStr.split(":").map(Number);
      return hours * 3600 + minutes * 60 + seconds;
    }
    return 0;
  };

  const startingSerialNumber = (currentPage - 1) * rowsPerPage + 1;

  const getCurrentPageData = () => {
    const startIndex = (currentPage - 1) * rowsPerPage;
    const endIndex = startIndex + rowsPerPage;
    return attendanceData.slice(startIndex, endIndex).map((employee) => {
      return {
        ...employee,
        date: employee.status === "Present" && employee.login_time ? formatDate(employee.date) : "Absent",
      };
    });
  };

  const totalPages = Math.ceil(attendanceData.length / rowsPerPage);

  const pageNumbers = Array.from({ length: totalPages }, (_, i) => i + 1);

  const goToPage = (pageNumber) => {
    if (pageNumber >= 1 && pageNumber <= totalPages) {
      setCurrentPage(pageNumber);
    }
  };
  

  return (
    <div>
      <table>
        <thead>
          <tr>
            <th colSpan="2">SL No.</th>
            <th onClick={() => handleSort("employee_id")}>
              Employee ID {sortOrder["employee_id"] === "asc" ? "↑" : "↓"}
            </th>
            <th colSpan="2" onClick={() => handleSort("name")}>
              Full Name {sortOrder["name"] === "asc" ? "↑" : "↓"}
            </th>
            <th onClick={() => handleSort("date")}>
              Date {sortOrder["date"] === "asc" ? "↑" : "↓"}
            </th>
            <th onClick={() => handleSort("login_time")}>
              Login Time {sortOrder["login_time"] === "asc" ? "↑" : "↓"}
            </th>
            <th onClick={() => handleSort("logout_time")}>
              Logout Time {sortOrder["logout_time"] === "asc" ? "↑" : "↓"}
            </th>
            <th>View More</th>
          </tr>
        </thead>
        <tbody>
          {getCurrentPageData().map((employee, index) => (
            <tr key={employee.emp_id}>
              <td>
                <input type="checkbox" />
              </td>
              <td>{startingSerialNumber + index}</td>
              <td>{employee.employee_id}</td>
              <td>
                <img
                  src={`${employee.profile_picture}?timestamp=${Date.now()}`}
                />
              </td>
              <td className="management_empDetails">
                <div className="management_empName">
                  {employee.name
                    ?.split(" ")
                    .map(
                      (word) =>
                        word.charAt(0).toUpperCase() +
                        word.slice(1).toLowerCase()
                    )
                    .join(" ")}
                </div>
                <div className="management_designation">
                  {employee.position}
                </div>
              </td>

              <td>{employee.date}</td>

              <td>
                {employee.status === "Present" && employee.login_time
                  ? employee.login_time
                  : "N/A"}
              </td>
              <td>
                {employee.status === "Present" && employee.logout_time
                  ? employee.logout_time
                  : "N/A"}
              </td>

              <td className="table__data">
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <img
                    src={eyeIcon}
                    alt="icon"
                    onClick={() => handleViewMore(employee)}
                    style={{
                      marginRight: "5px",
                      cursor: "pointer",
                      width: "35px",
                      height: "30px",
                      borderRadius: "0",
                    }}
                  />
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <div className="pagination">
        <button onClick={() => goToPage(currentPage - 1)}>Previous</button>
        <div className="page-numbers">
          {pageNumbers.map((pageNumber) => (
            <button
              key={pageNumber}
              onClick={() => goToPage(pageNumber)}
              className={currentPage === pageNumber ? "active" : ""}
            >
              {pageNumber}
            </button>
          ))}
        </div>
        <button onClick={() => goToPage(currentPage + 1)}>Next</button>
      </div>
    </div>
  );
};

export default TimeNAttndanceTable;
