const DocumentsCard = (props) => {
  const {
    iconImg,
    iconBgColor,
    title,
    count,
    daysShort,
    daysLong,
    countMin,
    countMax,
    handleFirst,
    handleSecond,
  } = props.item;

  return (
    <div className="visa_card-container">
      <div className="visa_card-left">
        <div className={`visa_card-icon ${iconBgColor}`}>
          <img src={iconImg} alt="" />
        </div>
        <div className="visa_card-title">
          <h5>{title}</h5>
          <p>Expiry Details</p>
        </div>
      </div>

      <div className="visa_card-right">
        <div className="visa_card-days_left" onClick={handleFirst}>
          <i className="fa-solid fa-circle"></i>
          <div className="span_title">
            <span>{daysShort}:</span>
            <p>{countMin}</p>
          </div>
        </div>
        {daysLong !== "" && (
          <div className="visa_card-days_left right" onClick={handleSecond}>
            <i className="fa-solid fa-circle"></i>
            <div className="span_title">
              <span>{daysLong}:</span>
              <p>{countMax}</p>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default DocumentsCard;
