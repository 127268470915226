import "../../styles/payroll.css";
import { useState, useCallback, useEffect } from "react";
import axios from "axios";

const SearchTable = ({
  selectedCompany,
  selectedDepartment,
  companyList,
  departmentList = [],
  handleCompanyChange,
  handleDepartmentChange,
  handleSearch,
}) => {
  return (
    <div className="salary__search-table">
      <div className="salary__search-item">
        <label htmlFor="company">All Companies</label>
        <select
          name="company"
          id="company"
          value={selectedCompany}
          onChange={handleCompanyChange}
        >
          <option value="">Select Company</option>
          {companyList.map((company) => (
            <option key={company.id} value={company.company_name}>
              {company.company_name}
            </option>
          ))}
        </select>
      </div>
      <div className="salary__search-item">
        <label htmlFor="department">All Departments</label>
        <select
          name="department"
          id="department"
          onChange={handleDepartmentChange}
        >
          <option value="">Select the Department</option>
          {departmentList.map((department) => (
            <option key={department.id} value={department.department_name}>
              {department.department_name}
            </option>
          ))}
        </select>
      </div>
      {/* <div className="salary__search-item">
        <label htmlFor="date">Date</label>
        <input type="date" name="date" />
      </div> */}
      <div className="salary__search-icon">
        <div className="search__table-icon" onClick={handleSearch}>
          <i className="ri-search-line"></i>
        </div>
      </div>
    </div>
  );
};

export default SearchTable;
