import React, { useState, useEffect } from "react";
import { BsThreeDots } from "react-icons/bs";
import "../../styles/empManagement.css";
import axios from "axios";
import { Link } from "react-router-dom";

const EmployeeCard = ({ selectedCompany, searchQuery, selectedFilter }) => {
  const accessToken = localStorage.getItem("accessToken");
  const [employeeData, setEmployeeData] = useState([]);

  const getEmployeeData = async () => {
    try {
      const accessToken = localStorage.getItem("accessToken");
      let apiUrl = "";
      
      
      const permissions = JSON.parse(localStorage.getItem("userPermissions"));
      const hasViewAllPermission = permissions.some(
        (permission) =>
          permission.name === "view_per_group_all_employees_info" ||
          permission.name === "view_all_managed_employees_info"
      );
  
      if (hasViewAllPermission) {
        
        apiUrl = permissions.some(
          (permission) => permission.name === "view_per_group_all_employees_info"
        )
          ? `${process.env.REACT_APP_BASE_URL}/employees/employeeInformation?company=${selectedCompany}`
          : `${process.env.REACT_APP_BASE_URL}/employees/employeesDataForManagers`;
        
        const response = await axios.get(apiUrl, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        });
  
        const data = response.data?.data;
        const employees = data ? data.flatMap((item) => item.users) : [];

        let filteredAndSortedEmployees = [...employees];

        if (selectedFilter === "Active") {
          filteredAndSortedEmployees = employees.filter(
            (employee) => employee.is_active === 1
          );
        } else if (selectedFilter === "In Active") {
          filteredAndSortedEmployees = employees.filter(
            (employee) => employee.is_active === 0
          );
        }

        if (selectedFilter === "Sort By Name") {
          filteredAndSortedEmployees.sort((a, b) =>
            a.employee_full_name.localeCompare(b.employee_full_name)
          );
        } else if (selectedFilter === "Sort By Employee ID") {
          filteredAndSortedEmployees.sort((a, b) =>
            compareAlphanumeric(a.employee_id, b.employee_id)
          );
        }

        setEmployeeData(filteredAndSortedEmployees);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const compareAlphanumeric = (a, b) => {
    const numA = parseInt(a.match(/\d+/)[0]);
    const numB = parseInt(b.match(/\d+/)[0]);

    if (numA === numB) {
      return a.localeCompare(b);
    }

    return numA - numB;
  };

  useEffect(() => {
    getEmployeeData();
  }, [selectedCompany, selectedFilter]);

  const filteredEmployees = employeeData.filter((user) =>
    user.employee_full_name.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const truncateEmail = (email) => {
    if (email && email.length > 25) {
      return email.substring(0, 25) + "...";
    }
    return email;
  };

  return (
    <>
      {filteredEmployees.map((user) => (
        <div className="card__container-emp">
          <div className="employee__card-status">
            <div>
              <p className={user.is_active ? "active" : "inactive"}> {user.is_active ? "Active" : "In Active"}</p>
            </div>
            <div>
              <h6>
                <Link to={`/employeedirectory/${user.id}`}>
                <BsThreeDots />
              </Link>
                
              </h6>
            </div>
          </div>
          <div className="employee__info">
            <img src={user.profile_picture} alt={user.employee_full_name} />
            <div className="employee__name">
              <h5>{user.employee_full_name}</h5>
              <p>{user.roles[0]?.role_name}</p>
            </div>
            <div className="employee__contact">
              <p>{user.employee_id}</p>
              <p>{truncateEmail(user.official_email)}</p>
            </div>
          </div>
        </div>
      ))}
    </>
  );
};

export default EmployeeCard;
