import { useLocation, useNavigate } from "react-router-dom";
import "../../styles/dashboard.css";
import { capitalizeWords } from "./capitalizeWords";
import formatDate from "./dateUtils";
import eyeIcon from '../../assets/images/actionEye.png'

const NewEmployeeDetails = () => {
  const location = useLocation();
  const { employeeList, selectedCompany } = location.state;
  const navigate = useNavigate()

  const handleViewMore = (employeeId) => {
    navigate(`/employeedirectory/${employeeId}`)
  };


  return (
    <div className="board__layout">
      <div className="section-header">
        <h1>New Employees Details</h1>
        <p>{selectedCompany}</p>
      </div>
      <div>
        <table>
          <thead>
            <tr>
              <th>SL No.</th>
              <th>Employee ID</th>
              <th colSpan="2">Employee Name</th>
              <th>Company Name</th>
              <th>Position</th>
              <th>Joining Date</th>
              <th>View</th>
            </tr>
          </thead>
          <tbody>
            {employeeList.map((employee, index) => {
              return (
                <tr key={index}>
                  <td>{index + 1}</td>
                  <td>{employee.employee_id}</td>
                  <td>
                    <img src={employee.profile_picture} alt="employee?.employee_full_name" />
                  </td>
                  <td style={{ textAlign: "start" }}>
                    {capitalizeWords(employee?.employee_full_name)}
                  </td>
                  <td >
                    {capitalizeWords(employee?.roles[0]?.company[0]?.company_name)}
                  </td>
                  <td>{capitalizeWords(employee?.roles[0]?.role_name)}</td>
                  <td>{formatDate(employee?.joining_date)}</td>
                  <td className="table__data">
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <img
                    src={eyeIcon}
                    alt="icon"
                    onClick={() => handleViewMore(employee.id)}
                    style={{
                      marginRight: "5px",
                      cursor: "pointer",
                      width: "35px",
                      height: "30px",
                      borderRadius: "0",
                    }}
                  />
                </div>
              </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default NewEmployeeDetails;
