import { Formik, Field, Form, ErrorMessage, FieldArray } from "formik";
import * as Yup from "yup";
import TextError from "./TextError";
import { useState, useMemo, Component, useEffect } from "react";
import countryList from "react-select-country-list";
import axios from "axios";
import { BsArrowLeft, BsArrowRight } from "react-icons/bs";
import { fieldsetStyles, legendStyles } from "../Reusable/styles";

const Qualification = (props) => {
  const accessToken = localStorage.getItem("accessToken");
  const [educationList, setEducationList] = useState([]);
  const [fieldOfStudyList, setFieldOfStudyList] = useState([]);
  const [majorOfStudyList, setMajorOfStudyList] = useState([]);

  function getPossibleYears(startYear, endYear) {
    const years = [];
    for (let year = startYear; year <= endYear; year++) {
      years.push(year);
    }
    return years;
  }
  const currentYear = new Date().getFullYear();
  const yearsList = getPossibleYears(1990, new Date().getFullYear()).reverse();

  const getEducation = () => {
    axios
      .get(`${process.env.REACT_APP_BASE_URL}/employees/educationLevel`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      })
      .then((response) => {
        const data = response.data || [];
        setEducationList(data);
      })
      .catch((error) => console.log(error));
  };

  const getFieldOfStudy = () => {
    axios
      .get(`${process.env.REACT_APP_BASE_URL}/employees/field-of-studies`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      })
      .then((response) => {
        const data = response.data || [];
        setFieldOfStudyList(data);
      })
      .catch((error) => console.log(error));
  };

  const getMajorOfStudy = () => {
    axios
      .get(`${process.env.REACT_APP_BASE_URL}/employees/major-of-studies`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      })
      .then((response) => {
        const data = response.data || [];
        setMajorOfStudyList(data);
      })
      .catch((error) => console.log(error));
  };

  useEffect(() => {
    getEducation();
    getFieldOfStudy();
    getMajorOfStudy();
  }, []);

  const QualificationValidation = Yup.object().shape({
    qualification: Yup.array().of(
      Yup.object().shape({
        qualification_name: Yup.string().required("Education is required"),
        field_of_study: Yup.string().required("Field of Study is required"),
        university_name: Yup.string().required("University is required"),
        country: Yup.string().required("Country is required"),
        course_name: Yup.string().required("Major of Study is required"),
        passout_year: Yup.string().required("Year of Passout is required"),
      })
    ),
  });

  const handleSubmit = (values) => {
    props.next(values);
  };

  const countries = useMemo(() => countryList().getData(), []);

  const handlePrevious = (values) => {
    props.onBack(values);
  };

  return (
    <div>
      <Formik
        initialValues={props.empData}
        validationSchema={QualificationValidation}
        onSubmit={handleSubmit}
      >
        {({ values }) => (
          <Form>
            <FieldArray name="qualification">
              {({ insert, remove, push }) => (
                <div>
                  {values.qualification.length > 0 &&
                    values.qualification.map((qual, index) => (
                      <fieldset style={fieldsetStyles}>
                        <legend style={legendStyles}>{`Qualification Details ${
                          index + 1
                        }`}</legend>

                        <div className="form-rows">
                          <div>
                            <label
                              htmlFor={`qualification.${index}.qualification_name`}
                            >
                              Education
                            </label>
                            <Field
                              name={`qualification.${index}.qualification_name`}
                              as="select"
                            >
                              <option value="">Select the Education </option>

                              {educationList.map((education) => (
                                <option key={education.id} value={education.id}>
                                  {education.qualification_name}
                                </option>
                              ))}
                            </Field>
                            <ErrorMessage
                              name={`qualification.${index}.qualification_name`}
                              component="div"
                              className="error-message"
                            />
                          </div>

                          <div>
                            <label
                              htmlFor={`qualification.${index}.field_of_study`}
                            >
                              Field of Study
                            </label>
                            <Field
                              name={`qualification.${index}.field_of_study`}
                              as="select"
                            >
                              <option value="">
                                Select the Field of Study
                              </option>
                              {fieldOfStudyList.map((studyField) => (
                                <option
                                  key={studyField.id}
                                  value={studyField.id}
                                >
                                  {studyField.field_name}
                                </option>
                              ))}
                            </Field>
                            <ErrorMessage
                              name={`qualification.${index}.field_of_study`}
                              component="div"
                              className="error-message"
                            />
                          </div>
                        </div>
                        <div className="form-rows">
                          <div>
                            <label
                              htmlFor={`qualification.${index}.university_name`}
                            >
                              Name of University
                            </label>
                            <Field
                              name={`qualification.${index}.university_name`}
                              type="text"
                              id={`qualification.${index}.university_name`}
                              placeholder="Name of University"
                            ></Field>
                            <ErrorMessage
                              name={`qualification.${index}.university_name`}
                              component="div"
                              className="error-message"
                            />
                          </div>

                          <div>
                            <label htmlFor={`qualification.${index}.country`}>
                              Country
                            </label>
                            <Field
                              name={`qualification.${index}.country`}
                              as="select"
                            >
                              <option value="">Select Country</option>

                              {countries.map((country, index) => (
                                <option value={country.id} key={index}>
                                  {" "}
                                  {country.label}{" "}
                                </option>
                              ))}
                            </Field>
                            <ErrorMessage
                              name={`qualification.${index}.country`}
                              component="div"
                              className="error-message"
                            />
                          </div>
                        </div>
                        <div className="form-rows">
                          <div>
                            <label
                              htmlFor={`qualification.${index}.course_name`}
                            >
                              Major of Study
                            </label>
                            <Field
                              name={`qualification.${index}.course_name`}
                              as="select"
                            >
                              <option value="">
                                Select the Major of Study{" "}
                              </option>
                              {majorOfStudyList.map((majorStudy) => (
                                <option
                                  key={majorStudy.id}
                                  value={majorStudy.id}
                                >
                                  {majorStudy.main_course}
                                </option>
                              ))}
                            </Field>
                            <ErrorMessage
                              name={`qualification.${index}.course_name`}
                              component="div"
                              className="error-message"
                            />
                          </div>

                          {/* <div >
                            <label
                              htmlFor={`qualification.${index}.other_skills`}
                            >
                              Other Qualifiation / Skills
                            </label>
                            <Field
                              name={`qualification.${index}.other_skills`}
                              type="text"
                              placeholder="Additional Skills/ Qualifications"
                            ></Field>
                            <ErrorMessage
                              name={`qualification.${index}.other_skills`}
                              component="div"
                              className="error-message"
                            />
                          </div> */}
                        </div>
                        <div className="form-rows">
                          <div>
                            <label
                              htmlFor={`qualification.${index}.passout_year`}
                            >
                              Year of Passout
                            </label>
                            <Field
                              name={`qualification.${index}.passout_year`}
                              type="date"
                            ></Field>
                            <ErrorMessage
                              name={`qualification.${index}.passout_year`}
                              component="div"
                              className="error-message"
                            />
                          </div>
                          <div></div>
                        </div>

                        <div className="col">
                          {index > 0 && (
                            <button
                              type="button"
                              className="formik-delete-btn"
                              onClick={() => remove(index)}
                            >
                              Delete
                            </button>
                          )}
                        </div>
                      </fieldset>
                    ))}
                  <button
                    type="button"
                    className="formik-addMore-btn"
                    onClick={() =>
                      push({
                        qualification_name: "",
                        field_of_study: "",
                        university_name: "",
                        country: "",
                        course_name: "",
                        passout_year: "",
                        is_approved: 1,
                      })
                    }
                  >
                    Add More Qualification +
                  </button>
                </div>
              )}
            </FieldArray>
            <div className="form-btn-div">
            <button
                onClick={handlePrevious}
                type="button"
                className="form-btn-previous"
              >
                <BsArrowLeft /> Previous
              </button>
              <button type="submit" className="form-btn-submit">
                Save & Continue <BsArrowRight />
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default Qualification;
