const formatDateTime = (dateString) => {
  const dateTime = new Date(dateString);

  const formattedDate = dateTime.toLocaleDateString("en-GB");
  const formattedTime = dateTime.toLocaleTimeString("en-US", {
    hour: "2-digit",
    minute: "2-digit",
    hour12: true,
  });

  return `${formattedDate} ${formattedTime}`;
};

export default formatDateTime;