const Popup = ({ title, message, onConfirm, onCancel, leaveId }) => (
  <div className="popup-overlay">
    <div className="popup">
      <div>
        <h5>{title}</h5>
      </div>
      <p>{message}</p>
      <div className="popup-buttons">
      <button className="no_btn" type="button" onClick={onCancel}>
          No
        </button>
        <button className="yes_btn" type="button" onClick={()=>onConfirm(leaveId)}>
          Yes
        </button>
        
      </div>
    </div>
  </div>
);
export default Popup;

